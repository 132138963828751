import { GetApiPayloadByUrl } from '@crossbeam/openapi';
import { MarkOptional, Nullable } from '@crossbeam/types';

import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { ComputedRef, computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import {
  TEMP_ECOSYSTEM_FIELDS_SFDC,
  TEMP_PUSH_EXTRA_FIELDS,
} from '@/constants/feature_flags';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
  useIntegrationProfileStore,
  usePartnerCloudStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import {
  EcosystemFieldMappings,
  EcosystemFieldsPush,
  IntegrationProfile,
  IntegrationProfileType,
  MinimalIntegrationProfile,
  TrayPayload,
} from '@/types/integration_profiles';
import { TrayTag } from '@/types/integrations';
import { STANDARD_POPULATIONS } from '@/types/populations';

type IntegrationProfileOptional = MarkOptional<
  IntegrationProfile,
  'profile_id'
>;
export default function useIntegrationProfile(
  profileType: IntegrationProfileType | undefined = undefined,
  options = { initOnMount: true },
) {
  const defaultStandardPopSettings = (
    [...STANDARD_POPULATIONS, 'custom'] as const
  ).map((pop) => ({
    standard_type: pop,
    is_included: ['customers', 'open_opportunities'].includes(pop),
  }));

  const { hasPermission } = useAuth();
  const { hasFeatureFlag } = useFeatureFlagStore();

  const integrationProfileStore = useIntegrationProfileStore();
  const { getFeedById } = useFeedsStore();
  const flashesStore = useFlashesStore();
  const { getSourceById } = useSourcesStore();
  const partnerCloudStore = usePartnerCloudStore();
  const route = useRoute();

  const excludePartnershipsCreatedSince = ref<string | undefined>(undefined);
  const isSaving = ref(false);
  const isLoading = ref(false);
  const integrationProfileSettings = ref<IntegrationProfileOptional | null>(
    null,
  );
  const existingIntegrationProfileSettings =
    ref<IntegrationProfileOptional | null>(null);

  const { populations } = storeToRefs(usePopulationsStore());
  const { isFreeTier, isEnterpriseTier } = storeToRefs(useBillingStore());

  const integrationDescription = computed(() => {
    return profileType === 'clari'
      ? 'Configure the populations syncing to Clari. You can only sync Salesforce based populations and Partner Opps or Partner Customers.'
      : `Amplify and activate your ecosystem insights by pushing overlapping partner data into the Crossbeam overlaps custom object in ${friendlyName.value}.`;
  });

  const enableDescription = computed(() => {
    // todo confirm copy
    return `Push overlap data to ${friendlyName.value}. This will impact your record export limit`;
  });

  const hasPermissionToManageIntegrations = computed(() =>
    hasPermission('manage:integrations'),
  );
  const hasTempPushExtraFields = hasFeatureFlag(TEMP_PUSH_EXTRA_FIELDS);
  const hasTempSFDCFields = hasFeatureFlag(TEMP_ECOSYSTEM_FIELDS_SFDC);

  const isIntegrationAvailable = computed(() =>
    profileType === 'hubspot' ? !isFreeTier.value : isEnterpriseTier.value,
  );

  type Integration = 'salesforce' | 'gong' | 'hubspot' | 'outreach' | 'chrome';
  const integrationType = computed<Integration>(
    () => route.params.integration as Integration,
  );

  const friendlyNameMap = {
    salesforce: 'Salesforce',
    gong: 'Gong',
    hubspot: 'HubSpot',
    outreach: 'Outreach',
    chrome: 'Chrome',
  } as const;

  const friendlyName = computed(() => {
    if (!profileType) return null;
    if (profileType === 'crossbeam_copilot')
      return integrationType.value
        ? friendlyNameMap[integrationType.value]
        : null;
    const names = {
      clari: 'Clari',
      salesforce: 'Salesforce',
      hubspot: 'HubSpot',
      microsoft_dynamics: 'MS Dynamics',
      snowflake: 'Snowflake',
    };
    return names[profileType];
  });

  onMounted(async () => {
    if (
      profileType &&
      !integrationProfileSettings.value &&
      options.initOnMount
    ) {
      await initProfileSettings();
    }
  });

  const onSettingsUpdated = (payload: MinimalIntegrationProfile) => {
    if (!integrationProfileSettings.value) return;
    integrationProfileSettings.value = {
      ...integrationProfileSettings.value,
      ...payload,
    };
  };
  function onUpdateExcludePartnershipsCreatedSince(isChecked: boolean) {
    if (!integrationProfileSettings.value) return;
    integrationProfileSettings.value = {
      ...integrationProfileSettings.value,
      exclude_partnerships_created_since: isChecked
        ? null
        : (excludePartnershipsCreatedSince.value ?? null),
    };
  }
  const pathTrayTag = computed(() => route.params.tray_tag as TrayTag);

  const enabledTray = computed(() => {
    const foundTray = partnerCloudStore.enabledTray.find(
      (trayIntegration) => trayIntegration?.trayTag === pathTrayTag.value,
    );
    return foundTray;
  });
  const isPushEnabled = ref(enabledTray?.value?.enabled ?? false);
  const originalEnabled = enabledTray?.value?.enabled; // used to track changes for triggering tray update

  const getConfigValueByExternalId = (externalId: string) =>
    computed(() => {
      const foundConfig = enabledTray?.value?.configValues?.find(
        (config) => config.externalId === externalId,
      )?.value;
      return foundConfig ? JSON.parse(foundConfig) : null;
    });

  const existingEcosystemFieldsPushSetting = getConfigValueByExternalId(
    'external_push_ecosystem_fields',
  );

  const existingOverlapsPushSetting = getConfigValueByExternalId(
    'external_push_overlaps',
  );

  const existingOpportunityPushSetting = getConfigValueByExternalId(
    'external_opps-enabled',
  );

  const existingEcosystemFieldMappings = computed<EcosystemFieldMappings>(
    () => {
      const mappings = getConfigValueByExternalId(
        'external_ecosystem_fields',
      ) as ComputedRef<EcosystemFieldMappings>;

      return (
        mappings.value?.map((m) => ({
          crm_field: m.crm_field,
          crossbeam_field: m.crossbeam_field,
        })) ?? null
      );
    },
  );

  const existingProfileIdConfig = getConfigValueByExternalId(
    'external_crossbeam-profile-id',
  );

  const opportunityPushSetting = computed<boolean>(
    () => existingOpportunityPushSetting.value ?? false,
  );

  const existingEcosystemFieldsPushSettingValue = computed<EcosystemFieldsPush>(
    () => existingEcosystemFieldsPushSetting.value ?? 'NONE',
  );

  const ecosystemFieldMappings = ref<Nullable<EcosystemFieldMappings>>(
    existingEcosystemFieldMappings.value,
  );

  const existingOverlapsPushSettingValue = computed<boolean>(
    () => existingOverlapsPushSetting.value ?? false,
  );

  const isIntegrationProfileConfigured = computed(
    () => existingProfileIdConfig.value && !!existingProfileIdConfig.value,
  );

  const isOpportunityPushEnabled = ref(opportunityPushSetting.value);
  const isOverlapsPushEnabled = ref(existingOverlapsPushSettingValue.value);

  const ecosystemFieldsPushSettingValue = ref(
    existingEcosystemFieldsPushSettingValue.value,
  );
  const isEcosystemFieldsPushEnabled = computed(
    () => ecosystemFieldsPushSettingValue.value === 'ALL',
  );

  const isTrayConfigUpdateRequired = computed(() => {
    const opportunityPushSettingChanged =
      isOpportunityPushEnabled.value !== opportunityPushSetting.value;

    const ecosystemFieldsPushSettingChanged =
      ecosystemFieldsPushSettingValue.value !==
      existingEcosystemFieldsPushSettingValue.value;

    const overlapsPushSettingChanged =
      isOverlapsPushEnabled.value !== existingOverlapsPushSettingValue.value;

    const enabledSettingChanged = isPushEnabled.value !== originalEnabled;

    const ecosystemMappingsChanged = !isEqual(
      ecosystemFieldMappings.value,
      existingEcosystemFieldMappings.value,
    );

    return (
      opportunityPushSettingChanged ||
      enabledSettingChanged ||
      ecosystemFieldsPushSettingChanged ||
      overlapsPushSettingChanged ||
      !isIntegrationProfileConfigured.value ||
      (ecosystemMappingsChanged &&
        ecosystemFieldMappings.value?.every((m) => !!m.crm_field))
    );
  });

  const createTrayConfigPayload = (): TrayPayload => {
    const profileId = integrationProfileSettings.value?.profile_id;
    if (!profileId) {
      throw new Error('Integration profile settings not found');
    }

    if (!enabledTray?.value) {
      throw new Error('Tray integration info not found');
    }

    const config: TrayPayload['config'] = {
      integration_profile_id: profileId,
      start_timestamp: DateTime.now().plus({ minutes: 15 }).toISO(),
      enabled: isPushEnabled.value,
      opps_enabled: isOpportunityPushEnabled.value,
      push_overlaps: isOverlapsPushEnabled.value,
      push_ecosystem_fields: ecosystemFieldsPushSettingValue.value,
    };

    if (
      profileType === 'salesforce' &&
      ecosystemFieldMappings.value &&
      hasTempSFDCFields
    ) {
      config.ecosystem_fields = ecosystemFieldMappings.value;
    }

    return {
      config,
      instance_name: enabledTray.value.name,
      instance_id: enabledTray.value.id,
    };
  };

  const updateTrayInstanceConfig = async (payload: TrayPayload) => {
    try {
      await crossbeamApi.PUT('/v0.1/tray-integrations', {
        body: payload,
      });
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Failed to set remote configuration',
      });
    }
  };

  const saveSettings = async () => {
    if (!integrationProfileSettings.value) {
      return;
    }
    const outPayload = {
      exclude_partnerships_created_since: integrationProfileSettings.value
        .exclude_partnerships_created_since
        ? excludePartnershipsCreatedSince.value
        : null,
      population_settings:
        integrationProfileSettings.value.population_settings.map((p) => ({
          population_id: p.population_id,
          is_partner_population: p.is_partner_population,
          is_included: p.is_included,
        })),
      standard_population_settings:
        integrationProfileSettings.value.standard_population_settings.map(
          (p) => ({
            standard_type: p.standard_type,
            is_included: p.is_included,
          }),
        ),
    };

    isSaving.value = true;
    const isExcludePartnershipsCreatedSinceChanged =
      !!integrationProfileSettings.value?.exclude_partnerships_created_since !==
      !!existingIntegrationProfileSettings.value
        ?.exclude_partnerships_created_since;

    try {
      if (!profileType) return;
      integrationProfileSettings.value?.profile_id
        ? await integrationProfileStore.putIntegrationProfileSettings(
            integrationProfileSettings.value?.profile_id,
            outPayload,
          )
        : await integrationProfileStore.postIntegrationProfileSettings({
            ...outPayload,
            integration_type: profileType,
          });

      await integrationProfileStore.refreshIntegrationProfileStore();

      if (enabledTray.value && isTrayConfigUpdateRequired.value) {
        const payload = createTrayConfigPayload();

        await updateTrayInstanceConfig(payload);
        await partnerCloudStore.refreshPartnerCloudStore();
      }
      flashesStore.addSuccessFlash({
        message: `${
          profileType === 'crossbeam_copilot'
            ? 'Crossbeam Copilot'
            : enabledTray.value?.name
              ? enabledTray.value.name
              : friendlyName.value
        } settings saved`,
        description: isExcludePartnershipsCreatedSinceChanged
          ? integrationProfileSettings.value?.exclude_partnerships_created_since
            ? 'Partners will no longer be added automatically'
            : 'New partners will be automatically added'
          : profileType === 'crossbeam_copilot'
            ? undefined
            : `Your integration configuration has been saved`,
      });
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: `Your ${profileType === 'crossbeam_copilot' ? 'Crossbeam Copilot' : 'integration'} settings could not be saved`,
      });
    } finally {
      clearProfilesState();
      isSaving.value = false;
    }
  };

  const clearProfilesState = () => {
    partnerCloudStore.$patch({ inProgressInstallation: null });
    integrationProfileSettings.value = null;
    existingIntegrationProfileSettings.value = null;
  };

  const setIntegrationProfileDefaults = (
    integrationProfile: IntegrationProfile,
  ) => {
    const standardPopSettingsRaw = [
      ...integrationProfile.standard_population_settings,
      ...defaultStandardPopSettings.filter(
        (pop) =>
          !integrationProfile?.standard_population_settings.some(
            (p) => p.standard_type === pop.standard_type,
          ),
      ),
    ];

    integrationProfileSettings.value = {
      ...integrationProfile,
      standard_population_settings: standardPopSettingsRaw,
      population_settings: integrationProfile.population_settings,
    };
    existingIntegrationProfileSettings.value = integrationProfileSettings.value;
  };

  const initProfileSettings = async () => {
    if (!profileType) return;
    await integrationProfileStore.readySync;
    const integrationProfile =
      integrationProfileStore.getIntegrationProfileByType(profileType);
    // on initial fetch, we set the date to now
    excludePartnershipsCreatedSince.value = DateTime.now().toISO();

    if (integrationProfile) {
      setIntegrationProfileDefaults(integrationProfile);
      return;
    }

    // If there isn't an appropriate integration profile, make a new one and post it.
    const popSettings = populations.value
      .filter((pop) => {
        const { feed_id: feedId } = getSourceById(pop.source_id) as unknown as {
          feed_id: number;
        };
        const feed = getFeedById(feedId);
        return feed?.integration.type === profileType;
      })
      .map((pop) => {
        return {
          population_id: pop.id,
          is_partner_population: false,
          is_included: true,
        };
      });

    const payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'> = {
      integration_type: profileType,
      population_settings: popSettings,
      standard_population_settings: defaultStandardPopSettings,
    };

    isLoading.value = true;

    // short circuit the POST for snowflake, don't create profile until save is clicked
    if (profileType === 'snowflake') {
      integrationProfileSettings.value = {
        ...payload,
        exclude_partnerships_created_since: null,
      };
      return;
    }

    try {
      const { data, error } =
        await integrationProfileStore.postIntegrationProfileSettings(payload);
      if (error) throw error;

      integrationProfileSettings.value = data;
      existingIntegrationProfileSettings.value =
        integrationProfileSettings.value;
      await integrationProfileStore.refreshIntegrationProfileStore();
    } catch (err) {
      if (err instanceof Error) {
        flashesStore.addErrorFlash({ message: err.message });
      }
    } finally {
      isLoading.value = false;
    }
  };
  const handleEcosystemFieldsPushSettingChange = (checked: boolean) => {
    ecosystemFieldsPushSettingValue.value = checked ? 'ALL' : 'NONE';
  };

  return {
    clearProfilesState,
    enableDescription,
    excludePartnershipsCreatedSince,
    friendlyName,
    hasPermissionToManageIntegrations,
    integrationDescription,
    integrationProfileSettings,
    isIntegrationAvailable,
    isLoading,
    isSaving,
    onSettingsUpdated,
    onUpdateExcludePartnershipsCreatedSince,
    saveSettings,
    initProfileSettings,
    enabledTray,
    existingOpportunityPushSetting,
    existingEcosystemFieldsPushSetting,
    existingOverlapsPushSetting,
    opportunityPushSetting,
    isTrayConfigUpdateRequired,
    isPushEnabled,
    ecosystemFieldsPushSettingValue,
    ecosystemFieldMappings,
    isOpportunityPushEnabled,
    isOverlapsPushEnabled,
    isEcosystemFieldsPushEnabled,
    handleEcosystemFieldsPushSettingChange,
    hasTempPushExtraFields,
    hasTempSFDCFields,
  };
}
