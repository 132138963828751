<template>
  <component
    :is="rootComponent"
    v-bind="rootProps"
    class="w-full h-full"
    :class="{ 'cursor-not-allowed': !noteValue.note_text && !isOwnColumn }"
    v-on="rootEvents"
  >
    <div
      v-if="noteValue.note_text"
      class="gap-8 flex flex-start max-w-[200px]"
      data-testid="individual-list-table__notes-cell"
    >
      <p
        :class="{
          'bg-white notes_cell_note__custom': isOwnColumn && isCustomColumn,
          'bg-info-background-weak notes_cell_note__new':
            isOwnColumn && !isCustomColumn,
          'bg-accent-background-weak notes_cell_note__new': !isOwnColumn,
        }"
      >
        {{ noteValue.note_text }}
      </p>
    </div>
    <div v-else class="w-full h-full" />
    <template #content>
      <div class="max-h-[400px]">
        <component
          :is="popoverComponent"
          v-bind="popoverProps"
          v-model="noteValue.note_text"
          name="collaborate-component"
        >
          {{ noteValue.note_text }}
        </component>
        <div
          v-if="isNoteInvalid"
          class="flex text-danger-text-strong items-center pt-8"
        >
          <FontAwesomeIcon
            :icon="['fak', 'exclamation-circle']"
            :style="{ height: '12px', width: '12px' }"
            class="text-danger-text-strong mr-4"
          />
          <p class="text-sm"> Max {{ maxNumChars }} characters </p>
        </div>
        <div v-if="noteValue?.updated_at" class="flex pl-4 pr-12 pt-8">
          <BittsAvatarPip
            size="small"
            :user="lastUpdatedNoteUser?.user"
            :org="lastUpdatedNoteUser?.org"
            :show-initials="true"
            class="mr-8"
          />
          <p class="text-neutral-text-weak text-sm self-center">
            {{ updatedAt }}
          </p>
        </div>
      </div>
    </template>
  </component>
</template>
<script lang="ts">
import { BittsAvatarPip, BittsPopover, BittsTextArea } from '@crossbeam/bitts';

import { ICellRendererParams } from '@ag-grid-community/core';
import { DateTime } from 'luxon';
import { computed, nextTick, onMounted, reactive, ref } from 'vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import {
  CUSTOM_TEXT,
  MAX_NUM_CUSTOM_NOTE_CHARS,
  MAX_NUM_NOTE_CHARS,
} from '@/constants/shared_list';
import { captureException } from '@/errors';
import { useFlashesStore, usePartnersStore, useTeamStore } from '@/stores';
import { PartnerUser } from '@/types/partners';
import { SharedListUserOrgAndLabel } from '@/types/shared_lists';
import { TeamAccessAuthorization, TeamAuthorizationUser } from '@/types/team';

type Props = { params: ICellRendererParams };

export default {
  name: 'SharedListNotesRenderer',
  components: { BittsTextArea, BittsPopover, BittsAvatarPip },
  props: {
    params: {
      default: null,
      type: Object,
    },
  },
  setup(props: Props) {
    const flashesStore = useFlashesStore();
    const { iteratively } = useIteratively();
    const partnerStore = usePartnersStore();
    const teamStore = useTeamStore();

    const isOwnColumn = computed(
      () => props.params.column?.getColDef().cellRendererParams.isOwnColumn,
    );
    const listId = computed(
      () => props.params.column?.getColDef().cellRendererParams.listId,
    );
    const { currentOrg } = useAuth();

    onMounted(() => {
      if (isOwnColumn.value) {
        props.params.api.startEditingCell({
          rowIndex: props.params.rowIndex,
          colKey: props.params.column?.getColDef().colId as string,
        });
      }
    });

    const noteValue = reactive(props.params.value || {});
    const originalText = ref(props.params.value?.note_text);

    const columnWidth = computed(() => {
      return `${Number(props.params.column?.getActualWidth()) + 5}px`;
    });

    const isCustomColumn = computed(
      () => props.params.column?.getColDef().field === CUSTOM_TEXT,
    );

    function getValue() {
      return noteValue;
    }
    const lastUpdatedUserInList = computed(() =>
      props.params.column
        ?.getColDef()
        .cellRendererParams.allUsersInList.find(
          (user: SharedListUserOrgAndLabel) =>
            user.user_id === props.params.value?.updated_by_user_id &&
            user.org?.organization_id === props.params.value?.organization_id,
        ),
    );

    type NoteUser = (TeamAccessAuthorization | PartnerUser) & {
      user?: TeamAuthorizationUser;
      id?: string | number;
    };
    const lastUpdatedNoteUser = computed(() => {
      if (lastUpdatedUserInList.value) {
        const { user_id: id, org } = lastUpdatedUserInList.value;
        return {
          user: { ...lastUpdatedUserInList.value, id },
          org: { ...org, id: org.organization_id },
        };
      }
      const isOwn = props.params.value?.organization_id === currentOrg.value.id;
      const org = isOwn
        ? currentOrg.value
        : partnerStore.getPartnerOrgById(
            props.params.value?.organization_id,
            false,
          );
      const users: NoteUser[] = isOwn
        ? teamStore.coreUsers
        : partnerStore.getPartnerOrgById(
            props.params.value?.organization_id,
            false,
          )?.users || [];
      const user = users.find((user) => {
        const id = user.user ? user.user.id : user.id;
        return id === props.params.value?.updated_by_user_id;
      });
      return user ? { user: user.user ? user.user : user, org } : null;
    });

    const updatedAt = computed(
      () => `Updated ${DateTime.fromISO(noteValue?.updated_at).toRelative()}`,
    );

    const isEmptyPartnerNote = computed(
      () => !isOwnColumn.value && !props.params.value,
    );
    const rootComponent = computed(() =>
      isEmptyPartnerNote.value ? 'div' : BittsPopover,
    );
    const rootEvents = computed(() => {
      return { 'menu-visible': updateCellEdit };
    });
    const rootProps = computed(() => {
      if (isEmptyPartnerNote.value) return {};
      const overlayClass = isNoteInvalid.value
        ? 'individual-list-table__notes-cell-popover-new danger'
        : 'individual-list-table__notes-cell-popover-new';
      return {
        align: { offset: [-16, -12], points: ['tl', 'tl'] },
        mountToBody: true,
        placement: 'bottomRight',
        class: 'cursor-pointer',
        overlayClass,
      };
    });

    const popoverComponent = computed(() =>
      isOwnColumn.value ? BittsTextArea : 'div',
    );
    const popoverProps = computed(() => {
      if (!isOwnColumn.value) return {};
      return {
        autoSize: { maxRows: 8 },
        textarea: true,
        placeholder: 'Click here and start typing note',
      };
    });

    const maxNumChars = computed(() =>
      isCustomColumn.value ? MAX_NUM_CUSTOM_NOTE_CHARS : MAX_NUM_NOTE_CHARS,
    );

    const isNoteInvalid = computed(
      () => noteValue.note_text?.length >= maxNumChars.value,
    );

    const hasNoteValueChanged = computed(
      () => noteValue.note_text !== originalText.value,
    );

    async function updateCellEdit({ visible }: { visible: boolean }) {
      if (visible) {
        await nextTick();
        const popoverElementCollection = document.body.getElementsByClassName(
          'ant-popover-content',
        ) as unknown as HTMLElement[];
        popoverElementCollection.forEach((el: HTMLElement) => {
          el.style.width = columnWidth.value;
        });
      }
      if (!visible && isOwnColumn.value) {
        props.params.api.stopEditing();
        if (hasNoteValueChanged.value) {
          if (isCustomColumn.value) {
            await handlePutCustomColNote();
          } else {
            await handlePutNote();
          }
        }
      }
    }

    const columnId = computed(() => props.params.column?.getColId());

    function submitIterativelyEvent(action: string) {
      iteratively.userEditedList({
        list_id: listId.value,
        previous_value: originalText.value || '',
        new_value: noteValue.note_text,
        action,
        note_type: !originalText.value ? 'New' : 'Updated',
        column_id: columnId.value,
      } as never);
    }

    async function handlePutNote() {
      if (isNoteInvalid.value) noteValue.note_text = '';
      const rowId = props.params.data.row_id;
      const body = { note_text: noteValue.note_text };
      try {
        const resp = await crossbeamApi.PUT(
          '/v0.1/lists/{list_id}/rows/{row_id}/note',
          {
            params: {
              path: {
                list_id: listId.value,
                row_id: rowId,
              },
            },
            body,
          },
        );
        submitIterativelyEvent('Edited Note');
        Object.assign(noteValue, resp?.data);
        props.params.node.setDataValue(props.params.column || '', resp?.data);
        props.params.api.redrawRows({ rowNodes: [props.params.node] });
        await props.params.context.refreshStore();
      } catch (err) {
        flashesStore.addErrorFlash({
          message:
            'Something went wrong, reach out to our support team for help',
        });
      }
    }

    async function handlePutCustomColNote() {
      if (isNoteInvalid.value) noteValue.note_text = '';
      const rowId = props.params.data.row_id;
      const colId = props.params.column?.getColId();
      const body = { note_text: noteValue.note_text };
      try {
        const resp = await crossbeamApi.PUT(
          '/v0.1/lists/{list_id}/rows/{row_id}/columns/{column_id}',
          {
            params: {
              path: {
                list_id: listId.value,
                row_id: rowId,
                column_id: colId || '',
              },
            },
            body,
          },
        );
        submitIterativelyEvent('Edited Custom Note');
        Object.assign(noteValue, resp?.data);
        props.params.node.setDataValue(props.params.column || '', resp?.data);
        props.params.api.redrawRows({ rowNodes: [props.params.node] });
        await props.params.context.refreshStore();
      } catch (err) {
        console.log(err);
        captureException(err);
        flashesStore.addErrorFlash({
          message:
            'Something went wrong, reach out to our support team for help',
        });
      }
    }

    return {
      getValue,
      lastUpdatedNoteUser,
      isOwnColumn,
      noteValue,
      popoverComponent,
      popoverProps,
      rootComponent,
      rootEvents,
      rootProps,
      isNoteInvalid,
      columnWidth,
      isCustomColumn,
      updateCellEdit,
      updatedAt,
      DateTime,
      maxNumChars,
    };
  },
};
</script>
<style lang="pcss">
.ag-cell {
  line-height: 24px !important;
}

.individual-list-table__notes-cell-popover-new {
  @apply rounded-bts-base;
  .ant-popover-content {
    @apply rounded-8;
    .ant-popover-inner {
      @apply outline-1 border-neutral-border-focus outline-neutral-border-focus shadow-component outline p-8 rounded-8;
      div .c-bitts-textarea {
        @apply border-none outline-none rounded-none shadow-none p-0 max-h-[204px];
        textarea {
          @apply max-h-[250px];
        }
      }
    }
  }
  &.danger {
    .ant-popover-content .ant-popover-inner {
      @apply border-danger-accent outline-danger-accent;
    }
  }
}

.notes_cell_note__new {
  @apply py-4 px-8 rounded-tr-lg rounded-br-lg rounded-bl-lg overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.notes_cell_note__custom {
  @apply py-4 px-8 rounded-tr-lg rounded-br-lg rounded-bl-lg truncate;
}
</style>
