import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { computed, inject } from 'vue';
import type { Ref } from 'vue';

import { crossbeamApi } from '@/api';
import {
  MIN_SUPPORTED_SALESFORCE_VERSION,
  NOT_LIMITED,
} from '@/constants/integrations';
import { ProvidedExportLimitReached } from '@/injectionKeys/global';
import { useConnectionsStore, usePartnerCloudStore } from '@/stores';
import {
  IntegrationProfilePopulationSetting,
  IntegrationProfileStatus,
} from '@/types/integration_profiles';
import {
  EnabledTray,
  TrayError,
  TrayIntegrationHeaderStatus,
  TrayIntegrationType,
} from '@/types/integrations';

import useAuth from './useAuth';
import useTrayIntegrations from './useTrayIntegrations';

export default function useTrayStatus(
  integration?: Ref<EnabledTray | undefined>,
) {
  const partnerCloudStore = usePartnerCloudStore();
  const exportLimitReached = inject(ProvidedExportLimitReached);
  const { currentOrg, hasPermission } = useAuth();

  const canManageIntegrations = computed(() =>
    hasPermission('manage:integrations'),
  );

  const showExportLimitErrorState = computed(
    () =>
      exportLimitReached?.value &&
      integration?.value?.type &&
      !NOT_LIMITED.includes(integration?.value.type),
  );

  const isIntegrationProfileConfigured = computed(() => {
    if (
      !hasIntegrationProfile.value ||
      integration?.value?.type === 'tray_gong'
    ) {
      return true;
    }
    return integration?.value?.configValues.some(
      (config) =>
        config.externalId === 'external_crossbeam-profile-id' &&
        config.value &&
        !!JSON.parse(config.value),
    );
  });

  const scheduledStartTimestamp = computed(() => {
    const config = integration?.value?.configValues?.find(
      (config) =>
        config.externalId === 'external_start_timestamp' && config.value,
    );

    if (config?.value) {
      return DateTime.fromISO(JSON.parse(config.value));
    }

    return null;
  });

  const { openConfigWindow, isConfiguringTray } = useTrayIntegrations();
  const reauthorizeTray = async () => {
    if (!integration?.value) throw new Error('Integration not found');
    const body = {
      org_name: currentOrg.value.name,
      instance_name: integration?.value?.name,
      solution_id: integration?.value?.solution.id,
      instance_id: integration?.value?.id,
    };

    const { data } = await crossbeamApi.PATCH('/v0.1/tray-integrations', {
      body,
    });
    openConfigWindow(data);
  };

  const getTotalOverlaps = async (
    populations: IntegrationProfilePopulationSetting[],
  ) => {
    const our_population_ids = populations
      .filter((p) => !p.is_partner_population)
      .map((p) => p.population_id);
    const partner_population_ids = populations
      .filter((p) => p.is_partner_population)
      .map((p) => p.population_id);
    if (!our_population_ids.length && !partner_population_ids.length)
      return null;
    const body = { our_population_ids, partner_population_ids };
    const { data } = await crossbeamApi.POST('/v0.3/overlaps/total', {
      body,
    });
    if (!data) return null;
    return data.total;
  };

  const workflowToStatusName = {
    'Sch. Create Hubspot Lists [CB HS]': 'Report to Contact List Status',
    'Sch. Entry Point: HubSpot Overlap Push': 'Custom Object Push Status',
    'Sch. HubSpot Ecosystem Fields Push': 'Standard Object Push Status',
    'SCH.Overlaps to Salesforce [SFDC Overlaps]': 'Custom Object Push Status',
    'SCH.Ecosystem fields to CSV [SFDC Ecosystem]':
      'Standard Object Push Status',
    'Sch. Get and Format Records [Gong]': 'Status',
    'Sch. Crossbeam Operations [CB Marketo]': 'Status',
    'SCH.CB Attributions to SFDC [CD SFDC]': 'Status',
    'SCH. Crossbeam to Dynamics [CB DYN]': 'Status',
  };

  const trayStatuses = computed(() => {
    if (!integration?.value?.id) return [];
    return sortBy(
      partnerCloudStore.getStatusesByInstanceId(integration.value.id),
      ['last_completed_timestamp'],
    ).reverse();
  });

  const firstError = computed(() => {
    const error = trayStatuses.value.find(
      (status) =>
        integration?.value?.id === status.solution_instance_id &&
        status.error &&
        !!workflowToStatusName[
          status.scheduled_workflow_name as keyof typeof workflowToStatusName
        ],
    );
    return error?.error?.error_type as TrayError;
  });

  const errorTimestamp = computed(() => {
    const error = trayStatuses.value.find(
      (status) =>
        integration?.value?.id === status.solution_instance_id &&
        status.error &&
        !!workflowToStatusName[
          status.scheduled_workflow_name as keyof typeof workflowToStatusName
        ],
    );
    if (error?.error) {
      return DateTime.fromISO(error.error?.timestamp);
    }
    return null;
  });

  const statusText = computed(() => {
    if (reauthPending.value) {
      return 'Pending';
    }
    if (
      showExportLimitErrorState.value ||
      !isIntegrationProfileConfigured.value ||
      !!firstError.value ||
      needsAuth.value
    ) {
      return 'Action needed';
    }
    return integration?.value?.enabled ? 'Active' : 'Paused';
  });

  const statusToTypeMap = {
    Paused: 'warning',
    Active: 'success',
    'Action needed': 'error',
    Pending: 'warning',
  } as const;
  const statusType = computed<IntegrationProfileStatus>(
    () => statusToTypeMap[statusText.value] ?? undefined,
  );

  const calloutType = computed(() => {
    const type = statusToTypeMap[statusText.value];
    if (type === 'error') return 'danger';
    return type === 'warning' ? type : undefined;
  });

  const hasIntegrationProfile = computed(() =>
    [
      'tray_salesforce_push',
      'tray_hubspot',
      'tray_clari',
      'tray_microsoft_dynamics',
    ].includes(integration?.value?.type ?? ''),
  );

  const needsAuth = computed(() => {
    return (
      !integration?.value?.authValues?.length ||
      firstError.value === 'auth_error' ||
      firstError.value === 'crossbeam_auth_error' ||
      (!hasIntegrationProfile.value && !integration?.value?.enabled)
    );
  });

  const reauthPending = computed(() => {
    return (
      needsAuth.value &&
      scheduledStartTimestamp.value &&
      errorTimestamp.value &&
      errorTimestamp.value < scheduledStartTimestamp.value
    );
  });

  const friendlyNameMap: Record<TrayIntegrationType, string> = {
    tray_salesforce_push: 'Salesforce',
    tray_gong: 'Gong',
    tray_hubspot: 'HubSpot',
    tray_salesforce_attribution: 'Salesforce',
    tray_clari: 'Clari',
    tray_marketo: 'Marketo',
    tray_microsoft_dynamics: 'Microsoft Dynamics',
  } as const;

  const calloutText = computed(() => {
    if (hasIntegrationProfile.value && !isIntegrationProfileConfigured.value) {
      return 'To finish your set-up, enable your data push, configure your data selection and save your settings';
    }
    if (showExportLimitErrorState.value) {
      return 'Reach out to <a target="_blank" href="mailto:support@crossbeam.com" class="text-secondary-text">support@crossbeam.com</a> to increase your export limit';
    }

    if (reauthPending?.value) {
      return 'Re-authorization in progress';
    }

    if (needsAuth.value) {
      return 'You need to reauthorize connection to use this integration';
    }

    if (isSalesforce.value && !hasSalesforceConnection.value) {
      return 'You need to reauthorize the Salesforce connection with Crossbeam';
    }

    // specific error messages
    const errorMessages: Partial<Record<TrayError, string>> = {
      quota_check_failed: 'You have reached your Salesforce API limit',
      permission_check_failed:
        'You need the right Salesforce permission to authorize this integration',
      scope_check_failed:
        'Please update your HubSpot private app settings to include the required "crm.import" scope',
      crossbeam_auth_error: `You need to reauthorize the ${integration?.value?.name} integration with Crossbeam`,
    };

    if (firstError.value) {
      return (
        errorMessages[firstError.value] ||
        'Reach out to <a target="_blank" href="mailto:support@crossbeam.com" class="text-secondary-text">support@crossbeam.com</a> for further assistance'
      );
    }

    if (!integration?.value?.enabled) {
      return `Data is not currently pushing to ${friendlyNameMap[integration?.value?.type as TrayIntegrationType]}`;
    }

    return '';
  });

  const isHubspot = computed(() => integration?.value?.type === 'tray_hubspot');
  const isSalesforce = computed(
    () => integration?.value?.type === 'tray_salesforce_push',
  );

  const statuses = computed<TrayIntegrationHeaderStatus[]>(() => {
    const integrationStatus: TrayIntegrationHeaderStatus = {
      type: statusType.value ?? undefined,
      text: statusText.value,
      lastPushed: trayStatuses.value?.[0]?.last_completed_timestamp,
    };
    if ((isHubspot.value || isSalesforce.value) && trayStatuses.value?.length) {
      const statuses: TrayIntegrationHeaderStatus[] = trayStatuses.value
        .filter(
          (status) =>
            !!workflowToStatusName[
              status.scheduled_workflow_name as keyof typeof workflowToStatusName
            ],
        )
        .map((status) => ({
          name: workflowToStatusName[
            status.scheduled_workflow_name as keyof typeof workflowToStatusName
          ],
          type: status.error && !reauthPending.value ? 'error' : 'success',
          text:
            status.error && !reauthPending.value ? 'Action needed' : 'Active',
          lastPushed: status.last_completed_timestamp,
        }));
      return statuses || [];
    }
    return integrationStatus ? [integrationStatus] : [];
  });

  const salesforceConnection = computed(() =>
    useConnectionsStore().getConnectionByType('salesforce'),
  );

  const hasSalesforceConnection = computed(() => {
    const version = salesforceConnection.value?.config?.app_version;
    const minSupportedVersion = MIN_SUPPORTED_SALESFORCE_VERSION;

    return (
      !!salesforceConnection.value &&
      version &&
      parseFloat(version) >= minSupportedVersion
    );
  });

  const version = computed(() => {
    if (!isSalesforce.value) return undefined;
    return salesforceConnection.value?.config?.app_version;
  });

  return {
    statusType,
    statusText,
    calloutText,
    calloutType,
    statuses,
    salesforceConnection,
    hasSalesforceConnection,
    version,
    needsAuth,
    reauthPending,
    firstError,
    reauthorizeTray,
    hasIntegrationProfile,
    isIntegrationProfileConfigured,
    getTotalOverlaps,
    canManageIntegrations,
    isConfiguringTray,
  };
}
