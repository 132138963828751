/* Step Types */
export enum StepType {
  InvitePartner = 1,
  ConnectData = 2,
  ShareData = 3,
  Uncover = 4,
}

/* Onboarding V3 */
export const PROGRESS = 'progress';
export const PENDING = 'pending';
export const COMPLETE = 'complete';
export const ERRORED = 'errored';
export const LOCKED = 'locked';
export const NOT_SYNCED = 'not_synced';
export const NEEDS_SHEET = 'needs_sheet';
export const DELETING = 'deleting';
export const NEEDS_PREVIOUS = 'needs_previous';
export const CALCULATING = 'calculating';

export type StatusType =
  | typeof PROGRESS
  | typeof PENDING
  | typeof COMPLETE
  | typeof ERRORED
  | typeof LOCKED
  | typeof NOT_SYNCED
  | typeof NEEDS_SHEET
  | typeof DELETING
  | typeof NEEDS_PREVIOUS
  | typeof CALCULATING;

/* Returns list of objects indicating whether a feature is supported or not */
export function makeFeatureList(features: string[], supported: boolean[]) {
  return features.map((feature, i) => ({
    [feature]: supported[i],
  }));
}

export enum OnboardingStepType {
  INVITE_TEAMMATES = 'invite_teammates',
  IMPORT_DATA = 'import_data',
  INVITE_PARTNERS = 'invite_partners',
  SEGMENT_DATA = 'segment_data',
  SHARE_DATA = 'share_data',
  ACCOUNT_MAP = 'account_map',
}

export const onboardingSteps: OnboardingStepType[] = [
  OnboardingStepType.INVITE_TEAMMATES,
  OnboardingStepType.IMPORT_DATA,
  OnboardingStepType.INVITE_PARTNERS,
  OnboardingStepType.SEGMENT_DATA,
  OnboardingStepType.SHARE_DATA,
  OnboardingStepType.ACCOUNT_MAP,
];

export type Step = {
  step: OnboardingStepType;
  status: string;
};
