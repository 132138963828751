import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import {
  Notification,
  NotificationQuery,
  NotificationSetting,
} from '@/types/notifications';

import { initStore, pollStore } from './store-utils';

export const useNotificationsStore = defineStore('Notifications', () => {
  const newItemsHref = ref<string | null>(null);
  const allNotifications = ref<Notification[]>([]);
  const settings = ref<NotificationSetting[]>([]);
  const isLoading = ref(true);
  const isLoadingMore = ref(false);

  const unreadNotifications = computed(() =>
    allNotifications.value.filter((n) => !n.is_read),
  );

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data, error } = await crossbeamApi.GET('/v0.1/notifications');
      if (error) {
        captureException(error);
        isLoading.value = false;
        return;
      }
      if (!data) return;

      const fetchedNotifications = data.items.filter(
        (item: Notification) => item.event_type !== 'data_request',
      );
      const newNotifications = fetchedNotifications.filter(
        (item: Notification) =>
          !allNotifications.value.some((existing) => existing.id === item.id),
      );
      if (!allNotifications.value.length || !isPolling.value) {
        newItemsHref.value = data.prev_href;
      }
      allNotifications.value = [...newNotifications, ...allNotifications.value];

      await refreshSettings();
    },
  );

  const loadMore = async () => {
    if (!newItemsHref.value || isLoadingMore.value) return;

    isLoadingMore.value = true;

    const queryParams: Record<string, string | number | undefined> = {};
    const searchParams = new URLSearchParams(
      new URL(newItemsHref.value).search,
    );
    searchParams.forEach((value, key) => {
      queryParams[key as keyof NotificationQuery] =
        key === 'limit' ? Number(value) : value;
    });

    const { data, error } = await crossbeamApi.GET('/v0.1/notifications', {
      params: { query: queryParams },
    });
    if (error) {
      isLoadingMore.value = false;
      captureException(error);
      return;
    }
    isLoadingMore.value = false;
    if (!data) return;

    const filteredItems = data.items.filter(
      (item: Notification) => item.event_type !== 'data_request',
    );

    allNotifications.value = [...allNotifications.value, ...filteredItems];
    newItemsHref.value = data.items.length ? data.prev_href : null;
  };

  async function refreshSettings(): Promise<void> {
    const { data } = await crossbeamApi.GET('/v0.1/notification-settings');
    isLoading.value = false;
    if (!data) return;
    settings.value = data.items;
  }
  async function markRead({ id, isRead }: { id: string; isRead: boolean }) {
    const path = { id };
    const { data } = await crossbeamApi.PATCH('/v0.1/notifications/{id}', {
      params: { path },
      body: { is_read: isRead },
    });
    allNotifications.value = allNotifications.value.map(
      (note: Notification) => {
        if (note.id !== id) return note;
        return { ...note, ...data };
      },
    );
  }
  async function markAllRead(): Promise<void> {
    await crossbeamApi.POST('/v0.1/notifications/mark-all-read');
    allNotifications.value.map((note) => {
      note.is_read = true;
    });
  }
  async function bulkUpdateSettings({
    items,
  }: {
    items: NotificationSetting[];
  }): Promise<void> {
    await crossbeamApi.PUT('/v0.1/notification-settings/bulk', {
      body: { items },
    });
    await refreshSettings();
  }

  const { startPolling, stopPolling, isPolling } = pollStore(
    readySync,
    refresh,
  );

  return {
    error: initError,
    isLoading,
    isLoadingMore,
    loadMore,
    ready,
    readySync,
    running,
    refreshNotificationsStore: refresh,
    newItemsHref,
    allNotifications,
    unreadNotifications,
    settings,
    markRead,
    markAllRead,
    bulkUpdateSettings,
    startPolling,
    stopPolling,
    isPolling,
  };
});
