import { itly as Itly } from '@crossbeam/itly';
import { EmptyObject, Nullable } from '@crossbeam/types';

import axios from 'axios';
import EventEmitter from 'eventemitter3';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import appConfig from '@/config';
import {
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { isVerified } from '@/constants/verification_status';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import { isErrorWithResponse } from '@/types/common';
import { BasePermission } from '@/types/permissions';
import { Authorization, Invitation, Organization, User } from '@/types/root';
import { SeatRequest, SeatRequestStatus } from '@/types/seat_requests';
import urls from '@/urls';
import { createAxiosWithRetry, indexBy, isLoginPopup } from '@/utils';

export const useRootStore = defineStore('RootStore', () => {
  const initializationFailed = ref(false);
  const hasProfile = ref(false);
  const authorizations = ref<Authorization[]>([]);
  const currentUser = ref<User | EmptyObject>({});
  const currentOrg = ref<Organization | EmptyObject>({});
  const currentAuth = ref<Nullable<Authorization>>(null);
  const visiblePartnerOrgIds = ref<Set<number>>();
  const sessionLoaded = ref(false);
  const pendingInvitations = ref<Invitation[]>([]);
  const seatRequests = ref<SeatRequest[]>([]);
  const maintenanceModeEvent = ref(null);
  const orgNotifier = ref<Nullable<EventEmitter>>(null);
  const iteratively = ref<typeof Itly | null>(null);
  const inviteTeamSkipped = ref<boolean>(false);

  const isLoggedIn = computed(() => {
    // Ensure that the login screen is hit when Crossbeam is loaded as a login popup for the widget
    return sessionLoaded.value && !isLoginPopup();
  });

  const orgMarkedAsFailed = computed(() => {
    return currentOrg.value?.verification_status === 'failed';
  });

  const userNeedsToRegister = computed(() => {
    return authorizations.value.length === 0;
  });

  const getPendingInvitation = computed(() => {
    return pendingInvitations.value[0];
  });

  const getPendingSeatRequest = computed(() =>
    seatRequests.value.find((r) => r.status === SeatRequestStatus.pending),
  );

  const userCanTryToLinkAccounts = computed(
    () => currentUser.value?.isLinkable === true,
  );

  const userHasNoOrg = computed(
    () => !!currentUser.value?.id && authorizations.value.length === 0,
  );

  const currentPermissions = computed<Record<string, BasePermission>>(() =>
    indexBy(currentAuth.value?.role.permissions, 'id'),
  );

  const currentScopes = computed(
    () =>
      new Set(
        currentAuth.value?.role?.permissions?.flatMap((p) => p.scopes) ?? [],
      ),
  );

  const preferredCrm = computed(() => {
    if (HUBSPOT_DATA_SOURCE_TYPE === currentOrg.value?.preferred_crm) {
      return HS3_DATA_SOURCE_TYPE;
    }

    return currentOrg.value?.preferred_crm;
  });

  const hasSalesEdgeOnly = computed(() => {
    return (
      currentAuth.value?.role === null &&
      currentAuth.value.sales_edge_role !== null
    );
  });

  const fiscalYearStart = computed(() => {
    return currentOrg.value?.fiscal_year_start ?? 1;
  });

  function getFiscalYearStart(fiscalYearStartMonth: number): DateTime {
    const now = DateTime.local();
    const currentYear = now.year;
    const currentMonth = now.month;

    let fiscalYearStartYear = currentYear;

    if (currentMonth < fiscalYearStartMonth) {
      fiscalYearStartYear -= 1;
    }

    return DateTime.local(fiscalYearStartYear, fiscalYearStartMonth, 1);
  }

  type QuarterNames = 'first' | 'second' | 'third' | 'fourth';

  type QuarterLookup = {
    [K in QuarterNames]: { start: DateTime; end: DateTime };
  };

  /**
   * Creates a lookup object for fiscal quarters based on the starting month of the fiscal year.
   *
   * @returns {QuarterLookup} returns an object with keys being the quarter name (first, second, third, fourth)
   * and values being objects with 'start' and 'end' luxon DateTime objects representing the start and end dates of the corresponding fiscal quarter.
   * @example
   * // For a fiscal year starting in July:
   * // Returns:
   * // {
   * //   first: { start: DateTime for July 1, end: DateTime for September 30 },
   * //   second: { start: DateTime for October 1, end: DateTime for December 31 },
   * //   third: { start: DateTime for January 1, end: DateTime for March 31 },
   * //   fourth: { start: DateTime for April 1, end: DateTime for June 30 }
   * // }
   */
  const quarterLookup = computed(() => {
    const currentFiscalYear = getFiscalYearStart(fiscalYearStart.value);

    return (['first', 'second', 'third', 'fourth'] as const).reduce(
      (acc, quarter, index) => {
        const start = currentFiscalYear.plus({ months: index * 3 });
        const end = start.plus({ months: 2 }).endOf('month');

        acc[quarter] = { start, end };
        return acc;
      },
      {} as QuarterLookup,
    );
  });

  const currentQuarter = computed(() => {
    const currentMonth = DateTime.local().month;

    return Object.values(quarterLookup.value).find(({ start, end }) => {
      // handle wrapping around the year
      if (start.month > end.month) {
        return currentMonth >= start.month || currentMonth <= end.month;
      }
      return currentMonth >= start.month && currentMonth <= end.month;
    });
  });

  function hasPermission(permission: string) {
    return Object.hasOwn(currentPermissions.value, permission);
  }

  function hasScope(scope: string) {
    return currentScopes.value.has(scope);
  }

  function resourcePermission(resource: string) {
    return currentAuth.value?.role.permissions.find(
      (perm) => perm.resource === resource,
    );
  }

  interface MeResponse {
    user: User;
    is_user_linkable: boolean;
    authorizations: Authorization[];
    pending_invitations: Invitation[];
    invite_requests: SeatRequest[];
  }
  async function loadUserProfile({
    initialLogin,
  }: { initialLogin?: boolean } = {}) {
    const userAxios = createAxiosWithRetry();
    // Keeping this out of the main try catch so we don't break the app on fail
    if (appConfig.statuspageBaseUrl) {
      await pollStatuspageEvents();
    }

    try {
      if (initialLogin) {
        // Additional security check that state parameter is being passed correctly
        const params = new URLSearchParams(window.location.search);
        const oauthState = params.get('state');
        const savedOauthState = ls.oauthState.get();
        if (savedOauthState && savedOauthState !== oauthState) {
          ls.oauthState.set();
          const queryString = window.location.search;
          window.location.replace(`/logout${queryString}`);
          return;
        }
      }

      ls.oauthState.set(); /* Clear the state value after successfully passing above state check */
      let userResponse;
      try {
        const url = urls.users.me;
        userResponse = await userAxios.get<MeResponse>(url);
      } catch (err: unknown) {
        if (isErrorWithResponse(err) && err.response?.status === 401) {
          return;
        }
        throw err;
      }

      loadUserProfileComplete(userResponse?.data);

      if (
        userNeedsToRegister.value ||
        userHasNoOrg.value ||
        !isVerified(currentOrg.value)
      ) {
        return;
      }

      hasProfile.value = true;
    } catch (err) {
      if (!maintenanceModeEvent.value) {
        captureException(err);
        initializationFailed.value = true;
      }
    }
  }

  type UpdateUserInfoPayload = Record<
    | 'first_name'
    | 'last_name'
    | 'pronouns'
    | 'department'
    | 'job_title'
    | 'company_position',
    string
  >;
  async function updateUserInfo(payload: UpdateUserInfoPayload) {
    try {
      const { data: user } = await axios.patch(urls.users.meV2, payload);
      currentUser.value = { ...currentUser.value, ...user };
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  async function updateOrgInfo(preferredCrmFeedId: number) {
    currentOrg.value.preferred_crm_feed_id = preferredCrmFeedId;
    await axios.patch(urls.org.patch, {
      preferred_crm_feed_id: preferredCrmFeedId,
    });
  }

  async function pollStatuspageEvents() {
    if (!appConfig.statuspageBaseUrl) return;
    try {
      const response = await fetch(
        `${appConfig.statuspageBaseUrl}${urls.external.activeStatusPageEvents}`,
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch statuspage events: ${response.statusText}`,
        );
      }
      const statusPageResponse = await response.json();
      const scheduledEvent = statusPageResponse?.scheduled_maintenances?.at(0);
      maintenanceModeEvent.value = scheduledEvent ?? null;
      setTimeout(() => {
        pollStatuspageEvents();
      }, 10000);
    } catch (err) {
      captureException(err);
    }
  }

  function setIsInviteTeamSkipped() {
    ls.onboardingInviteSkip.set(currentOrg.value.id);
    refreshIsInviteTeamSkipped();
  }

  function refreshIsInviteTeamSkipped() {
    inviteTeamSkipped.value =
      ls.onboardingInviteSkip.get() === currentOrg.value.id;
  }

  function loadUserProfileComplete(data: MeResponse | undefined) {
    sessionLoaded.value = true;
    if (data && data.user) {
      currentUser.value = {
        ...data.user,
        isLinkable: data.is_user_linkable,
      };
      authorizations.value = data.authorizations;
      pendingInvitations.value = data.pending_invitations;
      seatRequests.value = data.invite_requests || [];
      const orgId = ls.orgId.get();
      const authFromLocalStorage = orgId
        ? data.authorizations.find(
            (auth) =>
              auth.organization.id === parseInt(orgId) && auth.role !== null,
          )
        : null;
      if (
        authFromLocalStorage &&
        !authFromLocalStorage?.organization?.is_dormant
      ) {
        currentAuth.value = authFromLocalStorage;
      } else if (data.authorizations.length > 0) {
        currentAuth.value =
          data.authorizations.find((a) => !a.organization.is_dormant) ||
          data.authorizations[0] ||
          null;
      }

      if (Array.isArray(currentAuth.value?.partner_level_visibility?.visible)) {
        visiblePartnerOrgIds.value = new Set(
          currentAuth.value.partner_level_visibility.visible,
        );
      }

      currentOrg.value =
        authFromLocalStorage && !authFromLocalStorage?.organization?.is_dormant
          ? authFromLocalStorage.organization
          : currentAuth.value?.organization || {};

      refreshIsInviteTeamSkipped();

      if (orgNotifier.value?.emit) {
        orgNotifier.value?.emit('organizationFinalized', currentOrg.value);
      }
    }
  }

  function setIsVgActivationClosed(isClosed: boolean) {
    currentOrg.value.setup_menu_hidden = isClosed;
  }

  const hasHiddenPartners = computed(
    () => !!currentAuth.value?.partner_level_visibility?.hidden.length,
  );

  return {
    /* state and getters */
    authorizations,
    initializationFailed,
    currentAuth,
    currentOrg,
    currentQuarter,
    currentUser,
    fiscalYearStart,
    hasProfile,
    hasSalesEdgeOnly,
    inviteTeamSkipped,
    isLoggedIn,
    iteratively,
    maintenanceModeEvent,
    orgNotifier,
    getPendingInvitation,
    getPendingSeatRequest,
    quarterLookup,
    orgMarkedAsFailed,
    preferredCrm,
    refreshIsInviteTeamSkipped,
    seatRequests,
    sessionLoaded,
    setIsInviteTeamSkipped,
    userCanTryToLinkAccounts,
    userHasNoOrg,
    userNeedsToRegister,
    visiblePartnerOrgIds,
    hasHiddenPartners,

    /* Needed for Storybook */
    loadUserProfileComplete,

    /* actions */
    hasPermission,
    hasScope,
    loadUserProfile,
    resourcePermission,
    updateUserInfo,
    updateOrgInfo,
    setIsVgActivationClosed,
  };
});

export const createRootStore = (
  orgNotifier: EventEmitter,
  iteratively: typeof Itly | null,
) => {
  const store = useRootStore();
  store.orgNotifier = orgNotifier;
  store.iteratively = iteratively;
  return store;
};
