import { flatMap } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import { initStore } from '@/stores/store-utils';
import { DataSharePreset } from '@/types/data_share_presets';

export const useDataSharePresetsStore = defineStore(
  'DataSharePresetsStore',
  () => {
    const allPresets = ref<DataSharePreset[]>([]);

    const { ready, readySync, refresh } = initStore(async () => {
      const { data, error } = await crossbeamApi.GET(
        '/v0.1/data-share-presets',
      );
      if (error) throw error;
      if (data) allPresets.value = data;
    });

    function getFilteredPresetsByFeeId(feedId: number, baseSourceId?: number) {
      // CRM presets
      const presetsByFeedId =
        allPresets.value?.filter((item) => item.feed_id === feedId) || [];

      // Gsheet or Csv presets
      const presetsByBaseSourceId =
        presetsByFeedId.filter(
          (item) => item.base_source_id === baseSourceId,
        ) || [];

      return baseSourceId ? presetsByBaseSourceId : presetsByFeedId;
    }

    function getDefaultPresetId(feedId: number, baseSourceId?: number) {
      const filteredPresets = getFilteredPresetsByFeeId(feedId, baseSourceId);
      return filteredPresets.find(
        (preset) => preset.preset_type === 'crossbeam_default',
      )?.id;
    }

    function getPresetName(
      feedId: number,
      baseSourceId?: number,
      presetId?: number,
      isNew?: boolean,
    ) {
      const filteredPresets = getFilteredPresetsByFeeId(feedId, baseSourceId);
      return (
        filteredPresets.find((item) => item.id === presetId)?.name ||
        (isNew ? '' : 'Crossbeam default preset')
      );
    }

    function getSourceFieldIdsByPresetId(
      feedId: number,
      baseSourceId?: number,
      id?: number,
    ) {
      const filteredPresets = getFilteredPresetsByFeeId(feedId, baseSourceId);
      const preset = filteredPresets.filter(
        (preset: DataSharePreset) => preset.id === id,
      );
      return flatMap(preset, (preset: DataSharePreset) =>
        preset.source_fields.map(({ source_field_id }) => source_field_id),
      );
    }

    refresh({ useCache: true });

    return {
      ready,
      readySync,
      allPresets,
      getPresetName,
      getDefaultPresetId,
      getFilteredPresetsByFeeId,
      getSourceFieldIdsByPresetId,
      refreshDataSharePresetsStore: refresh,
    };
  },
);
