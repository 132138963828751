import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { values } from 'lodash';
import { storeToRefs } from 'pinia';
import { Ref, computed, onMounted, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import { crossbeamApi } from '@/api';
import { usePermissionsStore, useRolesStore } from '@/stores';
import { Permission } from '@/types/permissions';
import { Role, RoleWithoutId } from '@/types/roles';
import { indexBy } from '@/utils';

type RoleUpdateApiCall = ReturnType<
  typeof crossbeamApi.PUT<
    '/v0.1/roles/{id}',
    {
      params: {
        path: { id: string };
      };
      body: {
        name: string;
        description: string;
        permission_ids: string[];
      };
    }
  >
>;

type RequesterType = (data: {
  name: string;
  description: string;
  permission_ids: string[];
}) => RoleUpdateApiCall;

const role = ref<Role | RoleWithoutId>({
  name: '',
  description: '',
  permissions: [],
  role_type: 'limited_user',
  seat_type: 'core',
});
const loading = ref(true);

/* Helpers function */
function isUniqueMessage({ $model }: { $model: string }) {
  return `There is already a role named ${$model}.`;
}

export function useRoleDetail(
  roleId?: Ref<string | undefined>,
  viewOnly?: Ref<boolean | undefined>,
) {
  const router = useRouter();
  const rolesStore = useRolesStore();
  const permissionsStore = usePermissionsStore();
  const { defaultPermissions } = storeToRefs(permissionsStore);
  const showRoleDetailModal = ref(true);

  const isViewOnlyMode = computed(() => viewOnly?.value);
  const isEditMode = computed(() => !isViewOnlyMode.value && !!roleId?.value);
  const isCreateMode = computed(
    () => !isViewOnlyMode.value && !isEditMode.value,
  );
  const permissionSet = ref<Record<string, Permission>>({});

  const rules = {
    role: {
      name: {
        required: helpers.withMessage('Please name your role.', required),
        printable: helpers.withMessage(
          'Please only use letters, numbers, spaces, and dashes in the role name.',
          (value: string) => {
            const VALID_ROLE_NAME = /^[a-zA-Z0-9_\- ]+$/;
            return VALID_ROLE_NAME.test(value);
          },
        ),
        isUnique: helpers.withMessage(isUniqueMessage, (value: string, _) => {
          const existingRole = rolesStore.getRoleByName(value);
          return !existingRole || existingRole.id === (role.value as Role)?.id;
        }),
      },
      description: {
        required: helpers.withMessage('A description is required.', required),
      },
    },
  };

  const v$ = useVuelidate(rules, { role }, { $lazy: true });

  onMounted(async () => {
    await Promise.all([rolesStore.readySync, permissionsStore.readySync]);
    role.value =
      roleId?.value && rolesStore.getRoleById(roleId?.value)
        ? (rolesStore.getRoleById(roleId?.value) as Role)
        : {
            name: '',
            description: '',
            permissions: defaultPermissions.value,
            role_type: 'limited_user',
            seat_type: 'core',
          };
    permissionSet.value = indexBy(
      isCreateMode.value ? defaultPermissions.value : role.value.permissions,
      'resource',
    );
    loading.value = false;
  });

  watchEffect(() => {
    if (roleId?.value) {
      role.value = rolesStore.getRoleById(roleId.value) || {
        name: '',
        description: '',
        permissions: [],
        role_type: 'limited_user',
        seat_type: 'core',
      };
    }
  });

  async function refreshRoleDetail() {
    loading.value = true;
    await Promise.all([
      useRolesStore().refreshRolesStore(),
      usePermissionsStore().refreshPermissionsStore(),
    ]);
    loading.value = false;
  }

  async function writeRole(requester: RequesterType) {
    v$.value.$touch();
    if (v$.value.$invalid) return;
    loading.value = true;
    try {
      await requester({
        name: role.value.name,
        description: role.value.description,
        permission_ids: values(permissionSet.value).map((p) => p.id),
      });
    } finally {
      await rolesStore.refreshRolesStore();
      loading.value = false;
      showRoleDetailModal.value = false;
      await router.push({ name: 'roles' });
    }
  }
  async function createRole() {
    await writeRole((body) =>
      crossbeamApi.POST('/v0.1/roles', {
        body,
      }),
    );
  }
  async function saveRole() {
    await writeRole((body) =>
      crossbeamApi.PUT('/v0.1/roles/{id}', {
        params: {
          path: { id: (role.value as Role).id },
        },
        body,
      }),
    );
  }

  return {
    isCreateMode,
    isEditMode,
    isViewOnlyMode,
    loading,
    permissionSet,
    role,
    showRoleDetailModal,
    v$,
    createRole,
    saveRole,
    refreshRoleDetail,
  };
}
