<template>
  <BittsContainer class="c-empty-state-partner">
    <BittsSvg svg="empty-state-partner" class="my-[35px]" />
    <h3 class="text-lg font-bold">
      You don't have access to any Crossbeam partner yet
    </h3>
    <div class="mb-40">
      Please contact an administrator of your org to request the relevant
      accesses
    </div>
    <div class="w-full flex flex-col gap-4">
      <div
        v-for="(admin, index) in admins"
        :key="index"
        class="admin-row"
        :class="{ 'admin-row--striped': !(index % 2) }"
      >
        <div class="flex items-center gap-8">
          <BittsAvatar :user="admin.user" size="medium" :show-initials="true" />
          <div class="font-bold">
            {{ `${admin.user.first_name} ${admin.user.last_name}` }}
          </div>
          <BittsTag text="Administrator" size="x-small" color="secondary" />
        </div>
        <div>
          <BittsButton
            text="Request Access"
            size="small"
            type="neutral"
            @click="() => sendEmail(admin.user.email)"
          />
        </div>
      </div>
    </div>
  </BittsContainer>
</template>

<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsContainer,
  BittsSvg,
  BittsTag,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useRolesStore, useTeamStore } from '@/stores';

const rolesStore = useRolesStore();
const teamStore = useTeamStore();

const { authorizations } = storeToRefs(teamStore);

const admins = computed(() =>
  authorizations.value
    .filter((auth) => auth.user)
    .filter((auth) =>
      auth.role_id
        ? rolesStore.getRoleById(auth.role_id)?.name === 'Admin'
        : false,
    ),
);

function sendEmail(email: string) {
  window.location.href = `mailto:${email}?subject=Partner access request`;
}
</script>

<style lang="pcss" scoped>
.c-empty-state-partner {
  @apply flex flex-col items-center p-16;
}

.admin-row {
  @apply flex justify-between items-center p-12;
  &--striped {
    @apply bg-neutral-background-weak rounded-8;
  }
}
</style>
