import { groupBy, sortBy, uniqBy } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import { initStore } from '@/stores/store-utils';
import { Permission } from '@/types/permissions';
import { indexBy } from '@/utils';

type Resource = {
  name: string;
  displayName: string;
};

type PermissionLookupByResource = Record<string, Permission[]>;

type PermissionLookupById = Record<string, Permission>;

export const usePermissionsStore = defineStore('PermissionsStore', () => {
  const allResources = ref<Resource[]>([]);
  const defaultPermissions = ref<Permission[]>([]);
  const permissionGroups = ref<PermissionLookupByResource>({});
  const permissionDetails = ref<PermissionLookupById>({});

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data, error } = await crossbeamApi.GET('/v0.1/permissions');

      if (error) throw error;

      const permissions = data?.items || [];

      allResources.value = uniqBy(
        sortBy(permissions, 'resource_rank'),
        'resource',
      ).map((p) => ({
        name: p.resource,
        displayName: p.resource_display_name,
      }));
      permissionDetails.value = indexBy(permissions, 'id');
      permissionGroups.value = groupBy(permissions, 'resource');
      defaultPermissions.value = permissions
        .filter((p) => p.is_default)
        .map((p) => ({ ...p, is_default: false }));
    },
  );

  refresh({ useCache: true });

  return {
    error: initError,
    ready,
    readySync,
    refreshPermissionsStore: refresh,
    running,
    permissionDetails,
    allResources,
    permissionGroups,
    defaultPermissions,
  };
});
