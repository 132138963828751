import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
  HIDDEN,
  PARTNER_POPULATION_RULE,
  PARTNER_RULE,
  POPULATION_RULE,
} from '@/constants/data_shares';
import {
  IncomingDataShare,
  IncomingGreenfieldShare,
  IncomingSharingRule,
  OutgoingDataShare,
  OutgoingSharingRule,
  OutgoingSharingRuleMap,
  SharedFieldWithPartnerOrgId,
  isIncomingShare,
  isOutgoingShare,
} from '@/interfaces/data_shares';
import { usePopulationsStore } from '@/stores/PopulationsStore';
import { initStore } from '@/stores/store-utils';
import { DataSharingRule, DataSharingVisibility } from '@/types/data_shares';
import { SharingLevel } from '@/types/proposal';
import urls from '@/urls';

export const useDataSharesStore = defineStore('DataSharesStore', () => {
  const allIncomingSharedFields = ref<
    Record<number, SharedFieldWithPartnerOrgId>
  >({});
  const outgoingDataShares = ref<OutgoingDataShare[]>([]);
  const incomingDataShares = ref<IncomingDataShare[]>([]);
  const outgoingSharingRules = ref<OutgoingSharingRule[]>([]);
  const incomingSharingRules = ref<IncomingSharingRule[]>([]);
  const incomingGreenfieldShares = ref<IncomingGreenfieldShare[]>([]);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const [
        outgoingShareResponse,
        incomingShareResponse,
        outgoingSharingRuleResponse,
        incomingSharingRuleResponse,
      ] = await Promise.all([
        axios.get(urls.dataShares.outgoingShares),
        axios.get(urls.dataShares.incomingShares),
        axios.get(urls.dataShares.outgoingRules.default),
        axios.get(urls.dataShares.incomingRules),
      ]);

      outgoingDataShares.value = outgoingShareResponse.data.items;
      incomingDataShares.value = incomingShareResponse.data.items;
      outgoingSharingRules.value = outgoingSharingRuleResponse.data.items;
      incomingSharingRules.value = incomingSharingRuleResponse.data.items;
      allIncomingSharedFields.value = incomingDataShares.value.reduce(
        (
          sharedFields: Record<number, SharedFieldWithPartnerOrgId>,
          incomingDataShare: IncomingDataShare,
        ) => {
          incomingDataShare.shared_fields.forEach((share) => {
            sharedFields[share.source_field_id] = {
              ...share,
              partner_org_id: incomingDataShare.partner_org_id,
            };
          });
          return sharedFields;
        },
        {},
      );

      const { data } = await axios.get(
        urls.dataShares.incomingVisibility.greenfield,
      );
      incomingGreenfieldShares.value = data.items;
    },
  );

  refresh({ useCache: true });

  const outgoingSharingRuleMap = computed<OutgoingSharingRuleMap>(() => {
    return outgoingSharingRules.value.reduce(
      (lookup: OutgoingSharingRuleMap, share: OutgoingSharingRule) => {
        const rule = share.rule_type;
        if (!lookup[rule]) lookup[rule] = {};
        switch (rule) {
          case POPULATION_RULE:
            if (!lookup[rule]?.[share.population_id])
              lookup[rule][share.population_id] = [];
            lookup[rule][share.population_id]?.push(share);
            break;
          case PARTNER_RULE:
            if (!lookup[rule]?.[share.partner_org_id])
              lookup[rule][share.partner_org_id] = [];
            lookup[rule][share.partner_org_id]?.push(share);
            break;
          case PARTNER_POPULATION_RULE:
            if (!lookup[rule]?.[share.partner_population_id])
              lookup[rule][share.partner_population_id] = [];
            lookup[rule][share.partner_population_id]?.push(share);
            break;
          default:
            break;
        }
        return lookup;
      },
      {},
    );
  });

  const greenfieldSharesLookup = computed<Record<number, number[]>>(() => {
    return incomingGreenfieldShares.value.reduce(
      (lookup: Record<number, number[]>, share: IncomingGreenfieldShare) => {
        lookup[share.partner_organization_id] = share.partner_population_ids;
        return lookup;
      },
      {},
    );
  });

  const isSharingDataWithPartners = computed(() => {
    const visibleDefaultShares = outgoingSharingRules.value.filter(
      (dataShare) => dataShare.visibility !== HIDDEN,
    );
    return (
      visibleDefaultShares.length > 0 || outgoingDataShares.value.length > 0
    );
  });

  /* getters */
  function getAllIncomingDataShares({
    partnerOrgId,
    partnerPopulationId,
  }: {
    partnerOrgId: number;
    partnerPopulationId: number;
  }): (IncomingDataShare | IncomingSharingRule)[] {
    const matchingShare = (
      share: IncomingDataShare | IncomingSharingRule,
    ): boolean =>
      share.partner_org_id === partnerOrgId &&
      share.partner_population_id === partnerPopulationId;
    const incomingRules = incomingSharingRules.value.filter(matchingShare);
    const incomingShares = incomingDataShares.value.filter(matchingShare);
    return [...incomingRules, ...incomingShares];
  }

  function getIncomingDataShare({
    populationId,
    partnerPopulationId,
  }: {
    populationId: number;
    partnerPopulationId: number;
  }): IncomingDataShare | undefined {
    return incomingDataShares.value.find((dataShare) => {
      return (
        dataShare.partner_population_id === partnerPopulationId &&
        dataShare.population_ids.includes(populationId)
      );
    });
  }

  function getDataShareById(
    id: number,
  ):
    | IncomingDataShare
    | OutgoingDataShare
    | IncomingSharingRule
    | OutgoingSharingRule
    | undefined {
    return (
      incomingDataShares.value.find((share) => share.id === id) ||
      outgoingDataShares.value.find((share) => share.id === id) ||
      outgoingSharingRules.value.find((share) => share.id === id) ||
      incomingSharingRules.value.find((share) => share.id === id)
    );
  }

  function getDataShareWithPopulationInfo(id: number) {
    const populationsStore = usePopulationsStore();
    const dataShare = getDataShareById(id);
    if (!dataShare) return {};
    let partnerPopulation;
    let ourPopulation;
    if (isIncomingShare(dataShare)) {
      partnerPopulation = populationsStore.getPartnerPopulationById(
        dataShare.partner_population_id,
      );
    }
    if (isOutgoingShare(dataShare)) {
      ourPopulation = populationsStore.getPopulationById(
        dataShare.population_id,
      );
    }
    return { partnerPopulation, ourPopulation, dataShare };
  }

  function getIncomingSharingRules({
    partnerOrgId,
    populationId,
    partnerPopulationId,
    isOverride = false,
  }: {
    partnerOrgId?: number;
    populationId?: number;
    partnerPopulationId?: number;
    isOverride?: boolean;
  }): IncomingSharingRule[] {
    return incomingSharingRules.value.filter(
      (dataShare: IncomingSharingRule) => {
        const filters = [];
        filters.push(dataShare.is_override === isOverride);
        if (partnerOrgId)
          filters.push(dataShare.partner_org_id === partnerOrgId);
        if (populationId)
          filters.push(dataShare.population_id === populationId);
        if (partnerPopulationId)
          filters.push(dataShare.partner_population_id === partnerPopulationId);
        return filters.every((filter) => !!filter);
      },
    );
  }

  function getOutgoingSharingRules({
    ruleType,
    partnerOrgId,
    populationId,
    partnerPopulationId,
    visibility,
  }: {
    partnerOrgId?: number;
    populationId?: number;
    partnerPopulationId?: number;
    ruleType?: DataSharingRule;
    visibility?: DataSharingVisibility;
  }): OutgoingSharingRule[] {
    return outgoingSharingRules.value.filter(
      (dataShare: OutgoingSharingRule) => {
        const filters = [];
        if (ruleType) filters.push(dataShare.rule_type === ruleType);
        if (partnerOrgId) {
          filters.push(
            dataShare.partner_org_id === partnerOrgId ||
              dataShare.partner_org_id === null,
          );
        }
        if (populationId)
          filters.push(dataShare.population_id === populationId);
        if (partnerPopulationId) {
          filters.push(dataShare.partner_population_id === partnerPopulationId);
        }
        if (visibility) filters.push(dataShare.visibility === visibility);
        return filters.every((filter) => !!filter);
      },
    );
  }

  function getSharedFieldBySourceFieldId(
    sourceFieldId: number,
  ): SharedFieldWithPartnerOrgId | undefined {
    return allIncomingSharedFields.value[sourceFieldId];
  }

  function isSharingPopulationWithPartner(
    populationId: number,
    partnerOrgId: number,
  ): boolean {
    let partnerOverride;
    if (outgoingSharingRuleMap.value[PARTNER_RULE]?.[partnerOrgId]) {
      partnerOverride = outgoingSharingRuleMap.value[PARTNER_RULE][
        partnerOrgId
      ].find((share) => share.population_id === populationId);
    }
    if (partnerOverride) return partnerOverride.visibility !== HIDDEN;

    let generalPopulationSharingRule;
    if (outgoingSharingRuleMap.value[POPULATION_RULE]?.[populationId]) {
      // there will only ever be one general population rule per population, no need to .find
      generalPopulationSharingRule =
        outgoingSharingRuleMap.value[POPULATION_RULE][populationId][0];
    }
    if (generalPopulationSharingRule)
      return generalPopulationSharingRule.visibility !== HIDDEN;
    return false;
  }

  function isSharingPopulationWithPartnerPopulation(
    populationId: number,
    partnerOrgId: number,
    partnerPopulationId: number,
  ): boolean {
    // Check for a share rule in order of highest rule precedence:
    // partner population override, partner override, general population rule
    // I suspect rule_type must be partner_population if the previous constraints are met
    let partnerPopulationOverride;
    if (
      outgoingSharingRuleMap.value[PARTNER_POPULATION_RULE]?.[
        partnerPopulationId
      ]
    ) {
      partnerPopulationOverride = outgoingSharingRuleMap.value[
        PARTNER_POPULATION_RULE
      ][partnerPopulationId].find(
        (share) => share.population_id === populationId,
      );
    }
    if (partnerPopulationOverride)
      return partnerPopulationOverride.visibility !== HIDDEN;

    let partnerOverride;
    if (outgoingSharingRuleMap.value[PARTNER_RULE]?.[partnerOrgId]) {
      partnerOverride = outgoingSharingRuleMap.value[PARTNER_RULE][
        partnerOrgId
      ].find((share) => share.population_id === populationId);
    }
    if (partnerOverride) return partnerOverride.visibility !== HIDDEN;

    let generalPopulationSharingRule;
    if (outgoingSharingRuleMap.value[POPULATION_RULE]?.[populationId]) {
      // there will only ever be one general population rule per population, no need to .find
      generalPopulationSharingRule =
        outgoingSharingRuleMap.value[POPULATION_RULE][populationId][0];
    }
    if (generalPopulationSharingRule)
      return generalPopulationSharingRule.visibility !== HIDDEN;
    return false;
  }

  function populationSharesToServer(
    populationShares: {
      population_id: number;
      sharing_level: SharingLevel;
    }[],
  ) {
    return populationShares.map((populationShare) => {
      let sharingLevel = populationShare.sharing_level;
      const populationSharingSetting = getOutgoingSharingRules({
        ruleType: POPULATION_RULE,
      }).find(
        (setting) => setting.population_id === populationShare.population_id,
      ) || { visibility: HIDDEN };
      if (populationSharingSetting.visibility === sharingLevel)
        sharingLevel = 'use_defaults';
      return { ...populationShare, sharing_level: sharingLevel };
    });
  }

  /* actions */
  async function removeAndDeleteDataShare(id: number): Promise<void> {
    removeDataShare(id);
    try {
      const url = urls.dataShares.delete(id);
      await axios.delete(url);
    } catch (err) {
      refresh();
      throw err;
    }
  }

  function removeDataShare(id: number): void {
    outgoingDataShares.value = outgoingDataShares.value.filter(
      (ds) => ds.id !== id,
    );
    incomingDataShares.value = incomingDataShares.value.filter(
      (ds) => ds.id !== id,
    );
    outgoingSharingRules.value = outgoingSharingRules.value.filter(
      (ds) => ds.id !== id,
    );
  }

  function removeDataShareByPopulationId(populationId: number): void {
    outgoingDataShares.value = outgoingDataShares.value.filter(
      (ds) => ds.population_id !== populationId,
    );
    incomingDataShares.value = incomingDataShares.value
      .map((ds) => {
        const popIds = ds.population_ids.filter((id) => id !== populationId);
        return { ...ds, population_ids: popIds };
      })
      .filter((ds) => !!ds.population_ids.length);
    outgoingSharingRules.value = outgoingSharingRules.value.filter(
      (ds) => ds.population_id !== populationId,
    );
    incomingSharingRules.value = incomingSharingRules.value.filter(
      (ds) => ds.population_id !== populationId,
    );
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    allIncomingSharedFields,
    incomingDataShares,
    outgoingDataShares,
    getAllIncomingDataShares,
    getIncomingDataShare,
    getDataShareWithPopulationInfo,
    getDataShareById,
    getIncomingSharingRules,
    getOutgoingSharingRules,
    outgoingSharingRuleMap,
    getSharedFieldBySourceFieldId,
    greenfieldSharesLookup,
    incomingSharingRules,
    incomingGreenfieldShares,
    isSharingDataWithPartners,
    isSharingPopulationWithPartner,
    isSharingPopulationWithPartnerPopulation,
    outgoingSharingRules,
    populationSharesToServer,
    refreshDataSharesStore: refresh,
    removeAndDeleteDataShare,
    removeDataShareByPopulationId,
  };
});
