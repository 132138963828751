import { StoreGeneric, storeToRefs } from 'pinia';
import { Ref, computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import usePolling from '@/composables/usePolling';
import { captureException } from '@/errors';

/* Tells whether composable stores are ready.
 * They are either ready, or they are not "ready" but are
 * polling (which should only start after they are ready) */
export function allReady(...stores: StoreGeneric[]) {
  return computed(() =>
    stores.every((store) => {
      const { ready } = storeToRefs(store);
      return ready?.value;
    }),
  );
}

type ReadySync = Promise<void> | null;

/* Poll store after it is initialized.
 * Returns a poll function which can be used to start polling.
 * The refresh function should make the call itself. And to stop the polling,
 * simply set stopPoll to be true. */
export function pollStore(readySync: Ref<ReadySync>, refresh: () => void) {
  const stopPoll = ref(false);
  const isPolling = ref(false);
  async function startPolling(interval = 3000) {
    stopPoll.value = false;
    await readySync.value;
    if (isPolling.value) return;
    isPolling.value = true;
    usePolling({
      interval,
      shouldComplete() {
        const isStopping = stopPoll.value;
        if (isStopping) {
          isPolling.value = false;
          return true;
        }
        return false;
      },
      poller() {
        refresh();
        return Promise.resolve(undefined);
      },
    })();
  }

  function stopPolling() {
    stopPoll.value = true;
  }

  return { isPolling, startPolling, stopPolling };
}

interface CallbackArgs {
  useCache?: boolean | null;
  syncChargify?: boolean;
}

type InitStoreOpts = {
  requireAuth: boolean;
};

export function initStore(
  cb: (opts: CallbackArgs) => Promise<void>,
  initStoreOpts: InitStoreOpts = { requireAuth: true },
) {
  const { isLoggedIn } = useAuth();

  const ready = ref(false);
  const running = ref(false);
  const initError = ref<Error | null>(null);
  const readySync = ref<ReadySync>(null);

  async function _getData(opts: CallbackArgs): Promise<void> {
    running.value = true;
    try {
      await cb(opts);
    } catch (e) {
      const err = e as Error;
      captureException(err);
      initError.value = err;
    } finally {
      running.value = false;
      ready.value = true;
    }
  }

  function refresh(opts: CallbackArgs = { useCache: false }) {
    if (initStoreOpts.requireAuth && !isLoggedIn.value)
      return readySync.value; /* Store requires user to be logged in */
    if (opts.useCache && (ready.value || running.value))
      return readySync.value; /* Initializing store, used cached data if available */
    readySync.value = _getData(opts);
    return readySync.value;
  }

  return {
    initError,
    ready,
    running,
    readySync,
    refresh,
  };
}
