<template>
  <div class="c-datasource-settings-modal">
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="data.title"
      variant="sidebar"
      :loading="loading"
      :use-mask-to-close="true"
      :hide-footer="hideFooter"
      @closed="close"
      @action="handleAction"
      :disabled="saving || loading"
      :action-loading="saving"
      :no-padding="true"
    >
      <template #sidebar-header-prefix>
        <BittsSvg
          v-if="feed?.integration"
          class="w-24 h-24 mr-16"
          :svg="feed.integration.type + 'Icon'"
        />
      </template>
      <template #sidebar-footer>
        <BittsButton
          :disabled="saving"
          type="danger"
          text="Remove data source"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-datasource-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <ModalSidebarLink
          route-name="data-sources-settings-new"
          v-for="(item, i) in ModalSidebarItems"
          :item="item"
          :key="item.tab"
          :class="{
            'mt-auto': i === ModalSidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-datasource-settings-modal__content">
        <component
          :is="data.TabComponent"
          class="min-h-[600px]"
          :feed-id="feedId"
          :source-id="sourceId"
        />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DataSourceSettingsTabType } from '@/components/data-sources/SettingsModal/constants';
import DataImportTab from '@/components/data-sources/SettingsModal/DataImportTab.vue';
import FieldPresetsTab from '@/components/data-sources/SettingsModal/FieldPresetsTab/components/FieldPresetsTab.vue';
import GeneralTab from '@/components/data-sources/SettingsModal/GeneralTab.vue';
import SharingTab from '@/components/data-sources/SettingsModal/SharingTab.vue';
import ModalSidebarLink, {
  ModalSidebarItem,
} from '@/components/ModalSidebarLink.vue';

import useDataSourceSettings from '@/composables/useDataSourceSettings';

type Props = {
  feedId: number;
  sourceId?: number;
};
const { feedId, sourceId } = defineProps<Props>();

const router = useRouter();
const { handlePatchFeed, loading, saving, feed } = useDataSourceSettings();
const route = useRoute();

const tab = computed(() => route.params.tab as DataSourceSettingsTabType);
const hideFooter = computed(() => tab.value === 'data-import');
const data = computed(() => getData(tab.value));

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'general':
      await handlePatchFeed();
      break;
    default:
      await handlePatchFeed();
  }
}

async function close() {
  await router.push({ name: 'data-sources' });
}

async function showDeleteModal() {
  await router.push({ name: 'delete-data-source' });
}

// Helpers

function getData(tab: DataSourceSettingsTabType) {
  const item = ModalSidebarItems.find((i) => i.tab === tab);
  const title = item?.label || 'General';
  const TabComponent = item ? tabComponents[item.tab] : GeneralTab;
  return { title, TabComponent };
}
const ModalSidebarItems: ModalSidebarItem<DataSourceSettingsTabType>[] = [
  {
    label: 'General',
    icon: ['far', 'gear'],
    tab: DataSourceSettingsTabType.GENERAL,
  },
  {
    label: 'Field Presets',
    icon: ['far', 'arrow-right-arrow-left'],
    tab: DataSourceSettingsTabType.FIELD_PRESETS,
  },
  {
    label: 'Data Import',
    icon: ['far', 'upload'],
    tab: DataSourceSettingsTabType.DATA_IMPORT,
  },
];
const tabComponents = {
  [DataSourceSettingsTabType.GENERAL]: GeneralTab,
  [DataSourceSettingsTabType.SHARING]: SharingTab,
  [DataSourceSettingsTabType.FIELD_PRESETS]: FieldPresetsTab,
  [DataSourceSettingsTabType.DATA_IMPORT]: DataImportTab,
};
</script>

<style lang="pcss">
.c-datasource-settings-modal__content {
  @apply flex flex-col p-24;
}
</style>
