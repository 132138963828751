import { Nullable } from '@crossbeam/types';

import { differenceBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, onBeforeMount, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import { useFeedsStore, useFlashesStore, useSourcesStore } from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import {
  BusinessSettingDefinition,
  CoercedOperand,
  NewExistingPopulationPrefix,
  OperandOption,
  OperandsLookup,
  OpportunityFiltersPayload,
  SourceOption,
} from '@/types/business_settings';

import { usePreferredCRMFeed } from './usePreferredCRMFeed';

const businessSettings = ref<BusinessSettingDefinition[]>([]);

export function __resetBusinessSettings() {
  businessSettings.value = [];
}

export default function useBusinessSettings() {
  const sourcesStore = useSourcesStore();
  const { updateOrgInfo } = useRootStore();
  const { preferredCRMFeed } = usePreferredCRMFeed();

  const loading = ref(false);
  const selectedFeedId = ref<Nullable<number>>(null);
  const selectedOpportunityFieldId = ref<Nullable<number>>(null);
  const selectedOperandsLookup = ref<OperandsLookup>({
    newBusiness: [],
    existingBusiness: [],
  });
  const opportunityFieldOptions = ref<SourceOption[]>([]);
  const operandOptions = ref<OperandOption[]>([]); // operands are the values of the selected source field

  const isDataSourceInputDisabled = computed(() => crmFeeds.value.length === 1);

  function resetOperands() {
    selectedOperandsLookup.value.newBusiness = [];
    selectedOperandsLookup.value.existingBusiness = [];
  }

  function resetAll() {
    resetOperands();
    selectedOpportunityFieldId.value = null;
    opportunityFieldOptions.value = [];
    operandOptions.value = [];
  }

  function filterOperandOptions(
    excludedValues: string[],
    options: OperandOption[],
  ) {
    return differenceBy(
      options,
      excludedValues.map((val) => ({ value: val })),
      'value',
    );
  }

  const isMultiSelect = computed(
    () => selectedSourceField.value?.dataType === 'string',
  );

  function createAndDiffBusinessOptions(key: keyof OperandsLookup) {
    return isMultiSelect.value
      ? filterOperandOptions(
          selectedOperandsLookup.value[key] as string[],
          operandOptions.value,
        )
      : operandOptions.value;
  }

  const newBusinessOptions = computed(() =>
    createAndDiffBusinessOptions('existingBusiness'),
  );

  const recurringBusinessOptions = computed(() =>
    createAndDiffBusinessOptions('newBusiness'),
  );

  const selectedSourceField = computed(() =>
    opportunityFieldOptions.value.find(
      (field) => field.value === selectedOpportunityFieldId.value,
    ),
  );

  function getDataSourceSvg(integrationType: string) {
    let name = integrationType;
    if (name === 'hubspot_v3') name = 'hubspot';
    return `${name}Icon`;
  }

  const feedsStore = useFeedsStore();
  const { crmFeeds } = storeToRefs(feedsStore);
  const feedOptions = computed(() =>
    crmFeeds.value.map((feed) => ({
      id: feed.id,
      label: feed.integration.friendly_name,
      name: feed.integration.friendly_name,
      value: feed.id,
      svg: getDataSourceSvg(feed.integration.type),
    })),
  );

  const dealSourceFromSelectedFeed = computed(() =>
    sourcesStore.dealSources.find((source) => {
      return source.feed_id === selectedFeedId.value;
    }),
  );

  const isValidSettings = computed(() => {
    const { newBusiness, existingBusiness } = selectedOperandsLookup.value;
    const hasFeedAndFieldSelected =
      !!selectedFeedId.value && !!selectedOpportunityFieldId.value;

    const hasNew = Array.isArray(newBusiness)
      ? newBusiness.length > 0
      : !!newBusiness;

    const hasExisting = Array.isArray(existingBusiness)
      ? existingBusiness.length > 0
      : !!existingBusiness;

    return hasFeedAndFieldSelected && hasNew && hasExisting;
  });

  const createFilterParts = (label: string, inOperand: CoercedOperand) => {
    /* we use "true" and "false" strings to play well with select component, coercing to Boolean for final payload */
    const outOperand = Array.isArray(inOperand)
      ? inOperand
      : [JSON.parse(inOperand) as boolean];

    return [
      {
        source_field_id: selectedOpportunityFieldId.value as number,
        label,
        operator: 'in' as const,
        operand: outOperand,
      },
    ];
  };

  function getAccountSourceId() {
    const accountSources = sourcesStore
      .getSourcesByMdmType('account')
      .filter((source) => source.feed_id === selectedFeedId.value)
      .at(0)?.id;

    return accountSources;
  }
  const createPayloadItem = (
    nameSuffix: NewExistingPopulationPrefix,
    uuid: string,
    operand: CoercedOperand,
  ) => {
    const accountSourceId = getAccountSourceId();
    if (!dealSourceFromSelectedFeed.value?.id || !accountSourceId) return null;

    return {
      name: `${nameSuffix}-${selectedFeedId.value}`,
      source_id: accountSourceId,
      source_filter_expressions: {
        [`${dealSourceFromSelectedFeed.value?.table}`]: {
          filter_expression: [uuid],
          filter_parts: createFilterParts(uuid, operand),
        },
      },
    };
  };

  function buildPayload() {
    const newBusinessUUID = uuidv4();
    const recurringBusinessUUID = uuidv4();

    const newBusinessSetting = createPayloadItem(
      'net-new',
      newBusinessUUID,
      selectedOperandsLookup.value.newBusiness,
    );

    const recurringBusinessSetting = createPayloadItem(
      'existing',
      recurringBusinessUUID,
      selectedOperandsLookup.value.existingBusiness,
    );

    if (!newBusinessSetting || !recurringBusinessSetting) {
      return null;
    }
    return {
      items: [newBusinessSetting, recurringBusinessSetting],
    };
  }

  const fetchOpportunityFilters = async () => {
    const { data, error } = await crossbeamApi.GET('/v0.1/opportunity-filters');

    if (error) throw error;

    businessSettings.value = data?.items as unknown as [
      BusinessSettingDefinition,
      BusinessSettingDefinition,
    ];
  };

  const { addErrorFlash, addSuccessFlash } = useFlashesStore();
  async function saveSettings() {
    if (!isValidSettings.value || !selectedFeedId.value) return;

    try {
      const payload = buildPayload();

      if (!payload) {
        throw new Error('No settings defined');
      }

      const { error } = await crossbeamApi.PUT('/v0.1/opportunity-filters', {
        body: payload as OpportunityFiltersPayload,
      });

      await Promise.all([
        fetchOpportunityFilters(),
        updateOrgInfo(selectedFeedId.value),
      ]);

      if (error) {
        throw error;
      }

      addSuccessFlash({ message: 'Settings successfully saved' });
    } catch (error) {
      captureException(error);
      addErrorFlash({ message: 'Failed to save settings' });
    }
  }

  async function fetchFilterableSources(
    sourceId: number,
  ): Promise<SourceOption[]> {
    const { data, error } = await crossbeamApi.GET(
      '/v0.2/sources/{id}/filterable-sources',
      { params: { path: { id: sourceId } } },
    );

    if (error) {
      captureException(error);
      return [];
    }

    const fields =
      data?.items.find((source) => source.mdm_type === 'deal')?.fields ?? [];

    return fields
      ?.filter((field) => field.data_type === 'boolean' || !!field.options)
      .map((field) => ({
        label: field.display_name,
        value: field.id as number,
        options:
          field.data_type === 'boolean'
            ? [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ]
            : Object.values((field.options as Record<string, string>) ?? [])
                .map((option) => ({
                  label: option,
                  value: option,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        dataType: field.data_type as 'string' | 'boolean',
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  function setOperandOptions(fieldId: number) {
    const selectedField = opportunityFieldOptions.value.find(
      (field) => field.value === fieldId,
    );

    operandOptions.value = selectedField?.options ?? [];
  }

  function updateOperandsLookup(
    businessSetting: BusinessSettingDefinition,
    type: 'newBusiness' | 'existingBusiness',
  ) {
    const filterDefinition = businessSetting.source_filter_expressions;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [_, filterVals] = Object.entries(filterDefinition)[0]!;
    const filter = filterVals.filter_parts[0];

    if (!filter) return;

    /* use string for boolean values to play well with select component */
    const operand =
      typeof filter.operand[0] === 'boolean'
        ? (filter.operand[0]?.toString() as 'true' | 'false')
        : filter.operand;

    selectedOperandsLookup.value[type] = operand;

    /* same source_field_id on both settings, just grab the first */
    if (type === 'newBusiness') {
      selectedOpportunityFieldId.value = filter.source_field_id ?? null;
    }
  }

  onBeforeMount(async () => {
    loading.value = true;
    await sourcesStore.readySync;
    if (!businessSettings.value.length) {
      await fetchOpportunityFilters();
    }
    try {
      const [newBusinessSetting, existingBusinessSetting] = [
        businessSettings.value.find((setting) =>
          setting.name.startsWith('net-new'),
        ),
        businessSettings.value.find((setting) =>
          setting.name.startsWith('existing'),
        ),
      ];

      if (!newBusinessSetting || !existingBusinessSetting) {
        selectedFeedId.value = preferredCRMFeed.value?.id || null;
        return;
      }

      selectedFeedId.value =
        sourcesStore.getSourceById(newBusinessSetting.source_id)?.feed_id ??
        preferredCRMFeed.value?.id ??
        null;

      if (!selectedFeedId.value) return;

      updateOperandsLookup(
        newBusinessSetting as BusinessSettingDefinition,
        'newBusiness',
      );
      updateOperandsLookup(
        existingBusinessSetting as BusinessSettingDefinition,
        'existingBusiness',
      );
    } catch (error) {
      captureException(error);
      resetAll();
    } finally {
      loading.value = false;
    }
  });
  return {
    loading,
    selectedFeedId,
    selectedOpportunityFieldId,
    selectedOperandsLookup,
    feedOptions,
    opportunityFieldOptions,
    newBusinessOptions,
    operandOptions,
    recurringBusinessOptions,
    isValidSettings,
    isMultiSelect,
    isDataSourceInputDisabled,
    dealSourceFromSelectedFeed,
    buildPayload,
    fetchFilterableSources,
    resetAll,
    resetOperands,
    saveSettings,
    setOperandOptions,
  };
}
