<template>
  <BittsLoading :is-loading="loading" class="view-all-notifications">
    <BittsLayout :is-page="true">
      <template #content>
        <PageTitle
          title="Activity"
          subtitle="Take action on partner requests and stay informed on the latest opportunity activity"
        />
        <div
          v-if="notifications?.length"
          class="view-all-notifications__notifications"
        >
          <div
            class="flex justify-between pt-12 px-12 pb-6 border-b mb-2 border-neutral-border"
          >
            <span>Notifications</span>
            <BittsButton
              v-if="unreadNotifications?.length > 0"
              text="Mark All as Read"
              type="primary"
              variant="ghost"
              size="x-small"
              @click="handleMarkAllRead"
            />
          </div>
          <component
            :is="notificationComponent(item)"
            v-for="(item, index) in notifications"
            :key="`notification_${index}`"
            class="notification-component"
            :item="item"
            @action-clicked="sendIterativelyEvent($event, item)"
          />
        </div>
        <BittsEmptyState
          v-else
          :font-awesome-icon="['fak', 'notifications']"
          font-awesome-icon-class="view-all-notifications__empty-state-icon"
          :font-awesome-icon-style="{ height: '40px', width: '40px' }"
          title="You have no notifications"
          class="view-all-notifications__empty-state"
        >
          <template #subtitle>
            <div class="view-all-notifications__empty-state-description">
              Check here for updates on partnership requests, data share
              requests, list activity, and more
            </div>
          </template>
        </BittsEmptyState>
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsEmptyState,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

import PageTitle from '@/components/PageTitle.vue';

import useNotifications from '@/composables/useNotificationsPage';

useHead({ title: 'Notifications' });

const {
  handleMarkAllRead,
  loading,
  notifications,
  unreadNotifications,
  notificationComponent,
  sendIterativelyEvent,
} = useNotifications();
</script>

<style lang="pcss" scoped>
.notification-component:not(:last-of-type) {
  @apply border-b border-neutral-border w-full m-0;
}
.view-all-notifications__empty-state-description {
  @apply text-neutral-text text-base;
}
.view-all-notifications__header {
  @apply flex justify-between items-center pb-16 font-bold text-neutral-text-strong text-xl;
}
.view-all-notifications__notifications {
  @apply bg-white border border-solid border-neutral-border rounded-bts-md mb-24;
}
</style>

<style lang="pcss">
.view-all-notifications__empty-state {
  .c-bitts-empty-state-large {
    @apply px-0 py-24;
  }
  &.c-bitts-empty-state-large-border {
    @apply h-auto bg-white;
  }
}
.view-all-notifications__empty-state-icon {
  @apply text-neutral-text-weak p-24 rounded-[44px];
  background: radial-gradient(
      136.96% 50.92% at 50.09% -12.42%,
      theme(colors.neutral.accent) 0%,
      theme(colors.white / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
}
</style>
