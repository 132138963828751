import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { useConnectorTrialEndDate } from '@/composables/useConnectorTrialEndDate';
import {
  CORE_SEAT_COMPONENTS,
  SALES_SEAT_COMPONENTS,
  SEAT_REMOVAL,
} from '@/constants/billing';
import { ADMIN_PANEL, CORE } from '@/constants/team';
import { SALES } from '@/constants/team_v4';
import { useBillingStore } from '@/stores';
import { useTeamStore } from '@/stores/TeamStore';

import useHasFeature from './useHasFeature';

export default function useSeats() {
  const billingStore = useBillingStore();
  const teamStore = useTeamStore();

  const { currentAuth } = useAuth();
  const { salesSeats } = storeToRefs(teamStore);
  const {
    isFreeTier,
    isConnectorTier,
    isEnterpriseTier,
    billingInfo,
    chargifyData,
    hasSubscription,
    subscriptionType,
  } = storeToRefs(billingStore);

  const { isTrueConnector } = useConnectorTrialEndDate();

  const { canGoOverSeatQuota } = useHasFeature();

  const coreSubscriptionSeatCount = computed(
    () => coreSeatComponent.value?.allocated_quantity,
  );
  const coreSeatCount = computed(() => billingInfo.value.core?.used_seats);
  const salesEdgeSeatCount = computed(() => salesSeats.value.length);
  const isCoreSeatEarlyAdopter = computed(() => {
    if (isEnterpriseTier.value || isFreeTier.value) return false;
    return (
      coreSeatLimit.value > coreSubscriptionSeatCount.value ||
      coreSeatCount.value > coreSeatLimit.value
    );
  });
  const coreSeatComponent = computed(() =>
    chargifyData.value?.components.find((c) =>
      Object.values(CORE_SEAT_COMPONENTS).includes(c.component_handle),
    ),
  );
  const coreSeatPricePoints = computed(
    () => coreSeatComponent.value?.price_points || [],
  );
  const coreSeatLimit = computed(
    () => billingInfo.value.core?.seat_count ?? Infinity,
  );
  const coreSeatsRemaining = computed(() =>
    Math.max(coreSeatLimit.value - coreSeatCount.value, 0),
  );
  const coreSeatTotalCosts = computed(() =>
    aggregateCosts(coreSeatPricePoints.value),
  );
  const costPerCoreSeat = computed(
    () => coreSeatTotalCosts.value / coreSeatLimit.value,
  );
  const coreSeatsToReclaim = computed(() => {
    if (
      billingInfo.value.chargify_subscription?.update_summary?.update_type ===
      SEAT_REMOVAL
    ) {
      const coreComponent = CORE_SEAT_COMPONENTS[subscriptionType.value];
      return (
        billingInfo.value.chargify_subscription?.update_summary
          ?.unclaimed_seats?.[coreComponent] || 0
      );
    }
    return 0;
  });

  const salesSeatComponent = computed(() =>
    chargifyData.value?.components.find((c) =>
      Object.values(SALES_SEAT_COMPONENTS).includes(c.component_handle),
    ),
  );
  const salesSeatPricePoints = computed(
    () => salesSeatComponent.value?.price_points || [],
  );
  const salesSeatCount = computed(
    () => billingInfo.value.sales_seat?.used_seats || 0,
  );
  const salesSeatLimit = computed(
    () => billingInfo.value.sales_seat?.seat_count || 0,
  );
  const salesSeatsRemaining = computed(() =>
    Math.max(salesSeatLimit.value - salesSeatCount.value, 0),
  );
  const salesSeatTotalCosts = computed(() =>
    aggregateCosts(salesSeatPricePoints.value),
  );
  const costPerSalesSeat = computed(
    () => salesSeatTotalCosts.value / salesSeatLimit.value,
  );
  const salesSeatsToReclaim = computed(() => {
    if (
      billingInfo.value.chargify_subscription?.update_summary?.update_type ===
      SEAT_REMOVAL
    ) {
      const salesComponent = SALES_SEAT_COMPONENTS[subscriptionType.value];
      return (
        billingInfo.value.chargify_subscription?.update_summary
          ?.unclaimed_seats?.[salesComponent] || 0
      );
    }
    return 0;
  });

  const atCoreSeatLimit = computed(
    () => !coreSeatsRemaining.value && !canGoOverSeatQuota.value,
  );
  const atSalesSeatLimit = computed(
    () => !salesSeatsRemaining.value && !canGoOverSeatQuota.value,
  );
  const atSeatLimit = computed(
    () => atCoreSeatLimit.value && atSalesSeatLimit.value,
  );
  const overSeatLimit = computed(
    () =>
      coreSeatCount.value > coreSeatLimit.value ||
      salesSeatCount.value > salesSeatLimit.value,
  );
  const totalSeatLimit = computed(
    () => coreSeatLimit.value + salesSeatLimit.value,
  );
  const totalSeatCount = computed(
    () => coreSeatCount.value + salesSeatCount.value,
  );

  const hasUnlimitedSeats = computed(
    () => isEnterpriseTier.value && canGoOverSeatQuota.value,
  );
  const hasPaidSeatLimit = computed(
    () =>
      isConnectorTier.value ||
      (isEnterpriseTier.value && !canGoOverSeatQuota.value),
  );
  const mustTalkToSales = computed(
    () =>
      isEnterpriseTier.value ||
      (isTrueConnector.value && !hasSubscription.value),
  );
  const isAdmin = computed(
    () => currentAuth.value.authorizer_type === ADMIN_PANEL,
  );
  const remainingCoreSeats = computed(
    () => coreSeatsRemaining.value + (isAdmin.value ? 1 : 0),
  );
  const seatsRemainingMap = computed(() => ({
    [CORE]: remainingCoreSeats.value,
    [SALES]: salesSeatsRemaining.value,
  }));

  /* There will only ever be one price point so far no need to have separate costPerCoreSeat now per price point */
  function aggregateCosts(pricePoints) {
    return pricePoints
      .filter(
        (pricePoint) =>
          pricePoint.unit_price_cents > 0 && pricePoint.allocated_quantity > 0,
      )
      .map((pricePoint) => pricePoint.component_price_tier_total_cents)
      .reduce((sum, val) => sum + val, 0);
  }

  return {
    coreSeatCount,
    salesEdgeSeatCount,
    isCoreSeatEarlyAdopter,
    coreSeatPricePoints,
    coreSeatsToReclaim,
    salesSeatsToReclaim,
    coreSeatLimit,
    coreSeatsRemaining,
    seatsRemainingMap,
    salesSeatLimit,
    salesSeatCount,
    salesSeatsRemaining,
    salesSeatPricePoints,
    totalSeatLimit,
    totalSeatCount,
    atSeatLimit,
    atCoreSeatLimit,
    atSalesSeatLimit,
    overSeatLimit,
    costPerCoreSeat,
    coreSeatTotalCosts,
    salesSeatTotalCosts,
    costPerSalesSeat,
    hasPaidSeatLimit,
    mustTalkToSales,
    hasUnlimitedSeats,
  };
}
