<template>
  <BittsLayout class="data-sharing-page" :is-page="true">
    <template #content>
      <PageTitle
        title="Default Sharing"
        subtitle="These sharing settings apply to new partners."
        class="mb-16"
      />
      <SharingSettingsTab />
    </template>
    <template #populations-modal>
      <router-view-wrapper />
    </template>
  </BittsLayout>
</template>

<script setup lang="ts">
import { BittsLayout } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';

import PageTitle from '@/components/PageTitle.vue';
import { SharingSettingsTab } from '@/components/partners/DataSharingSettings/SettingsModal/SharingSettingsTab';

useHead({
  title: 'Default Sharing - Crossbeam',
});
</script>

<style scoped lang="pcss"></style>
