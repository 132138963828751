<template>
  <div class="c-partnerships-settings">
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="title"
      :description="description"
      variant="sidebar"
      :loading="loading"
      @closed="close"
      @action="handleAction"
      :use-mask-to-close="true"
      :disabled="saving || loading || !hasChanges"
      :action-loading="saving"
      :show-primary-button="!hideSaveButton"
      :secondary-button-text="hideSaveButton ? 'Close' : undefined"
      :hide-footer="!hasManageVisibilityPermission"
      :no-padding="true"
    >
      <template #sidebar-header-prefix>
        <PartnerIcon :partner-uuid="partner?.uuid" class="mr-12" />
      </template>
      <template #sidebar-footer>
        <BittsButton
          v-if="hasManagePartnerInvitePermission"
          :disabled="saving"
          type="danger"
          text="Delete partnership"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <ModalSidebarLink
          route-name="partnership-settings"
          v-for="(item, i) in sidebarItems"
          :item="item"
          :key="item.tab"
          default-tab="team-access"
          :class="{
            'mt-auto': i === sidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-partnerships-settings__content">
        <component :is="TabComponent" class="min-h-[600px]" v-bind="tabProps" />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ModalSidebarLink, {
  ModalSidebarItem,
} from '@/components/ModalSidebarLink.vue';
import SharingSettingsTab from '@/components/partners/DataSharingSettings/SettingsModal/SharingSettingsTab/components/SharingSettingsTab.vue';
import TeamAccessTab from '@/components/partners/DataSharingSettings/SettingsModal/TeamAccessTab.vue';
import PartnerIcon from '@/components/partners/PartnerIcon.vue';

import useAuth from '@/composables/useAuth';
import { usePartnershipTeamAccess } from '@/composables/usePartnershipTeamAccess';
import {
  TEMP_INVITE_WITH_SHARING,
  TEMP_PARTNER_VISIBILITY,
} from '@/constants/feature_flags';
import { useFeatureFlagStore, usePartnersStore } from '@/stores';

import { PartnershipSettingsTabType } from './constants';

const router = useRouter();
const route = useRoute();
const partnersStore = usePartnersStore();
const featureFlagStore = useFeatureFlagStore();

const { hasPermission } = useAuth();
const hasManageVisibilityPermission = computed(() =>
  hasPermission('manage:partner-visibility'),
);
const hasManagePartnerInvitePermission = computed(() =>
  hasPermission('manage:partnerships'),
);

const isPartnerVisibilityEnabled = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_PARTNER_VISIBILITY),
);
const isInviteWithSharingEnabled = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_INVITE_WITH_SHARING),
);

const tab = computed(() => {
  return isInviteWithSharingEnabled.value
    ? (route.params.tab as PartnershipSettingsTabType)
    : (route.query.tab as PartnershipSettingsTabType);
});
const hideSaveButton = computed(() => tab.value === 'sharing-settings');
const partnerId = computed(() => Number(route.params.partner_org_id as string));
const partner = computed(
  () => partnersStore.getPartnerOrgById(partnerId.value, false) || null,
);

const {
  close,
  handleSave: handleSaveTeamAccess,
  hasChanges,
} = usePartnershipTeamAccess(partner.value?.uuid);

const loading = ref(false);
const saving = ref(false);

const sidebarItems = computed(
  () =>
    [
      isInviteWithSharingEnabled.value && {
        label: 'Sharing Settings',
        icon: ['far', 'circles-overlap'],
        tab: PartnershipSettingsTabType.SHARING_SETTINGS,
      },
      isPartnerVisibilityEnabled.value && {
        label: 'Team Access',
        icon: ['fad', 'user-group'],
        tab: PartnershipSettingsTabType.TEAM_ACCESS,
      },
    ].filter(Boolean) as ModalSidebarItem<PartnershipSettingsTabType>[],
);

const TabComponent = computed(() => {
  switch (tab.value) {
    case 'sharing-settings':
      return SharingSettingsTab;
    case 'team-access':
      return TeamAccessTab;
    default:
      return isInviteWithSharingEnabled.value
        ? SharingSettingsTab
        : TeamAccessTab;
  }
});

const tabProps = computed(() => {
  switch (tab.value) {
    case 'sharing-settings':
      return { partnerId: partnerId.value };
    case 'team-access':
    default:
      return { partnerUuid: partner.value?.uuid };
  }
});

const title = computed(() => {
  switch (tab.value) {
    case 'sharing-settings':
      return `Sharing data with ${partner.value?.name}`;
    case 'team-access':
    default:
      return `${hasManageVisibilityPermission.value ? 'Manage' : 'View'} Access`;
  }
});

const description = computed(() => {
  switch (tab.value) {
    case 'sharing-settings':
      return '';
    case 'team-access':
    default:
      return `${hasManageVisibilityPermission.value ? 'Edit' : 'View'} roles and people who have access to ${partner.value?.name} in Crossbeam`;
  }
});

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'team-access':
      await handleSaveTeamAccess();
      break;
    default:
      console.log('handleAction');
  }
}

async function showDeleteModal() {
  const cancelDestination = route.path;
  await router.push({ name: 'delete_partner', query: { cancelDestination } });
}
</script>

<style lang="pcss" scoped>
.c-partnerships-settings {
  @apply py-24 px-0;
}

.c-partnerships-settings__content {
  @apply flex flex-col;
}
</style>
