import { ls } from './local_storage';
import { LOGIN_POPUP_PARAM, MINIMAL_VIEW_PARAM } from './utils';

/**
 * Used to set minimalView in local storage which is used to strip out functionality
 * mainly for iframe'ing the app in third party sites
 */
export default function initMinimalView() {
  const { search, pathname, origin } = window.location;
  const query = new URLSearchParams(search);
  const isInIframe = window.top !== window.self;

  const isMinimal = query.has(MINIMAL_VIEW_PARAM) && isInIframe;
  const hasLoginPopup = query.has(LOGIN_POPUP_PARAM);

  if (isMinimal && !hasLoginPopup) {
    localStorage.setItem('navbarCollapsed', 'true');
    sessionStorage.setItem(MINIMAL_VIEW_PARAM, 'true');
    query.delete(MINIMAL_VIEW_PARAM);
  }

  const orgId = query.get('organization_id');

  if (orgId) {
    ls.orgId.set(orgId);
  }

  // backend appends /switch-org for some flows and it uses organization_id
  if (!pathname.startsWith('/switch-org')) query.delete('organization_id');

  const updatedQueryString = query.toString();
  const newUrl = `${origin}${pathname}${updatedQueryString ? `?${updatedQueryString}` : ''}`;

  window.history.replaceState({}, document.title, newUrl);
}
