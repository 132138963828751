<template>
  <IntegrationSettingsOverlapEstimates
    v-if="settingType !== 'crossbeam_copilot'"
    :populations="populationSettings?.population_settings"
    :standard-populations="populationSettings?.standard_population_settings"
  />
  <IntegrationSettingsOwnPopulations
    class="mt-16"
    :integration-profile-settings="populationSettings"
    :is-disabled
    :setting-type="settingType"
    @update:settings="onSettingsUpdatedMine"
  />
  <IntegrationSettingsPartnerSpecificPopulations
    class="mt-16"
    :integration-profile-settings="populationSettings"
    :is-disabled
    :setting-type="settingType"
    @update:partner-default-settings="onPartnerStandardSettingsUpdated"
    @update:partner-overrides="onPartnerSpecificSettingsUpdated"
    @update:exclude-partnerships-created-since="
      onUpdateExcludePartnershipsCreatedSince
    "
  />
</template>

<script setup lang="ts">
import IntegrationSettingsOverlapEstimates from '@/components/integrations/IntegrationSettingsOverlapEstimates.vue';

import {
  IntegrationProfileType,
  MinimalIntegrationProfile,
} from '@/types/integration_profiles';

import IntegrationSettingsOwnPopulations from './IntegrationSettingsOwnPopulations.vue';
import IntegrationSettingsPartnerSpecificPopulations from './IntegrationSettingsPartnerSpecificPopulations.vue';

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean;
    populationSettings?: MinimalIntegrationProfile | null;
    settingType: IntegrationProfileType;
  }>(),
  { populationSettings: undefined, isDisabled: false },
);

const emit = defineEmits<{
  (e: 'save:changes', payload: MinimalIntegrationProfile): void;
  (e: 'update:exclude-partnerships-created-since', isChecked: boolean): void;
}>();

function onSettingsUpdatedMine(payload: MinimalIntegrationProfile) {
  emit('save:changes', {
    ...props.populationSettings,
    population_settings: [
      ...payload.population_settings,
      ...(props.populationSettings?.population_settings.filter(
        (pop) => pop.is_partner_population,
      ) ?? []),
    ],
    standard_population_settings:
      props.populationSettings?.standard_population_settings ?? [],
  });
}
function onPartnerStandardSettingsUpdated(payload: MinimalIntegrationProfile) {
  emit('save:changes', {
    ...props.populationSettings,
    population_settings: props.populationSettings?.population_settings ?? [],
    standard_population_settings: payload.standard_population_settings,
  });
}
function onPartnerSpecificSettingsUpdated(payload: MinimalIntegrationProfile) {
  emit('save:changes', {
    ...props.populationSettings,
    population_settings: payload.population_settings,
    standard_population_settings:
      props.populationSettings?.standard_population_settings ?? [],
  });
}
function onUpdateExcludePartnershipsCreatedSince(isChecked: boolean) {
  emit('update:exclude-partnerships-created-since', isChecked);
}
</script>
