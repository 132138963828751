export default {
  env: 'prod',
  apiBaseUrl: 'https://api.crossbeam.com',
  salesEdgeBaseUrl: 'https://sales-backend-api.crossbeam.com',
  salesEdgeRedirectUrl: 'https://sales.crossbeam.com',
  salesforceReauthUrl: 'lightning/n/xbeamprod__Setup',
  host: 'app.crossbeam.com',
  auth0: {
    domain: 'auth.crossbeam.com',
    clientID: 'T8XLE1YbNDeGKSw9WrCYpdjLiB2dwZV4',
    audience: 'https://api.getcrossbeam.com',
  },
  statuspageBaseUrl: 'https://status.crossbeam.com',
  statuspagePageId: 'ngfln6n04fyr',
  statusPageEmbedUrl: 'https://ngfln6n04fyr.statuspage.io/embed/script.js',
  datadog: {
    applicationId: '1e70f535-076b-454c-88c6-c01d1eb495a9',
    clientToken: 'pub881a1c58f3a08260853d80d2a704b75e',
    site: 'datadoghq.com',
    service: 'crossbeam-web',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ['https://api.crossbeam.com'],
    enableExperimentalFeatures: ['clickmap'],
    allowFallbackToLocalStorage: true,
  },
  enableFullstory: true,
  enableGoogleAnalytics: true,
  enableGoogleTags: true,
  intercom: {
    enabled: true,
    workspaceId: 'ugh1893t',
  },
  userLeap: {
    enabled: true,
    appId: 'v3tI1wOKH0t',
  },
  iteratively: {
    environment: 'production',
  },
  hubSpotForms: {
    contactSalesForm: '28accbe8-9751-4799-aef3-db69d5854f10',
    envID: '4716094',
  },
  chargify: {
    publicKey: 'chjs_dnb659f7tv76ngmkdzqqszjc',
    serverHost: 'https://crossbeam.chargify.com',
  },
} as const;
