<template>
  <div class="c-new-and-recurring-business-settings">
    <BittsLoading
      :is-loading="loading"
      loading-container-class="h-240 w-300"
      :with-transition="false"
    >
      <h3 class="text-neutral-text-strong font-bold mb-4">
        Opportunity Type Field
      </h3>
      <h4 class="text-sm text-neutral-text mb-24">
        Define what field in your CRM captures whether the opportunity is new or
        recurring business
      </h4>
      <BittsSelect
        v-model="selectedFeedId"
        :options="feedOptions"
        class="w-260 mb-24"
        :searchable="false"
        form-label="Select your CRM"
        placeholder="Select data source"
        option-type="svg"
        :disabled="isDataSourceInputDisabled"
        data-testid="data-source-select"
      />
      <BittsSelect
        v-if="selectedFeedId && opportunityFieldOptions.length"
        v-model="selectedOpportunityFieldId"
        :options="opportunityFieldOptions"
        class="w-260 mb-24"
        placeholder="Select opportunity field"
        data-testid="opportunity-field-select"
      />
      <div class="filter-groups" v-if="operandOptions.length">
        <BusinessSettingsFilterGroup
          v-model="selectedOperandsLookup.newBusiness"
          title="New Business"
          :operand-options="newBusinessOptions"
          :is-multi-select
          class="mb-16"
        />
        <BusinessSettingsFilterGroup
          v-model="selectedOperandsLookup.existingBusiness"
          title="Existing Business"
          :operand-options="recurringBusinessOptions"
          :is-multi-select
        />
      </div>
    </BittsLoading>

    <BittsButton
      text="Save changes"
      size="small"
      data-testid="save-settings-button"
      @click="saveSettings"
      :disabled="!isValidSettings"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsLoading, BittsSelect } from '@crossbeam/bitts';

import { watch } from 'vue';

import useBusinessSettings from '@/composables/useBusinessSettings';

import BusinessSettingsFilterGroup from './BusinessSettingsFilterGroup.vue';

const {
  selectedFeedId,
  selectedOperandsLookup,
  selectedOpportunityFieldId,
  opportunityFieldOptions,
  dealSourceFromSelectedFeed,
  feedOptions,
  operandOptions,
  newBusinessOptions,
  recurringBusinessOptions,
  isValidSettings,
  isDataSourceInputDisabled,
  isMultiSelect,
  loading,
  resetAll,
  resetOperands,
  fetchFilterableSources,
  setOperandOptions,
  buildPayload, // just for exposing to tests
  saveSettings,
} = useBusinessSettings();

/* root input - if this is reset we should hydrate everything */
watch(
  [selectedFeedId, selectedOpportunityFieldId],
  async ([newFeedId, newFieldId], [oldFeedId, oldFieldId]) => {
    // When the feed id changes, update opportunityFieldOptions
    if (newFeedId !== oldFeedId && dealSourceFromSelectedFeed.value?.id) {
      if (oldFeedId) resetAll();
      opportunityFieldOptions.value = await fetchFilterableSources(
        dealSourceFromSelectedFeed.value.id,
      );
    }

    // Only proceed if newFieldId is set
    if (newFieldId && opportunityFieldOptions.value) {
      if (newFieldId !== oldFieldId && oldFieldId !== null) {
        resetOperands();
      }
      setOperandOptions(newFieldId);
    }
  },
);

defineExpose({
  buildPayload,
});
</script>

<style scoped lang="pcss">
.filter-groups {
  @apply border-l-0 md:border-l border-neutral-border pl-0 md:pl-24 mb-24;
}
</style>
