import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { initStore } from '@/stores/store-utils';
import { DataSource } from '@/types/sources';

export const useDataSourcesStore = defineStore('DataSources', () => {
  const dataSources = ref<DataSource[]>([]);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data, error } = await crossbeamApi.GET('/v0.1/integrations');
      if (error) throw error;
      dataSources.value = data.items || [];
    },
  );

  refresh({ useCache: true });

  function getDataSource(type: string) {
    return dataSources.value.find((opt) => opt.type === type);
  }

  const supportedDataSources = computed(() =>
    dataSources.value.filter((ds) => {
      if (!ds.is_supported) return false;
      return true;
    }),
  );

  const unsupportedDataSources = computed(() =>
    dataSources.value.filter((ds) => !ds.is_supported),
  );

  const hasMultipleDataSources = computed(() => dataSources.value.length === 1);
  const getOneDataSource = computed(() =>
    dataSources.value.length ? dataSources.value[0] : [],
  );

  return {
    error: initError,
    ready,
    readySync,
    running,
    refreshDataSourcesStore: refresh,
    dataSources,
    getDataSource,
    supportedDataSources,
    unsupportedDataSources,
    hasMultipleDataSources,
    getOneDataSource,
  };
});
