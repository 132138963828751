import axios from 'axios';
import { each, find, orderBy } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { FILE_UPLOAD_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import { captureException } from '@/errors';
import { initStore } from '@/stores/store-utils';
import {
  PartnerPopulation,
  Population,
  PopulationStats,
  StandardPopulationType,
} from '@/types/populations';
import urls from '@/urls';

export const usePopulationsStore = defineStore('PopulationsTwo', () => {
  const populations = ref<Population[]>([]);
  const partnerPopulations = ref<PartnerPopulation[]>([]);
  const inactivePopulations = ref<Population[]>([]);
  const populationStats = ref<PopulationStats[]>([]);
  const uniqueRecordCount = ref<number>(0);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      try {
        const [
          populationResponse,
          inactivePopsResponse,
          populationStatsResponse,
          partnerPopResponse,
          recordStatsResponse,
        ] = await Promise.all([
          axios.get(urls.populations.all_v3({ onlyInactive: false })),
          axios.get(urls.populations.all_v3({ onlyInactive: true })),
          axios.get(urls.population_stats.all),
          axios.get(urls.populations.allPartnerPops),
          axios.get(urls.recordStats),
        ]);
        populations.value = sortAndAddMetadata({
          populations: populationResponse.data.items,
          populationStats: populationStatsResponse.data.items,
        });
        inactivePopulations.value = sortAndAddMetadata({
          populations: inactivePopsResponse.data.items,
          populationStats: populationStatsResponse.data.items,
        });
        populationStats.value = populationStatsResponse.data.items;
        partnerPopulations.value = partnerPopResponse.data.items;
        uniqueRecordCount.value = recordStatsResponse.data.unique_record_count;
      } catch (xhr) {
        captureException(xhr);
      }
    },
  );

  refresh({ useCache: true });

  const customPopulations = computed(() => {
    return populations.value.filter((pop) => {
      if (!pop.standard_type) return true;
      return !STANDARD_POPULATION_TYPES.includes(pop.standard_type);
    });
  });

  const standardPopulations = computed(() => {
    return populations.value.filter((pop) => {
      return (
        pop.standard_type &&
        STANDARD_POPULATION_TYPES.includes(pop.standard_type)
      );
    });
  });

  const missingStandardPopulations = computed(() =>
    STANDARD_POPULATION_TYPES.filter(
      (type) =>
        !standardPopulations.value.some((pop) => pop.standard_type === type),
    ),
  );

  const populationIds = computed(() => populations.value.map((pop) => pop.id));

  const fileUploadPopulations = computed(() =>
    populations.value.filter((pop) =>
      pop.base_schema.includes(FILE_UPLOAD_DATA_SOURCE_TYPE),
    ),
  );

  function getPopulationById(id: number) {
    return populations.value.find((pop) => pop.id === id);
  }

  function getPopulationByName(name: string) {
    return populations.value.find(
      (pop) => pop.name?.toLowerCase() === name?.toLowerCase(),
    );
  }

  function getPartnerPopulationById(id: number) {
    return partnerPopulations.value.find((pop) => pop.id === id);
  }

  function getPartnerPopulationsByOrg(partnerOrgId: number) {
    return partnerPopulations.value.filter(
      (pop) => pop.organization_id === partnerOrgId,
    );
  }

  function getPopulationByStandardType(type: StandardPopulationType) {
    return populations.value.find((pop) => pop.standard_type === type);
  }

  function getPartnerPopulationsByStandardType(type: StandardPopulationType) {
    return partnerPopulations.value.filter(
      (population) => population.standard_type === type,
    );
  }

  // Selectively refresh just partner populations
  async function refreshPartnerPopulations() {
    try {
      const partnerPopResponse = await axios.get(
        urls.populations.allPartnerPops,
      );
      partnerPopulations.value = partnerPopResponse.data.items;
    } catch (xhr) {
      captureException(xhr);
    }
  }

  function upsertPopulation(population: Population) {
    populations.value = populations.value
      .filter((s) => s.id !== population.id)
      .concat(population);
  }

  function removePopulation(id: number) {
    populations.value = populations.value.filter((s) => s.id !== id);
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    refreshPopulationsStore: refresh,
    refreshPartnerPopulations,
    populations,
    customPopulations,
    standardPopulations,
    uniqueRecordCount,
    partnerPopulations,
    inactivePopulations,
    populationStats,
    populationIds,
    fileUploadPopulations,
    getPopulationById,
    getPopulationByName,
    getPartnerPopulationById,
    getPartnerPopulationsByOrg,
    getPopulationByStandardType,
    getPartnerPopulationsByStandardType,
    missingStandardPopulations,
    upsertPopulation,
    removePopulation,
  };
});

export function sortAndAddMetadata({
  populations,
  populationStats,
}: {
  populations: Population[];
  populationStats: PopulationStats[];
}) {
  return orderBy(
    each(populations, (pop) => {
      pop.meta =
        find(populationStats, (popStats) => {
          return pop.id === popStats.population_id;
        }) || {};
    }),
    ['name'],
    ['asc'],
  );
}
