<template>
  <BittsModalTwo
    :width="900"
    :loading="!ready"
    :action-loading="!ready"
    :use-mask-to-close="true"
    class="c-field-preset-modal"
    @closed="closeModal"
    @action="handleAction"
    :disabled="!ready"
    :show-primary-button="!isDisabled"
    :primary-button-text="presetId ? 'Save' : 'Create'"
    :secondary-button-text="isDisabled ? 'Close' : 'Cancel'"
  >
    <template #header>
      <div class="c-field-preset-modal__header">
        <h3 class="text-neutral-text-strong font-bold text-lg w-full pt-0 mb-8">
          {{ title }}
        </h3>
        <BittsTag
          v-if="!!ready"
          :text="`${currentSourceFieldIds.length} ${pluralize(currentSourceFieldIds.length, 'field')}`"
          color="secondary"
        />
      </div>
    </template>

    <BittsLoading :is-loading="!ready">
      <div class="flex flex-col gap-20">
        <div v-if="!isSingleShare" class="flex flex-col gap-8">
          Name of preset
          <BittsInput
            v-model="presetName"
            type="text"
            placeholder="Preset name"
            name="preset-name"
            size="small"
            :disabled="isDisabled"
          />
        </div>
        <div class="flex flex-col gap-12">
          <div class="flex justify-between">
            Fields in this preset
            <BittsButton
              v-if="!hasDefaultPresets && !isDisabled"
              text="Reset to Crossbeam default"
              :left-icon="['far', 'arrows-spin']"
              size="x-small"
              variant="ghost"
              type="neutral"
              @click="resetToDefault"
            />
          </div>
          <BittsContainer>
            <div
              v-for="(
                { mdm_type, ids }, tableName, index
              ) in sourceFieldIdsByMdm"
              :key="mdm_type"
            >
              <div class="flex justify-between items-start">
                <div class="font-bold">{{ tableName }}</div>
                <BittsMultiselect
                  v-model="ids.value"
                  :options="getFieldOptions(mdm_type)"
                  mode="multiple"
                  size="large"
                  class="w-[400px]"
                  :disabled="isDisabled"
                />
              </div>
              <BittsDivider
                v-if="index + 1 !== Object.keys(sourceFieldIdsByMdm).length"
                class="my-20"
              />
            </div>
          </BittsContainer>
        </div>

        <BittsContainer v-if="isSingleShare">
          <div class="flex items-center justify-between gap-8 font-bold">
            Saved as new preset
            <BittsSwitch v-model="isToggledNewPreset" />
          </div>
          <div v-if="isToggledNewPreset">
            <BittsDivider class="my-20" />
            <BittsInput
              v-model="presetName"
              type="text"
              placeholder="Preset name"
              name="preset-name"
              size="small"
              class="max-w-[320px]"
            />
          </div>
        </BittsContainer>
      </div>
    </BittsLoading>
    <template #custom-button v-if="presetId && !isDisabled">
      <BittsButton
        text="Delete preset"
        type="danger"
        variant="ghost"
        :left-icon="['far', 'trash']"
        @click="deletePreset(presetId, presetName)"
        :disabled="!ready"
        class="mr-auto"
      />
    </template>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsContainer,
  BittsDivider,
  BittsInput,
  BittsLoading,
  BittsModalTwo,
  BittsMultiselect,
  BittsSwitch,
  BittsTag,
} from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { useFieldPresets } from '@/composables/useFieldPresets';
import { useDataSharePresetsStore } from '@/stores';

type Props = {
  feedId: number;
  sourceId: number | undefined;
  cancelDestination: string;
  presetId?: number;
  isSingleShare?: boolean;
};

const { feedId, sourceId, cancelDestination, presetId, isSingleShare } =
  defineProps<Props>();

const { hasPermission } = useAuth();

const {
  presetName,
  isDefaultPreset,
  hasDefaultPresets,
  isToggledNewPreset,
  sourceFieldIdsByMdm,
  currentSourceFieldIds,
  closeModal,
  createPreset,
  editPreset,
  deletePreset,
  getFieldOptions,
  resetToDefault,
} = useFieldPresets({
  feedId,
  sourceId,
  presetId,
  cancelDestination,
  isSingleShare,
});

const { ready } = storeToRefs(useDataSharePresetsStore());

const hasSharingPermission = computed(() => hasPermission('manage:sharing'));

const isDisabled = computed(
  () => isDefaultPreset.value || !hasSharingPermission.value,
);

const title = computed(() => {
  if (isSingleShare) return 'Customize field selection';
  const prefix = isDefaultPreset.value
    ? 'See'
    : presetId
      ? 'Edit'
      : 'Create new';
  return `${prefix} field preset`;
});

function handleAction() {
  presetId
    ? editPreset(presetId, presetName.value, currentSourceFieldIds.value)
    : createPreset(presetName.value, currentSourceFieldIds.value);
}
</script>

<style lang="pcss">
.c-field-preset-modal.o-bitts-modal.bitts-modal--primary {
  .o-bitts-modal__header {
    @apply flex-row;
  }
  .c-field-preset-modal__header {
    @apply flex gap-12;
  }
}
</style>
