<template>
  <BittsLayout :is-page="true" class="sharing-dashboard">
    <template #content>
      <BittsLoading :is-loading>
        <div>
          <div class="flex justify-between items-center">
            <PageTitle
              title="Sharing Dashboard"
              subtitle="View and manage your sharing settings across all your partners"
            />
            <PermissionIndicator :resource="resource" />
          </div>
          <SharingDashboardPopulationsContainer
            :pops-with-metadata
            :has-manage-pops
          />
          <EmptyStatePartner v-if="showEmptyStatePartner" />
          <SharingDashboardSettingsOverview
            v-else
            :enriched-partners="enrichedPartners"
            :pops-with-metadata
            :has-manage-sharing
          />
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>
<script setup>
import { BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, provide, ref } from 'vue';

import EmptyStatePartner from '@/components/EmptyStatePartner.vue';
import PageTitle from '@/components/PageTitle.vue';
import SharingDashboardPopulationsContainer from '@/components/partners/SharingSettingsDashboard/SharingDashboardPopulationsContainer.vue';
import SharingDashboardSettingsOverview from '@/components/partners/SharingSettingsDashboard/SharingDashboardSettingsOverview.vue';
import PermissionIndicator from '@/components/PermissionIndicator.vue';

import useAuth from '@/composables/useAuth';
import { useEmptyStatePartner } from '@/composables/useEmptyStatePartner';
import usePartnerSharing from '@/composables/usePartnerSharing';
import usePopulationShares from '@/composables/usePopulationShares';
import {
  useDataRequestsStore,
  useDataSharesStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const isLoading = ref(true);

const partnersStore = usePartnersStore();
const populationsStore = usePopulationsStore();
const dataRequestsStore = useDataRequestsStore();
const dataSharesStore = useDataSharesStore();
const sourcesStore = useSourcesStore();

const { populations } = storeToRefs(populationsStore);

const { hasPermission } = useAuth();
const { showEmptyStatePartner } = useEmptyStatePartner();

const hasManagePops = computed(() => hasPermission('manage:populations'));
const hasManageSharing = computed(() => hasPermission('manage:sharing'));

const { addMetadataToPopulation, getDefaultUsage, sourcesWithFields } =
  usePopulationShares();

provide('usePopulations', {
  addMetadataToPopulation,
  getDefaultUsage,
  sourcesWithFields,
});

const popsWithMetadata = computed(() =>
  sortBy(
    populations.value.map((pop) => addMetadataToPopulation(pop)),
    ['standard_type'],
  ),
);
const { partnerOrgsLookup } = storeToRefs(usePartnersStore());
const enrichedPartners = ref({});
function enrichPartners() {
  const partnerIds = sortBy(Object.values(partnerOrgsLookup.value), ['name']);
  enrichedPartners.value = partnerIds.reduce((partners, partner) => {
    const { partnerPopulationsWithMetadata } = usePartnerSharing(partner.id);
    return partners.concat({
      ...partner,
      ownPops: popsWithMetadata.value.map((pop) =>
        addMetadataToPopulation(pop, partner.id),
      ),
      partnerPops: partnerPopulationsWithMetadata.value,
    });
  }, []);
}

const resource = computed(() =>
  !hasManagePops.value ? 'populations' : 'sharing',
);

// TODO: Do we really need to do this? Should be reactive when the stores load, unless there is a bug
// in the enrichPartners function where we assume data exists.
onMounted(async () => {
  await Promise.all([
    partnersStore.readySync,
    dataSharesStore.readySync,
    sourcesStore.readySync,
    dataRequestsStore.readySync,
  ]);
  enrichPartners();
  isLoading.value = false;
});
</script>
