import { CTA_2, EVENT_SITES, PLAN_AND_BILLING_CTAS } from '@crossbeam/itly';

import axios from 'axios';
import { pluralize } from 'humanize-plus';
import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import appConfig from '@/config';
import {
  BILLING_PRODUCTS,
  CORE_SEAT_COMPONENTS,
  SALES_SEAT_COMPONENTS,
  normalizeBillingPeriod,
} from '@/constants/billing';
import { CORE } from '@/constants/team';
import { captureException } from '@/errors';
import { clearBillingToken, getOrCreateBillingToken } from '@/local_storage';
import {
  allReady,
  useBillingStore,
  useFlashesStore,
  useTeamStore,
} from '@/stores';
import urls from '@/urls';
import { ensureDefaultsExist, hubSpotFormApi } from '@/utils';

import useSeats from './useSeats';

export default function useBilling() {
  const route = useRoute();
  const router = useRouter();
  const flashesStore = useFlashesStore();
  const teamStore = useTeamStore();
  const billingStore = useBillingStore();
  const {
    isFreeTier,
    isConnectorTier,
    isOnFreeTrial,
    isEnterpriseTier,
    hasSubscription,
  } = storeToRefs(billingStore);

  const { mustTalkToSales } = useSeats();
  const { iteratively } = useIteratively();
  const { currentUser, currentOrg } = useAuth();
  const { coreSeatLimit, salesSeatLimit } = useSeats();
  const allStoresReady = allReady(billingStore, teamStore);
  const openTalkToSalesModal = inject('openTalkToSalesModal');
  const openComparisonPlanModal = inject('openComparisonPlanModal');

  async function previewConnector({ period, seats, salesSeats }) {
    const billingPeriod = normalizeBillingPeriod(period);
    const url = urls.billing.selfServe.preview(BILLING_PRODUCTS[billingPeriod]);
    const payload = {
      components: [
        {
          component_handle: CORE_SEAT_COMPONENTS[billingPeriod],
          allocated_quantity: seats,
        },
      ],
    };

    if (salesSeats) {
      payload.components.push({
        component_handle: SALES_SEAT_COMPONENTS[billingPeriod],
        allocated_quantity: salesSeats,
      });
    }

    const { data } = await axios.post(url, payload);
    return data;
  }

  async function previewComponents(components, reduceSeats = false) {
    const url = reduceSeats
      ? urls.billing.selfServe.previewReduce
      : urls.billing.selfServe.componentPreview;
    const { data } = await axios.post(url, { components });

    return {
      currentSubscriptionCost: data.current_subscription_cost_in_cents,
      nextSubscriptionCost: data.next_subscription_cost_in_cents,
      subtotal: data.subtotal_in_cents,
      discount_in_cents: data.discount_in_cents,
      proratedFee: data.prorated_charge_in_cents,
      amountDue: data.amount_due_in_cents,
      components: data.components,
    };
  }

  async function fetchSeatDataV4({ period, newCoreSeats, newSalesSeats }) {
    const coreHandle = CORE_SEAT_COMPONENTS[period];
    const salesHandle = SALES_SEAT_COMPONENTS[period];

    /* This endpoint is not very smart, it can only handle the total number of seats, and it fails
     * if the number of seats you send it are the same as the current allocation. So we have to only
     * send it the total number of seats for components whose total exceeds the previous allocation */
    const components = [];
    if (newCoreSeats + coreSeatLimit.value > coreSeatLimit.value)
      components.push({
        quantity: newCoreSeats + coreSeatLimit.value,
        component_handle: coreHandle,
      });
    if (newSalesSeats + salesSeatLimit.value > salesSeatLimit.value)
      components.push({
        quantity: newSalesSeats + salesSeatLimit.value,
        component_handle: salesHandle,
      });

    return await previewComponents(components);
  }

  function processCostDataV4({
    costData,
    newCoreSeats,
    newSalesSeats,
    period,
  }) {
    const coreHandle = CORE_SEAT_COMPONENTS[period];
    const salesHandle = SALES_SEAT_COMPONENTS[period];

    /* Bulk discounts have been removed in v4, there is only ever one price point */
    const corePricePoint = costData.components
      .find(
        (component) =>
          component.handle === coreHandle ||
          component.component_handle === coreHandle,
      )
      .price_points.at(0);
    const salesPricePoint = costData.components
      .find(
        (component) =>
          component.handle === salesHandle ||
          component.component_handle === salesHandle,
      )
      ?.price_points.at(0);

    const coreSeatsTotalCost = corePricePoint.unit_price_cents * newCoreSeats;
    const costPerSalesSeat = salesPricePoint
      ? salesPricePoint.unit_price_cents
      : 0;
    const salesSeatsTotalCost = salesPricePoint
      ? costPerSalesSeat * newSalesSeats
      : 0;

    return {
      nextSubscriptionCost: costData.nextSubscriptionCost,
      proratedFee: costData.proratedFee,
      amountDueToday: costData.amountDue || costData.total_in_cents,
      centsSaved: costData.centsSaved,
      costPerCoreSeat: corePricePoint.unit_price_cents,
      coreSeatsTotalCost,
      costPerSalesSeat,
      salesSeatsTotalCost,
    };
  }

  async function purchaseComponents(components, newCount) {
    const PURCHASE_COMPONENTS_KEY = 'purchaseComponents';
    let cacheEntry = getOrCreateBillingToken(PURCHASE_COMPONENTS_KEY, {
      components,
    });

    // Make sure we use the old key when facing an error so we cannot duplicate seat purchases
    if (!isEqual(components, cacheEntry.components)) {
      clearBillingToken(PURCHASE_COMPONENTS_KEY);
      cacheEntry = getOrCreateBillingToken(PURCHASE_COMPONENTS_KEY, {
        components,
      });
    }
    try {
      const { data } = await axios.post(
        urls.billing.selfServe.componentPurchase,
        {
          components,
          uniqueness_token: cacheEntry.token,
        },
      );
      if (data?.error) {
        flashesStore.addErrorFlash({ message: 'Your payment failed' });
      } else {
        flashesStore.addSuccessFlash({
          message: `Congrats! You have ${newCount} new ${pluralize(newCount, 'seat')}`,
        });
      }
      clearBillingToken(PURCHASE_COMPONENTS_KEY);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Something went wrong',
        description: 'Payment for the new seats failed',
      });
    } finally {
      syncAndReroute();
    }
  }

  function syncAndReroute() {
    teamStore.refreshTeamStore();
    billingStore.refreshBillingStore({ syncChargify: true });
    router.push({ name: 'team' });
  }

  async function buyConnector({
    token,
    period,
    seats: coreSeats,
    salesSeats,
    firstName,
    lastName,
    email,
    organization,
    routeOnSuccess,
    vatNumber,
  }) {
    let paymentSucceeded = false;
    try {
      const billingPeriod = normalizeBillingPeriod(period);
      const url = urls.billing.selfServe.subscribe(
        BILLING_PRODUCTS[billingPeriod],
      );
      const payload = {
        chargify_token: token,
        customer_attributes: {
          first_name: firstName,
          last_name: lastName,
          email,
          organization,
          vat_number: vatNumber,
        },
        components: [
          {
            component_handle: CORE_SEAT_COMPONENTS[billingPeriod],
            allocated_quantity: coreSeats,
          },
        ],
      };

      if (salesSeats) {
        payload.components.push({
          component_handle: SALES_SEAT_COMPONENTS[billingPeriod],
          allocated_quantity: salesSeats,
        });
      }

      await axios.post(url, payload);
      await router.push({ name: routeOnSuccess });
      iteratively.userUpgradesToConnector({
        event_site: EVENT_SITES.SELF_SERVE_FLOW,
      });
      paymentSucceeded = true;
    } catch (err) {
      flashesStore.addUnhandledError(err);
      captureException(err);
    }

    try {
      await axios.post(urls.billing.roleUpdate); /* Migrate user roles */
      billingStore.refreshBillingStore({
        syncChargify: true,
      }); /* Do not wait for these API calls, the other pages handle loading */
      teamStore.refreshTeamStore();
    } catch (err) {
      captureException(err);
    }

    return paymentSucceeded;
  }

  async function editPaymentMethod({ token, period }) {
    try {
      const url = urls.billing.editCard(BILLING_PRODUCTS[period]);
      await axios.put(url, { chargify_token: token });
      flashesStore.addSuccessFlash({ message: 'Card updated successfully' });
      return true;
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Failed to update payment',
        description: err.message,
      });
    } finally {
      await Promise.all([
        billingStore.refreshBillingStore({ syncChargify: true }),
        billingStore.refreshPortalAndPaymentInfo(),
      ]);
    }
  }

  function updatePricePoints(pricePoints, currentPricePoints) {
    return currentPricePoints
      .reduce((agg, currentPricePoint, i) => {
        const newPricePoint = pricePoints[i];
        const newCost =
          newPricePoint.component_price_tier_total_cents -
          currentPricePoint.component_price_tier_total_cents;
        const newSeats =
          newPricePoint.allocated_quantity -
          currentPricePoint.allocated_quantity;
        agg.push({
          component_price_tier_total_cents: newCost,
          allocated_quantity: newSeats,
          unit_price_cents: newPricePoint.unit_price_cents,
        });

        return agg;
      }, [])
      .filter((pricePoint) => pricePoint.allocated_quantity > 0);
  }

  /* User Events 󰳾 */

  /* handleBillingInteraction() handles click actions that could cause the user to upgrade
   * in some way, either via purchasing seats or purchasing connector,
   * or talking to our sales team about paying more money */
  const defaultCtaOpts = {
    seatType: CORE,
    cta: PLAN_AND_BILLING_CTAS.BILLING,
    event_site: undefined,
    isEarlyAdopter: false,
    period: 'year',
    talkToSalesReason: null,
    number_of_seats: 1,
    attribution_credits: undefined, // TODO -- This is too specific, should be tracked separately
    cta_2: undefined, // TODO -- This is legacy, please do not make more of them
  };

  /**
   * Handles billing interactions.
   * @param {import('@/types/billing').BillingInteraction} opts
   * @param {import('@/types/billing').BillingQuery} routeQuery
   * @param {boolean} skipModal
   * @param {import('vue-router').RouteLocationRaw | null} routeTo
   */
  async function handleBillingInteraction(
    opts,
    routeQuery = null,
    skipModal = false,
    routeTo = null,
  ) {
    if (!skipModal && !opts?.talkToSalesReason) {
      if (routeTo) {
        await router.push(routeTo);
      }
      openComparisonPlanModal(opts, routeQuery);
      return;
    }
    opts = ensureDefaultsExist(opts, defaultCtaOpts);

    /* For all sales-led connectors or enterprise accounts, or if a "talkToSalesReason" is explicitly
     * provided, then we use the Hubspot form */
    if (mustTalkToSales.value || opts.talkToSalesReason) {
      opts = ensureDefaultsExist(opts, defaultTalkToSalesOpts);
      const talkToSalesReason =
        opts.talkToSalesReason ||
        (isEnterpriseTier.value
          ? 'Manage Supernode Account'
          : 'Upgrade to Supernode');
      await talkToSales(talkToSalesReason);
      iteratively.userClickedTalkToSalesSupernode({
        cta: opts.cta,
        cta_2: opts.cta_2,
        event_site: opts.event_site,
        early_adopter_status: opts.isEarlyAdopter || false,
        attribution_credits: opts.attribution_credits,
      });
      openTalkToSalesModal();
      return;
    }

    /* When cancelled, the upgrade flows should send you back to the previous page */
    const query = {
      cancelDestination: route.path,
      userIds: route.query?.ids || undefined,
      emails: route.query?.emails || undefined,
      ...routeQuery,
    };

    if (isFreeTier.value || (isConnectorTier.value && isOnFreeTrial.value)) {
      iteratively.userClickedUpgradeConnector({
        cta: opts.cta,
        cta_2: opts.cta_2,
        event_site: opts.event_site,
        billing_cycle: opts.period,
        early_adopter_status: opts.isEarlyAdopter,
        number_of_seats: opts.number_of_seats,
      });
      await router.push({
        name: 'self-serve-purchase-connector',
        query: {
          ...query,
          period: opts.period,
          cta: opts.cta,
          cta_2: opts.cta_2,
        },
      });
      return;
    }

    if (hasSubscription.value) {
      iteratively.userClickedAddSeats({
        event_site: opts.event_site,
        cta: opts.cta,
        location: route.path,
      });
      await router.push({ name: 'self-serve-connector-seats', query });
      return;
    }

    captureException(
      new Error('Billing interaction not handled by handleBillingInteraction!'),
    );
  }

  /* talkToSales() is used when a user interaction requires sales intervention. This is
   * typically when someone wants to upgrade to Supernode, but not always. For instance,
   * they may have hit an in-app limit, and need assistance */
  const defaultTalkToSalesOpts = {
    cta: PLAN_AND_BILLING_CTAS.BILLING,
    talkToSalesReason: 'Upgrade to Supernode',
    early_adopter_status: false,
    event_site: undefined,
    attribution_credits: undefined, // TODO -- This is too specific, should be tracked separately
    cta_2: undefined, // TODO -- This is legacy, please do not make more of them
  };

  async function talkToSales(talkToSalesReason) {
    try {
      const { envID, contactSalesForm: formID } = appConfig.hubSpotForms;
      const formData = {
        email: currentUser.value.email,
        lastname: currentUser.value.last_name,
        firstname: currentUser.value.first_name,
        company: currentOrg.value.name,
        why_do_you_need_to_talk_to_sales: talkToSalesReason,
      };

      await hubSpotFormApi(formID, formData, route.name, envID);
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Something went wrong, reach out to our support team for help',
      });
    }
  }

  async function handleDowngrade(eventSite) {
    iteratively.userClickedDowngrade({
      event_site: eventSite,
      cta: PLAN_AND_BILLING_CTAS.DOWNGRADE,
    });
    await router.push({
      name: 'downgrade',
      query: { cancelDestination: '/billing' },
    });
  }

  async function handleRestoreConnector() {
    iteratively.userClickedRestorePlan({
      cta: PLAN_AND_BILLING_CTAS.BILLING,
      cta_2: CTA_2.CONNECTOR_TIER,
    });
    await router.push({ name: 'restore-plan' });
  }

  async function cancelConnector({ period, payload }) {
    try {
      const url = urls.billing.selfServe.cancel(BILLING_PRODUCTS[period]);
      await axios.post(url, payload);
      return true;
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Could not cancel plan',
        description: 'If this error persists contact support@crossbeam.com',
      });
    } finally {
      await Promise.all([
        billingStore.refreshBillingStore({ syncChargify: true }),
        billingStore.refreshPortalAndPaymentInfo(),
        teamStore.refreshTeamStore(),
      ]);
    }
  }

  async function restoreConnector({ period }) {
    try {
      const url = urls.billing.selfServe.restore(BILLING_PRODUCTS[period]);
      await axios.delete(url);
      flashesStore.addSuccessFlash({ message: 'Plan Restored' });
      return true;
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        message: 'Could not restore plan',
        description: 'If this error persists contact support@crossbeam.com',
      });
    } finally {
      await Promise.all([
        billingStore.refreshBillingStore({ syncChargify: true }),
        billingStore.refreshPortalAndPaymentInfo(),
        teamStore.refreshTeamStore(),
      ]);
    }
  }

  async function reduceSeats({ payload }) {
    try {
      const url = urls.billing.selfServe.reduce;
      await axios.post(url, payload);
      flashesStore.addSuccessFlash({
        message: 'Seats successfully removed',
        description: 'Your next invoice is updated',
      });
      return true;
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Could not remove seats',
        description: 'If this error persists contact support@crossbeam.com',
      });
    } finally {
      await Promise.all([
        teamStore.refreshTeamStore(),
        billingStore.refreshBillingStore({ syncChargify: true }),
      ]);
    }
  }

  async function reclaimSeats({ components }) {
    try {
      const url = urls.billing.selfServe.reclaimSeats;
      await axios.patch(url, { components });
      flashesStore.addSuccessFlash({ message: 'Seats successfully reclaimed' });
      return true;
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({ message: 'Failed to reclaim seats' });
    }
  }

  return {
    allStoresReady,
    talkToSales,
    reduceSeats,
    previewConnector,
    reclaimSeats,
    buyConnector,
    cancelConnector,
    restoreConnector,
    previewComponents,
    purchaseComponents,
    editPaymentMethod,
    updatePricePoints,
    fetchSeatDataV4,
    processCostDataV4,
    handleBillingInteraction,
    handleDowngrade,
    handleRestoreConnector,
    syncAndReroute,
  };
}
