<template>
  <BittsModal
    :loading="loading || !ready"
    class="c-edit-user-modal"
    save-text="Save changes"
    :visible="true"
    :width="600"
    :disabled="cannotSave"
    @saved="handleEditUser"
    @closed="closeModal"
  >
    <template #content>
      <div class="content">
        <BittsAvatar
          :user="auth?.user"
          :show-initials="true"
          class="user-icon"
          size="large"
        />
        <h1 class="title">
          {{ readableName }}
        </h1>
        <p v-if="email !== readableName" class="email">
          {{ email }}
        </p>
        <SelectSeatType
          class="w-full mt-24"
          :picked-seat="pickedSeat"
          :auths="[auth]"
          :is-editing-user="true"
          @seat-selected="handleChangeSeat"
          @seats-needed="gettingValue = true"
          @cta-clicked="handleCtaClicked"
        />
        <GettingValueCTA
          v-if="!isFreeTier && gettingValue"
          class="w-full mb-24"
          :seat-type="pickedSeat?.value === CORE ? SALES : CORE"
          :seats-needed="1"
        />
        <div class="flex flex-col gap-16 w-full">
          <SelectCoreRole v-model="pickedCoreRole" :picked-seat="pickedSeat" />
          <SelectSalesRole
            v-if="!isFreeTier"
            v-model="pickedSalesRole"
            :picked-seat="pickedSeat"
          />
          <SeatAlerts
            :user-ids="[id]"
            :picked-core-role="pickedCoreRole"
            @is-removing-last-admin="(bool) => (isRemovingLastAdmin = bool)"
          />
          <DowngradeCallout
            v-if="isSubscriptionCancelled && debookingEnabled && notRetained"
            :title="`User will be removed on ${subscriptionRenewalDate}`"
            subtitle="You've downgraded and have chosen not to retain this user"
            button-text="Restore plan"
          />
        </div>
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsAvatar, BittsModal } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import DowngradeCallout from '@/components/billing/DowngradeCallout.vue';
import GettingValueCTA from '@/components/team/GettingValueCTA.vue';
import SeatAlerts from '@/components/team/SeatAlerts.vue';
import SelectCoreRole from '@/components/team/SelectCoreRole.vue';
import SelectSalesRole from '@/components/team/SelectSalesRole.vue';
import SelectSeatType from '@/components/team/SelectSeatType.vue';

import useAuthOrInvite from '@/composables/useAuthOrInvite';
import useBilling from '@/composables/useBilling';
import useHasFeature from '@/composables/useHasFeature';
import { TEMP_DEAL_DASHBOARD } from '@/constants/feature_flags';
import { CORE, NO_ACCESS } from '@/constants/team';
import {
  CORE_SEAT_OPTION,
  SALES,
  SALES_SEAT_OPTION,
} from '@/constants/team_v4';
import { captureException } from '@/errors';
import {
  allReady,
  useBillingStore,
  useFeatureFlagStore,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
});

const { debookingEnabled } = useHasFeature();

const router = useRouter();
const billingStore = useBillingStore();
const rootStore = useRootStore();
const featureFlagStore = useFeatureFlagStore();
const rolesStore = useRolesStore();
const teamStore = useTeamStore();
const flashesStore = useFlashesStore();
const ready = allReady(billingStore, teamStore);

const { salesSeatRole } = storeToRefs(rolesStore);

const hasDealNav = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_DEAL_DASHBOARD),
);

const { isFreeTier, isSubscriptionCancelled, subscriptionRenewalDate } =
  storeToRefs(billingStore);
const { handleBillingInteraction } = useBilling();

useHead({ title: 'Team - Remove User' });

const loading = ref(false);
const auth = computed(() => teamStore.getAuthorizationByUserId(props.userId));

/* Handle case where a user cannot be found, close the modal */
onMounted(async () => {
  if (!auth.value) {
    flashesStore.addErrorFlash({ message: 'That user does not exist' });
    await closeModal();
  }
});

const { readableName, email, id, role, salesRole, roleSeatType } =
  useAuthOrInvite(auth);

/* Seat type selection */
const isCoreRole = ref(
  hasDealNav.value ? roleSeatType.value === CORE : role.value,
);

const pickedSeat = ref(isCoreRole.value ? CORE_SEAT_OPTION : SALES_SEAT_OPTION);

function handleChangeSeat(newSeat) {
  pickedSeat.value = newSeat;
  if (newSeat.value === SALES) {
    pickedCoreRole.value = hasDealNav.value ? salesSeatRole.value.id : null;
  } else {
    pickedCoreRole.value = initialCoreRole;
  }
}

/* Role selection */
const initialCoreRole = role.value?.id;
const pickedCoreRole = ref(initialCoreRole);
const pickedSalesRole = ref(salesRole.value || NO_ACCESS);

/* Submit form */
const isRemovingLastAdmin = ref(false);
const cannotSave = computed(() => {
  return (
    isRemovingLastAdmin.value ||
    !pickedSeat.value?.value ||
    (pickedSeat.value?.value === CORE && !pickedCoreRole.value) ||
    (pickedSeat.value?.value === SALES && !pickedSalesRole.value)
  );
});
async function handleEditUser() {
  loading.value = true;
  try {
    await teamStore.updateRoles({
      userIds: [id.value],
      salesEdgeRoleId: pickedSalesRole.value,
      crossbeamRoleId: pickedCoreRole.value,
    });

    rootStore.loadUserProfile();
    flashesStore.addSuccessFlash({ message: `${readableName.value} updated` });
    billingStore.refreshBillingStore(false);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'User could not be edited',
      description: 'If this error persists contact support@crossbeam.com',
    });
  } finally {
    closeModal();
  }
}

async function closeModal() {
  loading.value = false;
  await router.push({ name: 'team' });
}

function handleCtaClicked() {
  const seatType = pickedSeat.value.value;
  const query = { [seatType]: 1 };
  handleBillingInteraction(
    { seatType, event_site: EVENT_SITES.EDIT_USER_SEAT_TYPE_CTA },
    query,
  );
}

/* Getting Value CTA */
const gettingValue = ref(false);

/* Post-Cancellation State */
const notRetained = computed(() => auth.value.losing_access);
</script>

<style lang="pcss" scoped>
.c-edit-user-modal {
  .content {
    @apply flex flex-col items-center mt-[-36px];
  }
  .title {
    @apply mt-20 text-neutral-text-strong text-xl font-bold mb-8;
  }
  .email {
    @apply text-neutral-text text-m;
  }
}
</style>
<style lang="pcss">
.c-edit-user-modal {
  .ant-modal-body {
    background: var(
      --radial-neutral,
      radial-gradient(
        130.96% 20.92% at 70.09% 2%,
        rgba(140, 159, 184, 0.18) 5%,
        rgba(140, 159, 184, 0) 100%
      ),
      #fff
    );
    @apply rounded-16;
  }

  .c-bitts-modal__buttons--wrapper {
    @apply flex w-full items-center justify-between;
  }
}
</style>
