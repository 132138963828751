import { GetApiPayloadByUrl } from '@crossbeam/openapi';

import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import { crossbeamApi } from '@/api';
import usePopulations from '@/composables/usePopulations';
import { SHARING_TYPES, SharingType } from '@/constants/data_shares';
import { isGoogleOrCsvSource } from '@/constants/data_sources';
import { useDataSharePresetsStore, useFlashesStore } from '@/stores';
import { DataSharePreset } from '@/types/data_share_presets';
import { OutgoingSharingRule } from '@/types/outgoing_sharing_rules';

export type DataShareItem = {
  partnerId: number | null;
  feedId: number;
  sourceId?: number;
  presetParentId: number; // feedId or sourceId
  populationId: number;
  populationName: string;
  visibility: SharingType;
  presetId?: number | null;
  integrationType?: string;
};

type PresetOptionItem = {
  value: number | string;
  label: string;
  icon?: [string, string];
};

export type PresetOptionsGroup = {
  heading: string | null;
  items: PresetOptionItem[];
};

type PopulationOptionItem = {
  label: string;
  value: number;
  defaultPresetId?: number;
};

type Assignments = GetApiPayloadByUrl<'/v0.1/data-share-assignments', 'post'>;
type DataShare = Assignments['data_shares'][0];

export default function (partnerId: number | null = null, isBulk = false) {
  const isLoading = ref(false);
  const dataShares = ref<DataShareItem[]>([]);
  const bulkPayload = ref<Assignments>({
    partner_org_id: partnerId,
    data_shares: [],
  });

  const { ready, allPresets } = storeToRefs(useDataSharePresetsStore());
  const { standardPopulations, customPopulations } = usePopulations(
    partnerId as never,
  );
  const allPopulations = computed(() => [
    ...standardPopulations.value,
    ...customPopulations.value,
  ]);

  const populationOptions = computed(() =>
    allPopulations.value
      .filter((population) => {
        return !dataShares.value.some(
          (dataShare: DataShareItem) =>
            dataShare.populationId === population.id,
        );
      })
      .map((population) => {
        const isGoogleOrCsv = isGoogleOrCsvSource(population.integration_type);
        const parentId = isGoogleOrCsv
          ? population.source_id
          : population.feed_id;
        return {
          label: population.name,
          value: population.id,
          defaultPresetId:
            presetOptions.value[parentId]?.[0]?.items[0]?.value ?? null,
        } as PopulationOptionItem;
      }),
  );

  const presetOptions = computed(() =>
    getPresetOptionsPerFeed(allPresets.value),
  );

  onMounted(() => {
    getDataShares();
  });

  async function getDataShares() {
    isLoading.value = true;
    const { data, error } = await crossbeamApi.GET(
      '/v0.1/outgoing-sharing-rules',
    );
    if (error) throw error;

    // Group rules by population_id
    const rulesByPopulation: Record<number, OutgoingSharingRule[]> = {};
    data.items.forEach((rule: OutgoingSharingRule) => {
      if (!rulesByPopulation[rule.population_id]) {
        rulesByPopulation[rule.population_id] = [];
      }
      rulesByPopulation[rule.population_id]?.push(rule);
    });

    // Process grouped rules, prioritizing "partner" type with matching partnerId (and fallback to "population" type)
    const processedShares: DataShareItem[] = [];

    Object.entries(rulesByPopulation).forEach(([populationId, rules]) => {
      const numPopId = Number(populationId);

      // First, look for a partner rule with matching partnerId
      const partnerRuleForCurrentPartner = rules.find(
        (rule) =>
          rule.rule_type === 'partner' && rule.partner_org_id === partnerId,
      );
      if (partnerRuleForCurrentPartner) {
        const pop = allPopulations.value.find(
          (population) => population.id === numPopId,
        );
        const isGoogleOrCsv = isGoogleOrCsvSource(pop?.integration_type);

        processedShares.push({
          partnerId: partnerRuleForCurrentPartner.partner_org_id,
          populationId: numPopId,
          populationName: pop?.name,
          integrationType: pop?.integration_type,
          visibility: partnerRuleForCurrentPartner.visibility as SharingType,
          presetId: partnerRuleForCurrentPartner.data_share_presets_id,
          feedId: pop?.feed_id,
          sourceId: isGoogleOrCsv ? pop?.source_id : undefined,
          presetParentId: isGoogleOrCsv ? pop?.source_id : pop?.feed_id,
        });
      } else {
        // If not found, check for a population rule (as fallback)
        const populationRule = rules.find(
          (rule) => rule.rule_type === 'population',
        );

        if (populationRule) {
          const pop = allPopulations.value.find(
            (population) => population.id === numPopId,
          );
          const isGoogleOrCsv = isGoogleOrCsvSource(pop?.integration_type);

          processedShares.push({
            partnerId: null,
            populationId: numPopId,
            populationName: pop?.name,
            integrationType: pop?.integration_type,
            visibility: populationRule.visibility as SharingType,
            presetId: populationRule.data_share_presets_id,
            feedId: pop?.feed_id,
            sourceId: isGoogleOrCsv ? pop?.source_id : undefined,
            presetParentId: isGoogleOrCsv ? pop?.source_id : pop?.feed_id,
          });
        }
      }
    });

    dataShares.value = processedShares.filter(
      (dataShare) => dataShare.visibility !== SHARING_TYPES.HIDDEN,
    );
    isLoading.value = false;
  }

  async function createDataShare({
    value: populationId,
    defaultPresetId,
  }: PopulationOptionItem) {
    const newItem = {
      population_id: populationId,
      visibility: SHARING_TYPES.GREENFIELD_SHARING,
      preset_id: defaultPresetId,
    };

    if (isBulk) {
      addOrReplaceDataShare(bulkPayload.value, populationId, newItem);
    } else {
      const body: Assignments = {
        partner_org_id: partnerId,
        data_shares: [newItem],
      };
      const { error } = await crossbeamApi.POST(
        '/v0.1/data-share-assignments',
        {
          body,
        },
      );
      if (error) throw error;
      showSuccessToast();
    }

    const pop = allPopulations.value.find(
      (population) => population.id === populationId,
    );
    const isGoogleOrCsv = isGoogleOrCsvSource(pop?.integration_type);

    // Add the new data share to the local array
    dataShares.value.push({
      partnerId,
      populationId,
      populationName: pop?.name,
      integrationType: pop?.integration_type,
      visibility: SHARING_TYPES.GREENFIELD_SHARING,
      presetId: defaultPresetId,
      feedId: pop?.feed_id,
      presetParentId: isGoogleOrCsv ? pop?.source_id : pop?.feed_id,
    });
  }

  async function updateDataShare({
    populationId,
    visibility,
    presetId,
  }: {
    populationId: number;
    visibility: SharingType;
    presetId?: number | null;
  }) {
    const newItem = {
      population_id: populationId,
      visibility,
      preset_id:
        visibility === SHARING_TYPES.OLAPS_ONLY ||
        visibility === SHARING_TYPES.HIDDEN
          ? null
          : presetId,
    };
    if (isBulk) {
      addOrReplaceDataShare(bulkPayload.value, populationId, newItem);
    } else {
      const body: Assignments = {
        partner_org_id: partnerId,
        data_shares: [newItem],
      };
      const { error } = await crossbeamApi.POST(
        '/v0.1/data-share-assignments',
        {
          body,
        },
      );
      if (error) throw error;
      showSuccessToast();
    }

    if (visibility === SHARING_TYPES.HIDDEN) {
      dataShares.value = dataShares.value.filter(
        (share) => share.populationId !== populationId,
      );
    } else {
      const index = dataShares.value.findIndex(
        (share) => share.populationId === populationId,
      );
      dataShares.value[index] = {
        ...dataShares.value[index],
        visibility,
        presetId,
      } as DataShareItem;
    }
  }

  async function bulkUpdateDataShares() {
    const { error } = await crossbeamApi.POST('/v0.1/data-share-assignments', {
      body: bulkPayload.value,
    });
    if (error) throw error;
  }

  return {
    isLoading: computed(() => isLoading.value || !ready.value),
    dataShares,
    populationOptions,
    presetOptions,
    createDataShare,
    updateDataShare,
    bulkUpdateDataShares,
  };
}

// Helpers

function showSuccessToast() {
  const flashesStore = useFlashesStore();
  flashesStore.addSuccessFlash({ message: 'Settings saved' });
}

function addOrReplaceDataShare(
  payload: Assignments,
  populationId: number,
  newItem: DataShare,
) {
  const index = payload.data_shares.findIndex(
    (item) => item.population_id === populationId,
  );
  index !== -1
    ? (payload.data_shares[index] = newItem)
    : payload.data_shares.push(newItem);
}

function getPresetOptionsPerFeed(allPresets: DataSharePreset[]) {
  const dataSharePresetsStore = useDataSharePresetsStore();

  const options: Record<number, PresetOptionsGroup[]> = {};

  allPresets.forEach((preset: DataSharePreset) => {
    // base_source_id is only filled if the preset is csv/gsheet type
    const parentId = preset.base_source_id ?? preset.feed_id;
    if (!options[parentId]) {
      options[parentId] = [
        {
          heading: null,
          items: [],
        },
        {
          heading: null,
          items: [
            {
              value: 'custom', // TODO replace with single share create
              label: 'Custom field selection...',
              icon: ['far', 'plus'],
            },
          ],
        },
      ];
    }

    const isDefault = preset.preset_type === 'crossbeam_default';
    const groupIndex = isDefault ? 0 : 1;

    if (!isDefault && options[parentId].length === 2) {
      options[parentId].splice(1, 0, {
        heading: null,
        items: [],
      });
    }

    options[parentId]?.[groupIndex]?.items.push({
      value: preset.id,
      label: dataSharePresetsStore.getPresetName(
        preset.feed_id,
        preset.base_source_id || undefined,
        preset.id,
      ),
    });
  });

  return options;
}
