<template>
  <Notification :item>
    <template #logo>
      <FontAwesomeIcon
        :icon="['fak', 'collaborate']"
        :style="{ height: '40px', width: '40px', color: 'currentColor' }"
        class="text-info-accent"
      />
    </template>
    <template #message>
      {{ notificationTitle }}
    </template>
    <template #description>
      <div ref="notificationDescRef">
        {{ notificationDescription }}
      </div>
    </template>
    <template #action>
      <BittsButton
        v-if="buttonText"
        :text="buttonText"
        variant="outline"
        size="x-small"
        @click="handleButtonAction"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'collaborate']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import Notification from '@/components/notifications/Notification.vue';

import {
  LIST_MEMBERS_UPDATED,
  LIST_NOTE_UPDATED,
  LIST_SHARING_UPDATED,
  MAX_RECORDS_SHOWN,
} from '@/constants/shared_list';
import { useNotificationsStore } from '@/stores';

/*
 list notification event types:
 * list_sharing_updated - e.g. created / deleted list, added removed users
   * this event type has three different branches based off the action the event data
     * data.action = "deleted"
     * data.action = "internal-share"
     * data.action = "external-share"
 * list_members_updated - e.g. added records
 * list_note_updated
 */

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['action-clicked']);
const notificationDescRef = ref(null);

onMounted(() => {
  if (
    notificationDescRef.value &&
    props.item.event_type === LIST_NOTE_UPDATED
  ) {
    notificationDescRef.value.classList.add(
      'c-list-notification__notif-description',
    );
  }
});

const router = useRouter();
const notificationStore = useNotificationsStore();

const isListDeleted = computed(() => props.item.data.action === 'deleted');
const isInternalShare = computed(
  () => props.item.data.action === 'internal-share',
);

const notificationTitle = computed(() => {
  switch (props.item.event_type) {
    case isListDeleted.value && LIST_SHARING_UPDATED:
      return 'List Sharing Stopped';
    case LIST_MEMBERS_UPDATED:
      return 'New Records Added to Shared List';
    case LIST_NOTE_UPDATED:
      return 'Note Updated in Shared List';
    default:
      return 'New List Shared';
  }
});

const formattedRecordNames = computed(() => {
  if (props.item.data.members.length === 1)
    return `${props.item.data.members[0].name} added to ${props.item.data.list_name}`;
  const allRecords = [...props.item.data.members];
  const formattedLastRecord =
    allRecords.length > MAX_RECORDS_SHOWN
      ? `${allRecords.length - MAX_RECORDS_SHOWN} more`
      : allRecords.pop().name;
  const allOtherRecords = allRecords
    .slice(0, MAX_RECORDS_SHOWN)
    .map((record) => record.name)
    .join(', ');
  return `${allOtherRecords}, and ${formattedLastRecord} added to ${props.item.data.list_name}`;
});

function noteUpdatedDescription() {
  // the list_note_updated event data has a version signifying that there are multiple
  // implementations of the data in the notifications db, and we need to be able to render old ones
  switch (props?.item?.data?.version) {
    case 2:
      return `${props.item.data.user_name} wrote a note for ${props.item.data.record_name} in ${props.item.data.list_name}: "${props.item.data.note_text}"`;
    default:
      return `${props.item.data.user_name} wrote a note in ${props.item.data.list_name}: "${props.item.data.note_text}"`;
  }
}

const notificationDescription = computed(() => {
  switch (props.item.event_type) {
    case isListDeleted.value && LIST_SHARING_UPDATED:
      return `Shared List ${props.item.data.list_name} is no longer shared`;
    case LIST_MEMBERS_UPDATED:
      return formattedRecordNames.value;
    case LIST_NOTE_UPDATED:
      return noteUpdatedDescription();
    case isInternalShare.value && LIST_SHARING_UPDATED:
      return `${props.item.data.shared_by_user_name} has shared ${props.item.data.list_name}`;
    default:
      return `${props.item.data.shared_by_user_name} from ${props.item.data.shared_by_org_name} has shared ${props.item.data.list_name}`;
  }
});

const buttonText = computed(() => {
  switch (props.item.event_type) {
    case !isListDeleted.value && LIST_SHARING_UPDATED:
    case LIST_MEMBERS_UPDATED:
      return 'View List';
    case LIST_NOTE_UPDATED:
      return 'View Note';
    default:
      return null;
  }
});

async function handleButtonAction() {
  emit('action-clicked', 'View List');
  await notificationStore.markRead({ id: props.item.id, isRead: true });
  await router.push({
    name: 'collaborate_list',
    params: { id: props.item.data.list_id },
  });
}
</script>
<style scoped lang="pcss">
.c-list-notification__notif-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 1rem;
}
</style>
