<template>
  <BittsCollapse
    :is-default-open="true"
    expand-icon-position="end"
    :show-underline="false"
    class="c-integration-settings-own-populations"
  >
    <template #header="{ isOpen }">
      <div class="flex items justify-between">
        <h6 class="text-info-text text-base/6 font-bold"> My Populations </h6>
        <p class="text-neutral-text-weak text-base/6 font-normal">{{
          isOpen ? 'Collapse' : 'Expand'
        }}</p>
      </div>
    </template>
    <div v-if="sortedPopulations.length === 0" class="flex">
      <p class="text-neutral-text-weak text-base/6 font-normal mr-8">
        You don’t have any
        {{
          integrationProfileCrm ? friendlyNameMap[integrationProfileCrm] : ''
        }}
        Populations
      </p>
      <RouterLink to="/populations" class="text-secondary-accent text-base/6">
        <span>Create Population</span>
        <FontAwesomeIcon class="text-12 ml-4" :icon="['fak', 'open-link']" />
      </RouterLink>
    </div>
    <div class="flex flex-col gap-4">
      <BittsCheckbox
        v-for="population in sortedPopulations"
        :checked="checkedOwnPopIds.includes(population.id)"
        :disabled="isDisabled"
        :key="population.id"
        :label="population.name"
        @input="(isChecked: boolean) => boxChecked(population.id, isChecked)"
      />
    </div>
  </BittsCollapse>
</template>

<script setup lang="ts">
import { BittsCheckbox, BittsCollapse } from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { computed } from 'vue';

import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import { usePopulationsStore } from '@/stores';
import {
  IntegrationProfileCrm,
  IntegrationProfileType,
  MinimalIntegrationProfile,
} from '@/types/integration_profiles';

const props = withDefaults(
  defineProps<{
    integrationProfileSettings?: MinimalIntegrationProfile | null;
    isDisabled?: boolean;
    settingType: IntegrationProfileType;
  }>(),
  { integrationProfileSettings: undefined, isDisabled: false },
);

const emit =
  defineEmits<
    (e: 'update:settings', payload: MinimalIntegrationProfile) => void
  >();

const populationsStore = usePopulationsStore();

const checkedOwnPopIds = computed(() => {
  return (
    props.integrationProfileSettings?.population_settings
      .filter((pop) => pop.is_included && !pop.is_partner_population)
      .map((pop) => pop.population_id) ?? []
  );
});
const integrationProfileCrm = computed<IntegrationProfileCrm>(
  () =>
    (
      ({
        clari: 'salesforce',
        salesforce: 'salesforce',
        hubspot: 'hubspot',
        microsoft_dynamics: 'microsoft_dynamics',
        snowflake: undefined,
        crossbeam_copilot: undefined,
      }) as const
    )[props.settingType],
);
const friendlyNameMap = {
  salesforce: 'Salesforce',
  microsoft_dynamics: 'Microsoft Dynamics',
  hubspot: 'HubSpot',
  snowflake: 'Snowflake',
} as const;

const sortedPopulations = computed(() => {
  return sortBy(
    populationsStore.populations
      .filter((pop) =>
        pop.base_schema.startsWith(integrationProfileCrm.value || ''),
      )
      .map((pop) => ({
        id: pop.id,
        name: pop.name,
        standard_type: pop.standard_type,
      })),
    (pop) => {
      if (pop.standard_type)
        return STANDARD_POPULATION_TYPES.indexOf(pop.standard_type);
    },
  );
});

const updatePopulationSettings = (id: number, isChecked: boolean) => {
  return {
    // 1. map to the required format
    // 2. filter out items that are (is_included === false) and didn't exist in props.integrationProfileSettings initially
    population_settings: sortedPopulations.value
      .map((pop) => ({
        population_id: pop.id,
        is_included:
          pop.id === id ? isChecked : checkedOwnPopIds.value.includes(pop.id),
        is_partner_population: false,
        config_type: props.settingType,
      }))
      .filter(
        (pop) =>
          pop.is_included ||
          props.integrationProfileSettings?.population_settings.some(
            (setting) => setting.population_id === pop.population_id,
          ),
      ),
    standard_population_settings:
      props.integrationProfileSettings?.standard_population_settings ?? [],
  };
};

const boxChecked = (id: number, isChecked: boolean) => {
  const payload = updatePopulationSettings(id, isChecked);
  emit('update:settings', payload);
};
</script>

<style lang="pcss">
.c-integration-settings-own-populations {
  @apply px-12 py-8 bg-neutral-background-weak/50 rounded-lg;
}
</style>
