/* For use in AE mapping and DUNS mapping post-import */

import { computed, onMounted, ref } from 'vue';

import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useFeedsStore, useSourcesStore } from '@/stores';

export default function useColumnMapping(sourceId) {
  const sourcesStore = useSourcesStore();
  const feedsStore = useFeedsStore();

  const loading = ref(false);
  const feed = ref(null);
  const source = ref(null);
  const allColumns = ref([]);
  const unmappedColumns = ref([]);

  onMounted(async () => {
    await Promise.all([sourcesStore.readySync, feedsStore.readySync]);
    source.value = sourcesStore.sources.find(
      (source) => source.id === sourceId,
    );
    feed.value = feedsStore.getFeedById(source.value?.feed_id);
    allColumns.value = unusedSourceColumns(source.value);
    unmappedColumns.value = unusedSourceColumns(source.value);
    loading.value = false;
  });

  const isSourceType = (type) =>
    computed(() => {
      const feedId = sourcesStore.getSourceById(sourceId)?.feed_id;
      return feedsStore.getFeedById(feedId)?.integration.type === type;
    });

  const isSourceGoogleSheet = isSourceType(GOOGLE_SHEETS_DATA_SOURCE_TYPE);
  const isSourceCsv = isSourceType(FILE_UPLOAD_DATA_SOURCE_TYPE);

  // Filters out invalid source columns and columns that aren't mapped yet
  function unusedSourceColumns(source) {
    const columns =
      source?.fields.reduce((acc, field) => {
        if (
          field.is_visible &&
          field.mdm_property === null &&
          ![
            'File Name',
            'Upload Time',
            'id',
            'createdAt',
            'created_at',
          ].includes(field.column)
        ) {
          acc.push({ value: field.column, label: field.column, id: field.id });
        }
        return acc;
      }, []) ?? [];

    return columns;
  }
  return {
    unusedSourceColumns,
    isSourceGoogleSheet,
    isSourceCsv,
    loading,
    feed,
    source,
    allColumns,
    unmappedColumns,
  };
}
