export default {
  env: 'staging',
  apiBaseUrl: 'https://api.cb.c7m.io',
  salesEdgeBaseUrl: 'https://sales-backend-api.cb.c7m.io',
  salesEdgeRedirectUrl: 'https://sales.cb.c7m.io',
  salesforceReauthUrl: 'lightning/n/xbqa__Setup',
  host: 'app.cb.c7m.io',
  auth0: {
    domain: 'auth.cb.c7m.io',
    clientID: 'zpqyu12qLqNke407hhYK93bQ2nZRGA5l',
    audience: 'https://api.staging.getcrossbeam.com',
  },
  datadog: {
    applicationId: '1e70f535-076b-454c-88c6-c01d1eb495a9',
    clientToken: 'pub881a1c58f3a08260853d80d2a704b75e',
    site: 'datadoghq.com',
    service: 'crossbeam-web',
    env: 'staging',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ['https://api.cb.c7m.io'],
    allowFallbackToLocalStorage: true,
  },
  statuspageBaseUrl: 'https://crossbeamstaging.statuspage.io',
  statuspagePageId: '6dcrv6nnlbkg',
  statusPageEmbedUrl: 'https://6dcrv6nnlbkg.statuspage.io/embed/script.js',
  enableGoogleTags: false,
  intercom: {
    enabled: true,
    workspaceId: 'g42wpi5b',
  },
  userLeap: {
    enabled: true,
    appId: '6pBot6YECBQ',
  },
  iteratively: {
    environment: 'development',
  },
  hubSpotForms: {
    contactSalesForm: '846e4458-a3ea-42a4-ae97-f148221fd56b',
    envID: '5767578',
  },
  chargify: {
    publicKey: 'chjs_qq34qvgz5d523d87w8hv7fdz',
    serverHost: 'https://crossbeam-stage.chargify.com',
  },
} as const;
