<template>
  <BittsModalTwo
    :visible="true"
    :loading="!ready"
    :action-loading="loading"
    :width="640"
    :use-mask-to-close="true"
    :disabled="!selectedCompany || cannotSendInvite"
    :primary-button-text="saveText"
    @closed="handleCloseModal"
    @action="handleAddPartner"
    title="Invite a partner to connect on Crossbeam"
    description="Crossbeam is free for them to use."
    class="c-onboarding-invite-partner-modal"
  >
    <template #image>
      <BittsAvatarStack
        :org-list="[
          { ...currentOrg, showInitials: true },
          {
            customSvg: 'add',
          },
        ]"
        :show-initials="true"
        :transparent="false"
        size="medium"
        class="mb-16"
      />
    </template>
    <InvitePartnerSearch class="w-full mb-16" @input="companyChanged" />
    <div v-if="!proposalAlreadyExists && !currentOrgSentProposal">
      <BittsSelect
        v-if="potentialContacts.length > 0"
        v-model="chosenContactId"
        :options="potentialContacts"
        :allow-clear="true"
        :disabled="!selectedCompany"
        data-testid="discoverable-contact-selector"
        placeholder="Select contact"
        :form-label="{
          title: 'Contact',
          secondaryText: isPartnerDiscoverable ? 'Optional' : '',
        }"
        option-type="entity"
        class="mb-16"
      />
      <BittsInput
        v-else
        v-model="nonDiscoveredContactEmail"
        :disabled="!selectedCompany"
        :status="!!nonDiscoveredContactEmailErr ? 'danger' : 'default'"
        :danger-text="nonDiscoveredContactEmailErr"
        name="contact-email-input"
        data-testid="contact-email-input"
        placeholder="Enter a contact email"
        :form-label="{
          title: 'Contact',
          secondaryText: isPartnerDiscoverable ? 'Optional' : '',
        }"
        class="mb-16"
      />
      <BittsTextArea
        v-model="inviteMessage"
        data-testid="proposal-message-input"
        :disabled="!selectedCompany"
        :status="inviteMessageErr ? 'danger' : 'default'"
        :danger-text="inviteMessageErr"
        :form-label="{
          title: 'Write your message',
          secondaryText: 'Optional',
        }"
        placeholder="Enter a message"
        class="mb-16"
      />
      <BittsCheckbox
        :checked="sendCopy"
        @input="sendCopy = !sendCopy"
        label="Send me a copy of the invite"
        class="mb-16"
      />
    </div>
    <BittsAlert
      v-if="proposalWarningTitle"
      data-testid="proposal-warning"
      :color="proposalWarningState"
      :message="proposalWarningTitle"
      :description="proposalWarningMsg"
      class="mb-16"
    />
    <BittsAlert
      v-if="!proposalWarningTitle"
      message="This is safe! You will be able to control what you share with them or not."
      color="success"
    />
  </BittsModalTwo>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsAvatarStack,
  BittsCheckbox,
  BittsInput,
  BittsSelect,
  BittsTextArea,
} from '@crossbeam/bitts';
import BittsModalTwo from '@crossbeam/bitts/src/BittsModalTwo/BittsModalTwo.vue';
import { Nullable } from '@crossbeam/types';

import { email as emailVal } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { LocationQueryValue, useRoute, useRouter } from 'vue-router';

import InvitePartnerSearch from '@/components/partners/InvitePartnerSearch.vue';

import useAuth from '@/composables/useAuth';
import usePartnerManagement from '@/composables/usePartnerManagement';
import { allReady, useDataSharesStore, usePartnersStore } from '@/stores';

type Company = Nullable<{
  // TODO, fix when account is reitit-ed
  name: string | LocationQueryValue[];
  domain: string | LocationQueryValue[];
  public_invite_code: string | LocationQueryValue[];
  clearbit_domain: string | LocationQueryValue[];
}>;
type Contact = Nullable<{
  // TODO, fix when account is reitit-ed
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}>;

const { currentOrg } = useAuth();
const router = useRouter();
const route = useRoute();
const dataSharesStore = useDataSharesStore();
const partnersStore = usePartnersStore();
const { getDiscoverableTeam, sendInvitation } = usePartnerManagement();

const { proposalsSent, proposalsReceived } = storeToRefs(partnersStore);
const ready = allReady(partnersStore, dataSharesStore);
onMounted(() => {
  const { name, domain, public_invite_code: pic } = route.query;
  if (name && domain && pic)
    selectedCompany.value = {
      name,
      domain,
      public_invite_code: pic,
      clearbit_domain: domain,
    };
});

const loading = ref<boolean>(false);
const selectedCompany = ref<Company>(null);
const isUserInputtedCompany = computed(
  () => new Set(Object.values(selectedCompany.value || {})).size === 2,
); /* We can tell whether the user chose to "create" the partner because all of their information will be identical from InvitePartnerSearch 😄 */
const isPartnerDiscoverable = computed(
  () =>
    !!selectedCompany.value?.public_invite_code && !isUserInputtedCompany.value,
);

const potentialContacts = ref<Contact[]>([]);

// We either "pick" the discoverable contact or manually type an email
const chosenContactId = ref(null);
const nonDiscoveredContactEmail = ref('');
const inviteMessage = ref('');
const sendCopy = ref(true);

// Errors
const nonDiscoveredContactEmailErr = computed(() => {
  if (!nonDiscoveredContactEmail.value) return '';
  return !emailVal.$validator(
    nonDiscoveredContactEmail.value.toLowerCase(),
    null,
    null,
  )
    ? 'Please enter a valid email'
    : '';
});
const inviteMessageErr = computed(() =>
  inviteMessage.value.length > 500
    ? 'Invite messages may only be 500 characters'
    : '',
);

const saveText = computed(() => {
  return proposalAlreadyExists.value ? 'View invitation' : 'Invite';
});

function handleCloseModal() {
  router.back();
}

function resetFields() {
  selectedCompany.value = null;
  nonDiscoveredContactEmail.value = '';
  potentialContacts.value = [];
  chosenContactId.value = null;
  inviteMessage.value = '';
}

async function companyChanged({ company }: { company: Company }) {
  resetFields();

  if (!company?.name) {
    /* Re-select dropdown after clearing, otherwise open/close based on
    whether we need additional information or not from the user */
    document
      .querySelector<HTMLInputElement>('#invite-partner-search input')
      ?.focus();
    return;
  }

  selectedCompany.value = company;
  if (!isPartnerDiscoverable.value) {
    document
      .querySelector<HTMLInputElement>('#invite-partner-search input')
      ?.blur();
  }

  if (company.public_invite_code) {
    potentialContacts.value = await getDiscoverableTeam({
      publicInviteCode: company.public_invite_code,
    });
  }
}

const selectedContact = computed(() =>
  chosenContactId.value
    ? potentialContacts.value.find(
        (c: Contact) => c?.id === chosenContactId.value,
      )
    : null,
);

/* Proposal Warning */
const clearbitDomainsFromReceivedProposals = computed(() =>
  proposalsReceived.value.map(
    (proposal) => proposal?.sending_organization?.clearbit_domain,
  ),
);

const proposalAlreadyExists = computed(() =>
  clearbitDomainsFromReceivedProposals.value.some(
    (val) => val === selectedCompany.value?.domain,
  ),
);

const currentOrgSentProposal = computed(() =>
  proposalsSent.value.some(
    (proposal) => proposal.contact_company === selectedCompany.value?.name,
  ),
);

const proposalWarningTitle = computed(() => {
  if (proposalAlreadyExists.value)
    return `You have a partnership request from ${selectedCompany.value?.name}`;
  if (currentOrgSentProposal.value)
    return `You have an open partnership request to ${selectedCompany.value?.name}`;
  return '';
});
const proposalWarningMsg = computed(() => {
  if (proposalAlreadyExists.value)
    return 'Accept their invitation to collaborate live on Crossbeam';
  if (currentOrgSentProposal.value)
    return 'If you want to send a new one, revoke the existing invitation';
  return '';
});

const proposalWarningState = computed(() => {
  return proposalAlreadyExists.value ? 'success' : 'warning';
});

const cannotSendInvite = computed(() => {
  if (proposalAlreadyExists.value) return false;
  if (proposalWarningTitle.value) return true;

  if (isPartnerDiscoverable.value) return !selectedCompany.value;

  return (
    !!nonDiscoveredContactEmailErr.value ||
    !selectedCompany.value ||
    (!selectedContact.value && !nonDiscoveredContactEmail.value)
  );
});

async function handleAddPartner() {
  loading.value = true;
  if (proposalAlreadyExists.value) {
    const existingIncomingProposal = proposalsReceived.value.find(
      (proposal) =>
        proposal?.sending_organization?.clearbit_domain ===
        selectedCompany?.value?.domain,
    );
    // TODO replace with new route to accept invite from onboarding
    await router.push({
      name: 'accept_proposal',
      query: { proposal_id: existingIncomingProposal?.id },
    });
    return;
  }

  await sendInvitation({
    company: selectedCompany.value,
    contact: {
      email:
        selectedContact.value?.email || nonDiscoveredContactEmail.value || null,
      first_name: selectedContact.value?.first_name ?? null,
      last_name: selectedContact.value?.last_name ?? null,
    },
    inviteMessage: inviteMessage.value,
  });

  handleCloseModal();
}
</script>

<style lang="pcss">
.c-onboarding-invite-partner-modal {
  .o-bitts-modal__header {
    @apply p-40 !important;
  }
  .bitts-avatar-stack-medium {
    .bitts-avatar-stack__org-list {
      @apply justify-center p-0 mb-10;
      & > div {
        @apply w-[52px];
      }
      img {
        @apply bg-denim-200;
      }
    }
  }
  .o-bitts-modal__description {
    @apply text-neutral-text-weakest text-base !important;
  }
  .bitts-avatar-stack__transparent .bitts-avatar-stack__org-list {
    background-color: transparent;
  }

  .bitts-form-label__text {
    @apply text-neutral-text-strong !important;
  }

  textarea.c-bitts-textarea.ant-input {
    &.disabled {
      @apply bg-neutral-50 outline-none border-neutral-100;
    }
  }
  textarea.c-bitts-textarea.ant-input::placeholder {
    &.disabled {
      @apply text-neutral-300;
    }
  }
}
</style>
