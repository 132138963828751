<template>
  <BittsTooltip>
    <div
      v-if="totalOverlaps != null"
      data-testid="record-exports-estimation"
      class="rounded-lg my-16 py-8 px-12 text-base"
      :class="
        isWarningState
          ? 'bg-warning-background-weak'
          : 'bg-info-background-weak'
      "
    >
      <div class="flex items-center justify-between">
        <span class="text-neutral-text-weak"> Estimated record exports </span>
        <b :class="isWarningState ? 'text-warning-text' : 'text-neutral-text'">
          {{ totalOverlaps?.toLocaleString('en-US') }}
        </b>
      </div>
      <div v-if="isWarningState">
        <FontAwesomeIcon
          class="text-warning-accent text-12"
          :icon="['fak', 'exclamation-circle']"
        />
        <p class="text-neutral-text-weakest text-sm inline">
          This is an estimate. Saving this may exceed your export limit.
        </p>
        <BittsButton
          :text="'Learn More'"
          variant="ghost"
          type="neutral"
          data-testid="exports-warning-button"
          class="inline-flex mx-4"
          size="x-small"
          @click="openHelpTab"
        />
      </div>
    </div>
    <template #title>
      <p
        >Estimation of unique records exported. This estimate may be higher than
        the actual count.
        <BittsLink
          url="https://help.crossbeam.com/en/articles/8399864-record-export-limits"
          class="learn-more-link"
          target="_blank"
          text="Learn more"
      /></p>
    </template>
  </BittsTooltip>
</template>

<script setup lang="ts">
import { BittsButton, BittsLink, BittsTooltip } from '@crossbeam/bitts';

import { debounce } from 'lodash';
import { computed, ref, watch } from 'vue';

import useRecordExportLimits from '@/composables/useRecordExportLimits';
import useTrayStatus from '@/composables/useTrayStatus';
import { usePopulationsStore } from '@/stores';
import {
  IntegrationProfilePopulationSetting,
  IntegrationProfileStandardPopulationSetting,
} from '@/types/integration_profiles';

const { populations = [], standardPopulations = [] } = defineProps<{
  populations?: IntegrationProfilePopulationSetting[];
  standardPopulations?: IntegrationProfileStandardPopulationSetting[];
}>();

const { partnerPopulations } = usePopulationsStore();
const { getTotalOverlaps } = useTrayStatus();
const { exportLimit } = useRecordExportLimits();

const isTestMode = import.meta.env.MODE === 'test';

const defaultIncludedPartnerPopulations = computed<
  IntegrationProfilePopulationSetting[]
>(() =>
  partnerPopulations
    .filter((pop) => {
      const standardPop = standardPopulations.find(
        (standardPop) => standardPop.standard_type === pop.standard_type,
      );
      return (
        // excluding populations that are already included in the list, i.e. overwrites
        !populations.some(
          (populationSetting) => populationSetting.population_id === pop.id,
        ) &&
        (standardPop?.is_included ?? false)
      );
    })
    .map((pop) => ({
      population_id: pop.id,
      is_partner_population: true,
      is_included: true,
    })),
);

const totalOverlaps = ref<number | null>();
const updateTotalOverlaps = debounce(
  async (populations: IntegrationProfilePopulationSetting[]) => {
    totalOverlaps.value = await getTotalOverlaps(
      populations.filter((pop) => pop.is_included),
    );
  },
  isTestMode ? 0 : 500,
  {
    leading: true,
    trailing: true,
  },
);

const isWarningState = computed(() => {
  return (
    totalOverlaps.value != null &&
    exportLimit.value != null &&
    totalOverlaps.value > exportLimit.value
  );
});

watch(
  [() => populations, () => defaultIncludedPartnerPopulations.value],
  async ([newPopulations, newDefaultIncludedPartnerPopulations]) => {
    await updateTotalOverlaps([
      ...newPopulations,
      ...newDefaultIncludedPartnerPopulations,
    ]);
  },
);

const openHelpTab = () => {
  window.open(
    'https://help.crossbeam.com/en/articles/8399864-record-export-limits',
    '_blank',
  );
};
</script>

<style scoped lang="pcss">
:deep(.ant-tooltip) {
  @apply max-w-content;
}
.learn-more-link {
  @apply text-white font-extrabold;
}
.learn-more-link :deep(.bitts-link__icon) {
  @apply text-white;
}
</style>
