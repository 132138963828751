import { EmptyObject, Nullable } from '@crossbeam/types';

export const CUSTOMERS_STANDARD_TYPE = 'customers';
export const OPEN_OPPS_STANDARD_TYPE = 'open_opportunities';
export const PROSPECTS_STANDARD_TYPE = 'prospects';

export const STANDARD_POPULATIONS = [
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
  PROSPECTS_STANDARD_TYPE,
] as const;

export type StandardPopulationType =
  | typeof PROSPECTS_STANDARD_TYPE
  | typeof OPEN_OPPS_STANDARD_TYPE
  | typeof CUSTOMERS_STANDARD_TYPE;

type PopVersion = {
  id: number;
  is_active: boolean;
  first_processed_at?: string;
};

type BasePopulation = {
  id: number;
  name: string;
  description: Nullable<string>;
  organization_id: number;
  population_type: 'people' | 'companies';
  standard_type: Nullable<StandardPopulationType>;
};

export type PopulationStats = {
  population_id: number;
  organization_id: number;
  total_size: number;
  updated_at: string;
};

type Primitive = string | number | boolean | null;
export type Operand = Primitive | Primitive[];
export type Combinator = 'AND' | 'OR';

export type FilterPart = {
  source_field_id?: number;
  label: string;
  operator?: string;
  operand?: Operand;
  combinator?: Combinator;
};

type FilterExpression = string[];

export type FilterGroup = {
  filter_expression: FilterExpression;
  filter_parts: FilterPart[];
};

type SourceFilterExpressions = Record<string, FilterGroup>;

export type Population = {
  meta: EmptyObject | PopulationStats;
  source_id: number;
  base_schema: string;
  base_table: string;
  current_version: PopVersion;
  source_filter_expressions?: SourceFilterExpressions;
} & BasePopulation;

export type EmptyPopulation = Partial<Population>;

export type PartnerPopulation = BasePopulation;
