<template>
  <BittsSelect
    v-model="pickedCoreRole"
    class="c-select-core-role"
    form-label="Full Access Role"
    data-testid="core-role-selector"
    :disabled="!isCoreSeatSelected"
    :options="coreRoleOptions"
  >
    <template #placeholder>
      <span
        :class="[
          !isCoreSeatSelected
            ? 'text-neutral-text'
            : 'text-neutral-text-placeholder',
        ]"
        class="flex items-center gap-8"
      >
        <FontAwesomeIcon
          v-if="!isCoreSeatSelected"
          class="text-neutral-accent"
          :icon="['fak', 'hidden']"
        />
        <span data-testid="core-role-placeholder" class="mt-1">
          {{
            isCoreSeatSelected
              ? 'Select Full Access Role'
              : pickedSalesRoleDisplay()
          }}
        </span>
      </span>
    </template>
  </BittsSelect>
</template>

<script setup>
import { BittsSelect } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

import { TEMP_DEAL_DASHBOARD } from '@/constants/feature_flags';
import { SALES } from '@/constants/team';
import { CORE } from '@/constants/team_v4';
import { useFeatureFlagStore, useRolesStore } from '@/stores';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  pickedSeat: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);
const rolesStore = useRolesStore();
const featureFlagStore = useFeatureFlagStore();

const { allRoles, salesSeatRole } = storeToRefs(rolesStore);

const isCoreSeatSelected = computed(() => props.pickedSeat?.value === CORE);
const coreRoleOptions = computed(() =>
  allRoles.value
    .filter((role) => role.seat_type !== SALES || !isCoreSeatSelected.value)
    .map((role) => ({ value: role.id, label: role.name })),
);

const hasDealNav = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_DEAL_DASHBOARD),
);

const pickedCoreRole = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
function pickedSalesRoleDisplay() {
  return hasDealNav.value ? 'Sales Access' : 'No Access';
}
function selectedSalesRole() {
  return hasDealNav.value ? salesSeatRole.value.id : null;
}

/* When the seat type is changed, we also have to clear the
sales role if we choose a core role, if they had previously
been set to "no access" */
watch(isCoreSeatSelected, (isCore) => {
  if (isCore) {
    pickedCoreRole.value = props.initialCoreRole;
  } else {
    pickedCoreRole.value = selectedSalesRole();
  }
});
</script>
