import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { crossbeamApi } from '@/api';
import usePopulations from '@/composables/usePopulations';
import { MDM_TYPES, MdmType } from '@/constants/mdm';
import { validateAndConvertOperandDataTypes } from '@/constants/populations';
import {
  ARRAY_PAYLOAD_OPERATORS,
  DATETIME,
  EMPTY_OPERATORS,
  NUMBER,
} from '@/constants/reports';
import {
  emptyPopulation,
  getPopulationTypeName,
} from '@/constants/standard_populations';
import { captureException } from '@/errors';
import { SharedField } from '@/interfaces/data_shares';
import {
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import {
  Combinator,
  EmptyPopulation,
  FilterGroup,
  FilterPart,
  Operand,
  Population,
  StandardPopulationType,
} from '@/types/populations';
import { Source } from '@/types/sources';

import { FilterGroupType } from '../components/data-sources/SettingsModal/PopulationsTab/types';

export default function ({ feedId }: { feedId: number | null }) {
  const router = useRouter();
  const route = useRoute();
  const populationsStore = usePopulationsStore();
  const flashesStore = useFlashesStore();
  const {
    allPopulations,
    getRecordNumber,
    populationsReady,
    savePopulation,
    makePopulationPayload,
  } = usePopulations(null, null, feedId as never);

  const loading = ref(true);
  const filterableSources = ref<Source[]>([]);
  const filterGroupIds = ref<number[]>([]);
  const filterGroups = ref<Record<number, FilterGroupType>>({});

  const populationId = computed(() => route.params.population_id);
  const population = ref<Population | EmptyPopulation>({});
  const recordCount = computed<number | null>(
    () => getRecordNumber(population.value) || null,
  );
  const populationTypeName = computed(() => {
    return population.value?.standard_type
      ? getPopulationTypeName(population.value.standard_type)
      : '';
  });

  const remainingSources = computed(() => {
    return filterableSources.value
      .filter(
        (source: Source) =>
          !filterGroupIds.value.some(
            (id) => id.toString() === source.id?.toString(),
          ),
      )
      .map((source: Source) => {
        // need to convert it to the form that BittsDropdown expects
        return { value: source, display: source.table };
      });
  });

  const payload = computed(() =>
    makePopulationPayload(
      population.value,
      population.value.name ?? populationTypeName.value,
      formatFiltersForPayload(filterGroups.value),
      // @ts-expect-error - we know this is a standard population
      population.value?.standard_type,
    ),
  );

  onMounted(async () => {
    await populationsReady;
    initPopulation();
    if (!populationId.value) {
      initNewPopulation();
    }
    await loadSources();
    setFilterData();
    loading.value = false;
  });

  function initPopulation() {
    if (allPopulations.value && populationId.value) {
      const pop = allPopulations.value.find(
        (p) => p.id === Number(populationId.value),
      );
      population.value = pop;
    } else {
      population.value = emptyPopulation(
        route.params.type as StandardPopulationType,
      );
    }
  }

  function initNewPopulation() {
    const sourcesStore = useSourcesStore();
    if (!feedId) return;
    const sources = sourcesStore
      .getSourcesByFeedId(feedId)
      .filter((source) => source.is_base_table && source.sync_enabled);
    if (sources.length === 1) {
      population.value.source_id = sources[0]?.id;
    } else {
      console.log('TODO MANAGE MULTIPLE SOURCES');
    }
  }

  async function loadSources() {
    if (!population.value?.source_id) return;

    const { data, error } = await crossbeamApi.GET(
      '/v0.2/sources/{id}/filterable-sources',
      {
        params: {
          path: { id: population.value.source_id },
        },
      },
    );
    if (error) {
      flashesStore.addUnhandledError(new Error(error));
      return;
    }
    const forbiddenMdmTypes: MdmType[] = [MDM_TYPES.DEAL_CONTACT];
    filterableSources.value = uniqBy(data.items, 'id').filter(
      (source) => !forbiddenMdmTypes.includes(source.mdm_type),
    );
  }

  function setFilterData() {
    const filterExpressions = population.value?.source_filter_expressions;
    if (!filterExpressions) return;
    Object.keys(filterExpressions).forEach((table: string) => {
      const source = filterableSources.value.find((s) => s.table === table);
      const sourceId = source?.id;
      const filterExpression = filterExpressions[table]?.filter_expression;
      const filters: FilterPart[] = [];

      if (!sourceId) return;

      let combinator: string | null = null;
      filterExpression?.forEach((expressionItem: string) => {
        if (['AND', 'OR'].includes(expressionItem)) {
          combinator = expressionItem;
        } else {
          const associatedFilterPart = filterExpressions[
            table
          ]?.filter_parts.find((part) => part.label === expressionItem);
          if (associatedFilterPart) {
            if (combinator && associatedFilterPart) {
              associatedFilterPart.combinator = combinator as Combinator;
              combinator = null;
            }
            filters.push(associatedFilterPart);
          }
        }
      });
      filterGroups.value[sourceId] = { source, filters };
      filterGroupIds.value.push(sourceId);
    });
  }

  function addNewFilterGroup({
    source,
    field,
  }: {
    source: Source;
    field: SharedField;
  }) {
    const filter: FilterPart = {
      label: uuidv4(),
      source_field_id: field.id,
      operator: 'in',
    };
    addFilterGroup(source, filter);
  }

  function addFilterGroup(source: Source, filter: FilterPart) {
    const { id } = source;
    if (id) {
      filterGroups.value[id] = { source, filters: [filter] };
      filterGroupIds.value.push(id);
    }
  }

  function addFilterToGroup(sourceId: number, combinator: Combinator) {
    const label = uuidv4();
    filterGroups.value[sourceId]?.filters.push({
      label,
      combinator,
    });
  }

  function deleteGroup(sourceId: number) {
    const { [sourceId]: _, ...newFilterGroups } = filterGroups.value;
    filterGroups.value = newFilterGroups;
    filterGroupIds.value = filterGroupIds.value.filter(
      (oId) => oId !== sourceId,
    );
  }

  function deleteFilterFromGroup(sourceId: number, index: number) {
    const group = filterGroups.value[sourceId];
    if (!group) return;

    group.filters.splice(index, 1);

    if (!group.filters.length) deleteGroup(sourceId);
  }

  function updateFilter(
    sourceId: number,
    index: number,
    property: keyof FilterPart,
    value: unknown,
  ) {
    const group = filterGroups.value[sourceId];
    if (!group || !group.filters[index]) return;

    group.filters[index] = {
      ...group.filters[index],
      [property]: value,
    };

    filterGroups.value = { ...filterGroups.value };
  }

  async function handleSavePopulation() {
    if (!population.value.source_id) return;
    loading.value = true;
    try {
      population.value = await savePopulation(population.value, payload.value);

      await populationsStore.refreshPopulationsStore();

      /* New population */
      if (!populationId.value) {
        router.replace({
          name: 'edit-population',
          params: { population_id: population.value.id },
        });
        // Todo: redirect to sharing settings dialog when exists
      }
    } catch (err) {
      flashesStore.addErrorFlash({ message: 'Could not save population' });
      captureException(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    remainingSources,
    filterGroupIds,
    filterGroups,
    recordCount,
    populationTypeName,
    loading,
    addNewFilterGroup,
    addFilterToGroup,
    deleteGroup,
    deleteFilterFromGroup,
    updateFilter,
    handleSavePopulation,
  };
}

// Helpers

function isFilterIncomplete(filter: FilterPart) {
  const { source_field_id: id, operator, operand } = filter;
  if (!id || !operator) return true;
  // 0 and false are fine
  return (
    !EMPTY_OPERATORS.includes(operator) &&
    (operand === null || operand === undefined)
  );
}

function isFilterValid(filter: FilterPart, source: Source) {
  if (!source) return false;
  return source.fields.find((field) => field.id === filter.source_field_id);
}

function convertToValidNumber(input: Operand) {
  if (input !== null && input !== undefined && typeof input === 'string') {
    // remove commas
    return input.replace(/,/g, '');
  }
  return input;
}

function convertToValidDatetime(date: Date | string | null) {
  if (!date) return null;
  return DateTime.fromJSDate(new Date(date)).startOf('day').toISO();
}

function validateOperand(filter: FilterPart) {
  if (!filter.source_field_id || !filter.operand) return null;

  const field = useSourcesStore().getSourceFieldById(filter.source_field_id);
  let validOp: Operand = filter.operand;

  if (field?.data_type === DATETIME) {
    validOp = convertToValidDatetime(validOp as Date | string | null);
  } else if (field?.data_type === NUMBER) {
    validOp = convertToValidNumber(validOp);
  }

  const { convertedOperands } = validateAndConvertOperandDataTypes(
    field?.data_type,
    validOp,
    filter.operator,
  );

  return ARRAY_PAYLOAD_OPERATORS.includes(String(filter.operator)) &&
    !Array.isArray(convertedOperands)
    ? [convertedOperands]
    : convertedOperands;
}

function formatFiltersForPayload(
  filterGroups: Record<number, FilterGroupType>,
): FilterGroup[] {
  return Object.entries(filterGroups)
    .map(([_, group]) => {
      if (!group?.source || !group.filters) return null;

      const { source, filters: groupFilters } = group;

      // Build the filter expression and the list of formatted filters
      const { filterExpr, filterParts } = groupFilters.reduce<{
        filterExpr: string[];
        filterParts: FilterPart[];
      }>(
        (acc, item) => {
          if (isFilterIncomplete(item) || !isFilterValid(item, source)) {
            return acc;
          }

          // Add combinator (ex: AND/OR) if it's not the first filter
          if (acc.filterParts.length > 0 && item.combinator) {
            acc.filterExpr.push(item.combinator);
          }

          // Add filter identifier
          acc.filterExpr.push(item.label);

          // Add formatted filter to the list
          acc.filterParts.push({
            label: item.label,
            operator: item.operator,
            operand: validateOperand(item),
            source_field_id: item.source_field_id,
          });
          return acc;
        },
        { filterExpr: [], filterParts: [] },
      );

      if (filterParts.length === 0) return null;

      return {
        tableName: source.table,
        filter_expression: filterExpr,
        filter_parts: filterParts,
      };
    })
    .filter((filter) => filter !== null);
}
