<template>
  <BittsModal
    :visible="true"
    class="c-onboarding-invite-modal"
    save-text="Invite team"
    :loading="!ready"
    :width="600"
    :disabled="!hasValidInvites || saving"
    :saving="saving"
    @closed="handleClose"
    @saved="handleSubmit"
  >
    <template #content>
      <div class="mt-[-24px]">
        <BittsAvatar
          :org="currentOrg"
          :show-initials="true"
          size="large"
          shape="square"
        />
        <h1
          class="text-neutral-text-strong text-xl font-bold text-center mb-8 mt-24"
        >
          Invite your teammates
        </h1>
      </div>
      <p
        data-testid="coupon-modal-discount"
        class="text-neutral-text text-m text-center px-16 mb-40"
      >
        Partnerships are always better together
      </p>
      <div class="form-inputs">
        <InviteInput :labels="true" class="mb-16" @invite="updateInvite" />
        <InviteInput :number="2" @invite="updateInvite" />
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsAvatar, BittsModal } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import InviteInput from '@/components/onboarding/InviteInput.vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { captureException } from '@/errors';
import {
  allReady,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';

const rolesStore = useRolesStore();
const flashesStore = useFlashesStore();
const teamStore = useTeamStore();

const { allRoles } = storeToRefs(rolesStore);
const ready = allReady(rolesStore, teamStore);

const { iteratively } = useIteratively();
const { currentOrg } = useAuth();

const roleOptions = computed(() =>
  allRoles.value.map((role) => ({ value: role.id, label: role.name })),
);

const router = useRouter();
const route = useRoute();

function handleClose() {
  router.back();
}

/* Update invites in the map, and compute validity */
const invites = ref({});
const hasValidInvites = computed(() => {
  if (Object.keys(invites.value).length === 0) return false;
  return Object.values(invites.value).every((invite) => invite.valid);
});

function updateInvite(invite) {
  invites.value[invite.number] = invite;
}

const saving = ref(false);
function handleSubmit() {
  if (!hasValidInvites.value) return;

  const invitesToSend = Object.values(invites.value).map((invite) => ({
    email: invite.email,
    role_id: invite.role,
    sales_edge_role: null,
    purpose: 'user',
  }));

  try {
    saving.value = true;
    const { error } = crossbeamApi.POST('/v0.2/user-invites', {
      body: { invites: invitesToSend },
    });
    if (error) throw new Error(error);
    flashesStore.addSuccessFlash({
      message: `${pluralize(invitesToSend.length, 'Invitation')} sent`,
    });
    invitesToSend.forEach(({ email, role_id: roleId }) => {
      const iterativelyPayload = {
        teammate_email: email,
        role: roleOptions.value.find(({ value }) => value === roleId).label,
        seat_type: 'core',
        invite_location: route.name,
        event_site: EVENT_SITES.ONBOARDING_INVITE_MODAL,
      };
      iteratively.userInvitesTeammate(iterativelyPayload);
    });
    teamStore.refreshTeamStore();
  } catch (err) {
    captureException(err);
    flashesStore.addUnhandledError(err);
  } finally {
    router.back();
    saving.value = false;
  }
}
</script>
<style lang="pcss">
.c-onboarding-invite-modal {
  .ant-modal-body {
    background: radial-gradient(
      136.96% 69.92% at 50.09% -22.42%,
      theme(colors.info.border / 0.3) 0%,
      theme(colors.info.border / 0) 100%
    );
    @apply rounded-bts-xl;
  }

  footer.c-bitts-modal__buttons > div {
    @apply justify-between !important;
  }
}
</style>
