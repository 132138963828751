import { GetApiPayloadByUrl, GetApiResponseByUrl } from '@crossbeam/openapi';
import { Nullable } from '@crossbeam/types';

import { crossbeamApi } from '@/api';
import { Population } from '@/types/populations';
import { Source, SourceField } from '@/types/sources';

export const FILE_UPLOAD_DATA_SOURCE_TYPE = 'file_upload';
export const GOOGLE_SHEETS_DATA_SOURCE_TYPE = 'google_sheets';
export const PIPEDRIVE_DATA_SOURCE_TYPE = 'pipedrive';
export const HUBSPOT_DATA_SOURCE_TYPE = 'hubspot';
export const HS3_DATA_SOURCE_TYPE = 'hubspot_v3';
export const SALESFORCE_DATA_SOURCE_TYPE = 'salesforce';
export const SNOWFLAKE_DATA_SOURCE_TYPE = 'snowflake';
export const SNOWFLAKE_INTEGRATION_PREFIX = 'snowflake';
export const MD_DATA_SOURCE_TYPE = 'microsoft_dynamics';

export const SALESFORCE_INTEGRATION_FRIENDLY_NAME = 'Salesforce';
export const SNOWFLAKE_INTEGRATION_FRIENDLY_NAME = 'Snowflake';

/**
 * @returns { Record<TDataSourceType, string> }
 */
export const DataSourceFriendlyName = {
  [SALESFORCE_DATA_SOURCE_TYPE]: 'Salesforce',
  [HS3_DATA_SOURCE_TYPE]: 'Hubspot',
  [SNOWFLAKE_DATA_SOURCE_TYPE]: 'Snowflake',
  [PIPEDRIVE_DATA_SOURCE_TYPE]: 'Pipedrive',
  [MD_DATA_SOURCE_TYPE]: 'Microsoft Dynamics',
  [GOOGLE_SHEETS_DATA_SOURCE_TYPE]: 'Google Sheets',
  [FILE_UPLOAD_DATA_SOURCE_TYPE]: 'CSV File',
};

export const MAX_SALESFORCE_CONNECTIONS = 3;
export const RECORD_LIMIT_THRESHOLD = 1000;
export const SYNC_FEED_INTERVAL = 10000;
export const DELETE_FEED_INTERVAL = 10000;
export const MAX_FIELDS = 100;

export const DATA_TEMPLATES_CRMS = [
  SALESFORCE_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
];
// We have Hubspot feeds converted into HS3
// Those sources don't have 'hubspot_v3_...' schema but have 'hubspot_...' schema instead.
export const DEAL_CAPABLE_CRMS = [
  SALESFORCE_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
];

/* Possible statuses for a source */
export const SOURCE_STATUS_MAP = {
  process: ['PROCESSING', 'SCHEDULED_TO_PROCESS'],
  done: ['PROCESSED'],
  error: ['ERROR', 'PROCESSING_FAILED', 'COLUMN_ERRORS'],
  warning: ['DUPLICATE_ROWS'],
  deleting: ['DELETING'],
  missing: ['UNAUTHORIZED'],
};

/* The possible statuses for a feed */
export const REQUIRED_FEED_STATUS = 'REQUIRE_SOURCES_SELECTION';
export const PENDING_FEED_STATUS = 'OAUTH_COMPLETE';
export const ACTIVE_FEED_STATUS = 'CONFIGURED';
export const CONNECTION_CHECK_ERROR_STATUS = 'CONN_CHECK_FAILED';
export const RECORD_LIMIT_EXCEEDED_STATUS = 'RECORD_LIMIT_EXCEEDED';
export const SOURCE_FAILED_STATUS = 'SOURCE_FAILED';
export const DELETING_FEED_STATUS = 'DELETING';
export const DELETION_FAILED_FEED_STATUS = 'DELETION_FAILED';

export const COMPLETED_STATUSES = [PENDING_FEED_STATUS, ACTIVE_FEED_STATUS];
export const WARNING_STATUSES = [SOURCE_FAILED_STATUS];

export const LOOKUP_FIELD = 'lookup-field';
export const SOURCE_FIELD = 'source-field';
export const SOURCE = 'source';

export const ALL_FIELDS = 'all_fields';
export const SELECTED_FIELDS = 'selected_fields';

export const LOOKUP_PARENT = 'lookup_parent';
export const CONTAINS_LOOKUPS = 'contains_lookups';
export const CONTAINS_COPIED = 'contains_copied';

export const SYNCED = 'synced';
export const UNSYNCED = 'unsynced';
export const UNSYNCED_DISABLED = 'unsynced=disabled';
export const CANNOT_UNSYNC = 'cannot-unsync-field';
export const CANNOT_UNSYNC_PLURAL = 'cannot-unsync-fields';

const getLookupFieldString = (list: { copyingFieldName: string }[]) => {
  let str = '';
  list.forEach((item) => {
    str += ` ${item.copyingFieldName},`;
  });
  return str;
};

export const getIconTooltipText = (
  linkedSource: Nullable<SourceField>,
  messageType:
    | typeof LOOKUP_PARENT
    | typeof CONTAINS_COPIED
    | typeof CONTAINS_LOOKUPS,
) => {
  /*
  LOOKUP_PARENT is Source but source does not have a table property
  CONTAINS_LOOKUPS is null
  CONTAINS_COPIED is SourceField
  */

  switch (messageType) {
    case LOOKUP_PARENT:
      return `This field is linked to the ${linkedSource?.display_name} object`;
    case CONTAINS_COPIED:
    case CONTAINS_LOOKUPS:
      return 'Fields in this object include lookup fields';
  }
};

type CopyingField = SourceField & {
  copyingId: number;
  copyingSourceId: number;
  copyingRelId: number;
  copyingFieldName: string;
  initiallyChecked: boolean;
};
type Message =
  | typeof SYNCED
  | typeof UNSYNCED
  | typeof UNSYNCED_DISABLED
  | typeof CANNOT_UNSYNC
  | typeof CANNOT_UNSYNC_PLURAL;
export const getFieldTooltipText = (
  fieldOrFields: CopyingField | CopyingField[],
  source: Source,
  messageType: Message,
) => {
  const isArray = Array.isArray(fieldOrFields);
  switch (messageType) {
    case SYNCED:
      return !isArray
        ? `This field is synced to the ${fieldOrFields.display_name} field in the ${source.table} object`
        : '';
    case UNSYNCED:
      // no tooltip for UNSYNCED
      return null;
    case UNSYNCED_DISABLED:
      return !isArray
        ? `Select ${fieldOrFields.display_name} to enable this field`
        : '';
    case CANNOT_UNSYNC:
      return isArray
        ? `Unsync lookup field ${fieldOrFields[0]?.copyingFieldName} in order to unsync this field.`
        : '';
    case CANNOT_UNSYNC_PLURAL:
      return isArray
        ? `Unsync lookup fields${getLookupFieldString(fieldOrFields)} in order to unsync this field.`
        : '';
  }
};

export const getKey = ({
  type,
  sourceId,
  fieldId = null,
  relId = null,
}: {
  type: string;
  sourceId: number;
  fieldId?: number | null;
  relId?: number | null;
}) => {
  let key;
  if (fieldId) {
    key = `${type}__${fieldId}__${sourceId}`;
  } else {
    key = `${type}__${sourceId}`;
  }
  if (relId) {
    key += `__${relId}`;
  }
  return key;
};

export function isInPopDefinition(pop: Population, fieldId: number) {
  if (!pop.source_filter_expressions) return false;
  return Object.values(pop.source_filter_expressions)
    .map((expr) => expr.filter_parts)
    .flat()
    .find((fp) => fp.source_field_id === fieldId);
}

/* Returns the required columns for a given feed, per each source */
/* We may have to incorporate logic in here to account for fields from the template */
export async function getRequiredColumns(
  feed: GetApiResponseByUrl<'/v0.1/feeds'>['items'][number],
) {
  const dataSourceType = feed.integration.type;

  const { data, error } = await crossbeamApi.GET(
    '/v0.2/integrations/{integration_type}/required-columns',
    {
      params: {
        path: {
          integration_type: dataSourceType,
        },
      },
    },
  );
  if (error) throw new Error(error);
  return data?.reduce(
    (acc, { table, columns }) => ({ ...acc, [table]: columns }),
    {},
  );
}

export function isValidSourceField(sourceField: SourceField) {
  return (
    !sourceField.column.startsWith('_sdc_') &&
    !sourceField.syncing_disabled_reason
  );
}

type SourceFieldsPayload = GetApiPayloadByUrl<
  '/v0.2/feeds/{id}/source-fields',
  'patch'
>;
export async function patchSources({
  feedId,
  sources,
  fields,
}: {
  feedId: number;
  sources: SourceFieldsPayload['sources'];
  fields: SourceFieldsPayload['fields'];
}) {
  const { error } = await crossbeamApi.PATCH('/v0.2/feeds/{id}/source-fields', {
    params: {
      path: { id: feedId },
    },
    body: {
      sources,
      fields,
    },
  });
  if (error) {
    console.error(
      `Error while trying to update sources for feedId ${feedId}`,
      error,
    );
    throw new Error(error);
  }
}

export async function patchFeed(
  feedId: number,
  payload: GetApiPayloadByUrl<'/v0.1/feeds/{id}', 'patch'>,
) {
  const { error } = await crossbeamApi.PATCH('/v0.1/feeds/{id}', {
    params: {
      path: { id: feedId },
    },
    body: payload,
  });
  if (error) throw new Error(error);
}

export function isGoogleOrCsvSource(type: string) {
  return (
    type === GOOGLE_SHEETS_DATA_SOURCE_TYPE ||
    type === FILE_UPLOAD_DATA_SOURCE_TYPE
  );
}
