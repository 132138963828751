import { sortBy, values } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { ROLE_TYPE_ORDER } from '@/constants/team';
import { initStore } from '@/stores/store-utils';
import { Role } from '@/types/roles';
import { indexBy } from '@/utils';

export const useRolesStore = defineStore('RolesStore', () => {
  type RoleLookup = Record<string, Role>;

  const rolesById = ref<RoleLookup>({});

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data, error } = await crossbeamApi.GET('/v0.1/roles');
      if (error) throw error;
      rolesById.value = indexBy(data?.items, 'id');
    },
  );

  refresh({ useCache: true });

  const allRoles = computed(
    () =>
      sortBy(values(rolesById.value), (role) => {
        const orderKey =
          ROLE_TYPE_ORDER[role.role_type as keyof typeof ROLE_TYPE_ORDER];
        return orderKey !== undefined ? orderKey : `4-${role.name}`;
      }) || [],
  );
  const salesSeatRole = computed(() =>
    values(rolesById.value).find((r) => r.seat_type === 'sales'),
  );
  const adminRole = computed(() =>
    values(rolesById.value).find((r) => r.role_type === 'admin'),
  );

  const allRolesWithIsCustom = computed(() => {
    const rolesWithIsCustom = values(rolesById.value).map((role) => {
      return {
        ...role,
        is_custom: role.is_editable && role.role_type === 'custom',
      };
    });
    return (
      sortBy(rolesWithIsCustom, (role) => {
        const orderKey =
          ROLE_TYPE_ORDER[role.role_type as keyof typeof ROLE_TYPE_ORDER];
        return orderKey !== undefined ? orderKey : `4-${role.name}`;
      }) || []
    );
  });

  function getRoleByName(name: string) {
    return values(rolesById.value).find((r) => r.name === name);
  }
  function getRoleById(id: string) {
    return rolesById.value[id];
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    rolesById,
    adminRole,
    salesSeatRole,
    allRoles,
    allRolesWithIsCustom,
    refreshRolesStore: refresh,
    getRoleByName,
    getRoleById,
  };
});
