<template>
  <BittsDrawer
    :visible="showDrawer"
    :title="title"
    :primary-button-text="saveButtonText"
    :is-saving="isSaving || isDeleting"
    :is-saving-disabled="
      isReauthorizing || isDeleting || isConfiguringTray || shouldGateCopilot
    "
    @action:primary="saveIntegrationSettings"
    @closed="onDrawerClosed"
  >
    <template #headerAvatar>
      <BittsAvatar
        :org="avatarLogo"
        shape="square"
        size="small"
        data-testid="copilot-settings-avatar"
        :is-entity="true"
      />
    </template>

    <template #content>
      <section class="p-16 border-b border-neutral-border">
        <IntegrationCardHeader
          v-if="showStatus"
          :statuses="realOrFakeStatuses"
          :status-type="statusType"
          :callout-text="integrationType === 'gong' ? calloutText : undefined"
          :version
          :created
          :auth-by
          :disabled="!hasPermissionToManageIntegrations"
          :loading="isReauthorizing"
          :hide-reauth="integrationType === 'hubspot'"
          :hide-advanced-settings="integrationType === 'salesforce'"
          @reauthorize="onReauthorize"
        />
        <BittsCallout
          v-else
          action-text="Install"
          type="info"
          :icon="['fak', 'ecosystem-intelligence']"
          title="Make sure this integration is installed"
          subtitle="This integration uses a different system, check the status here"
          @bitts-callout-action="onCalloutClick"
          size="small"
        />
      </section>
      <div v-if="hasPermissionToManageIntegrations" class="copilot_drawer">
        <BittsLoading :is-loading="isLoading">
          <div class="px-16">
            <div class="flex items-center gap-8">
              <h4 class="text-neutral-text-strong font-bold text-base">
                Control what data shows in Copilot
              </h4>
              <BillingCTA
                v-if="isFreeTier || shouldGateCopilot"
                size="x-small"
                data-testid="crossbeam-copilot-settings-cta"
                :billing-interaction="billingCta"
                :route-to="{ name: 'integrations' }"
              />
            </div>
            <p class="text-sm text-neutral-text">
              This will affect all instances of Copilot and not count towards
              your record export limit
            </p>
            <IntegrationSettings
              :population-settings="integrationProfileSettings"
              setting-type="crossbeam_copilot"
              @save:changes="onSettingsUpdated"
              @update:exclude-partnerships-created-since="
                onUpdateExcludePartnershipsCreatedSince
              "
              :is-disabled="isFreeTier || shouldGateCopilot"
            />
          </div>
        </BittsLoading>
      </div>
    </template>
    <template #secondaryButton v-if="gongInfo">
      <BittsButton
        text="Delete integration"
        :left-icon="['fak', 'delete']"
        variant="ghost"
        class="w-1/2"
        type="danger"
        :loading="isDeleting || isReauthorizing || isSaving"
        @click="showDeleteModal = true"
        :disabled="isSaving"
      />
    </template>
  </BittsDrawer>
  <BittsModalTwo
    v-if="showDeleteModal && !!gongInfo"
    variant="confirm"
    title="Delete Integration"
    primary-button-type="danger"
    primary-button-text="Delete"
    description="Are you sure you want to delete the Gong integration?"
    @closed="showDeleteModal = false"
    @action="deleteIntegration(gongInfo)"
  />
</template>
<script setup lang="ts">
import {
  BittsAvatar,
  BittsButton,
  BittsCallout,
  BittsDrawer,
  BittsLoading,
  BittsModalTwo,
} from '@crossbeam/bitts';
import { EVENT_SITES, INTEGRATIONS_CTAS } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useIntegrationProfile from '@/composables/useIntegrationProfile';
import useIntegrations from '@/composables/useIntegrations';
import { useRouterWrapper } from '@/composables/useRouterWrapper';
import useTrayStatus from '@/composables/useTrayStatus';
import { TEMP_CONNECTOR_TRIAL } from '@/constants/feature_flags';
import { PCLOUD_INTEGRATIONS } from '@/constants/integrations';
import {
  useBillingStore,
  useConnectionsStore,
  useFeatureFlagStore,
  useFlashesStore,
  usePartnerCloudStore,
} from '@/stores';
import { EnabledTray, TrayIntegrationHeaderStatus } from '@/types/integrations';

import IntegrationCardHeader from './IntegrationCardHeader.vue';
import IntegrationSettings from './IntegrationSettings.vue';

const showDrawer = ref(true);
const isDeleting = ref(false);
const isReauthorizing = ref(false);
const showDeleteModal = ref(false);
const { pushWithExistingQuery, route } = useRouterWrapper();

const partnerCloudStore = usePartnerCloudStore();
const flashesStore = useFlashesStore();
const billingStore = useBillingStore();
const connectionStore = useConnectionsStore();
const { isFreeTier } = storeToRefs(billingStore);

type Integration = 'salesforce' | 'gong' | 'hubspot' | 'outreach' | 'chrome';
const integrationType = computed<Integration>(
  () => route.params.integration as Integration,
);

const {
  clearProfilesState,
  friendlyName,
  hasPermissionToManageIntegrations,
  integrationProfileSettings,
  isLoading,
  isSaving,
  onSettingsUpdated,
  onUpdateExcludePartnershipsCreatedSince,
  saveSettings,
} = useIntegrationProfile('crossbeam_copilot');

const showStatus = computed(() =>
  ['gong', 'hubspot', 'salesforce'].includes(integrationType.value),
);
const isSalesforceCopilot = computed(
  () => integrationType.value === 'salesforce',
);

const ffStore = useFeatureFlagStore();

const hasConnectorTrial = computed(() =>
  ffStore.hasFeatureFlag(TEMP_CONNECTOR_TRIAL),
);

const { isOnFreeTrial } = storeToRefs(billingStore);

const shouldGateCopilot = computed(
  () =>
    hasConnectorTrial.value && isOnFreeTrial.value && isSalesforceCopilot.value,
);

const salesforceConnection = computed(() =>
  connectionStore.getConnectionByType('salesforce'),
);
const version = computed(() => {
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.config?.app_version
    : undefined;
});

const created = computed(() => {
  if (hubspotInfo.value) {
    return hubspotInfo.value.installDate ?? undefined;
  }
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.connected_at
    : undefined;
});

const authBy = computed(() => {
  if (hubspotInfo.value) {
    return hubspotInfo.value?.installUser.email;
  }
  return isSalesforceCopilot.value
    ? salesforceConnection?.value?.connected_by_user_email
    : undefined;
});

const { allEnabledIntegrations } = useIntegrations();

const gongInfo = computed(() =>
  integrationType.value === 'gong'
    ? allEnabledIntegrations.value.find((i) => i.type === 'tray_gong')
    : undefined,
);

const hubspotInfo = computed(() =>
  integrationType.value === 'hubspot'
    ? partnerCloudStore.enabledSalesEdge.find(
        ({ type }) => type === 'salesedge-hubspot',
      )
    : null,
);

const {
  statusType,
  calloutText,
  reauthorizeTray,
  statuses,
  isConfiguringTray,
} = useTrayStatus(gongInfo);

const realOrFakeStatuses = computed<TrayIntegrationHeaderStatus[]>(() => {
  if (integrationType.value === 'hubspot') {
    return [
      {
        type: 'success',
        text: 'Active',
        name: 'HubSpot',
      },
    ];
  }
  return integrationType.value === 'gong' ? statuses.value : [];
});

const integration = computed(() => route.params.integration);
const billingCta = computed(() => {
  const integrationType =
    typeof integration.value === 'string'
      ? integration.value.toUpperCase()
      : '';
  return {
    cta: INTEGRATIONS_CTAS[
      `COPILOT_FOR_${integrationType}` as keyof typeof INTEGRATIONS_CTAS
    ],
    event_site:
      EVENT_SITES[
        `CROSSBEAM_COPILOT_SETTINGS_${integrationType}` as keyof typeof EVENT_SITES
      ],
  };
});

const basicInfo = computed(() =>
  PCLOUD_INTEGRATIONS.find((i) => {
    let searchType;
    switch (integrationType.value) {
      case 'gong':
        searchType = 'tray_gong';
        break;
      case 'hubspot':
        searchType = 'salesedge-hubspot';
        break;
      default:
        searchType = integrationType.value;
    }
    return i.type === searchType;
  }),
);

const title = computed(() => `Crossbeam Copilot for ${friendlyName.value}`);

const avatar = computed(() => basicInfo.value?.avatar);
const avatarLogo = computed(() => {
  return avatar.value
    ? {
        logo_url: new URL(
          `../../assets/pngs/partner-cloud/${avatar.value}`,
          import.meta.url,
        ).href,
      }
    : null;
});

const saveButtonText = computed(() => {
  if (isFreeTier.value) return 'Close';
  return isSaving.value ? 'Saving changes' : 'Save changes';
});

function onCalloutClick() {
  window.open(basicInfo.value?.learnMore, '_blank');
}

async function onReauthorize() {
  isReauthorizing.value = true;
  if (isSalesforceCopilot.value) {
    connectionStore.reauthSalesforce();
  }
  if (reauthorizeTray) await reauthorizeTray();
  isReauthorizing.value = false;
}

async function saveIntegrationSettings() {
  if (isFreeTier.value) {
    onDrawerClosed();
    return;
  }

  await saveSettings();
  onDrawerClosed();
}

async function onDrawerClosed() {
  showDrawer.value = false;
  clearProfilesState();
  await pushWithExistingQuery({ name: 'integrations' });
}

const deleteIntegration = async (info: EnabledTray) => {
  try {
    isDeleting.value = true;
    showDeleteModal.value = false;
    await partnerCloudStore.deleteIntegration(info);
  } catch (err) {
    flashesStore.addErrorFlash({
      message: 'Failed to delete integration',
    });
  } finally {
    isDeleting.value = false;
  }
  await pushWithExistingQuery({ name: 'integrations' });
};
</script>

<style lang="pcss">
.copilot_drawer {
  @apply py-16;
}
</style>
