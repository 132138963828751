import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { FileUpload } from '@/types/file_uploads';
import urls from '@/urls';

import { initStore } from './store-utils';

export const useFileUploadsStore = defineStore('FileUploads', () => {
  const currentUploads = ref<FileUpload[]>([]);
  const currentUploadNames = ref<string[]>([]);
  const uploadTables = ref<string[]>([]);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const [tablesResponse, fileUploadsResponse] = await Promise.all([
        axios.get(urls.fileUploads.tables),
        axios.get(urls.fileUploads.all),
      ]);
      currentUploads.value = fileUploadsResponse.data.items;
      const currentTableNames = currentUploads.value.map(
        ({ table_name: tableName }) => tableName,
      );
      currentUploadNames.value = [...new Set(currentTableNames)];
      uploadTables.value = tablesResponse.data.items;
    },
  );

  refresh({ useCache: true });

  function getByCSVName(name: string) {
    return uploadTables.value.find(
      (fileName) => fileName.trim().toLowerCase() === name.trim().toLowerCase(),
    );
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    refreshFileUploadsStore: refresh,
    currentUploads,
    uploadTables,
    currentUploadNames,
    getByCSVName,
  };
});
