import { EVENT_SITES, PLAN_AND_BILLING_CTAS } from '@crossbeam/itly';

import { pluralize } from 'humanize-plus';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import { useConnectorTrialEndDate } from '@/composables/useConnectorTrialEndDate';
import useHasFeature from '@/composables/useHasFeature';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import appConfig from '@/config';
import {
  ACTIVE,
  CANCELLED,
  CONNECTOR_TRIAL,
  LIMIT_REACHED,
  OVER_90,
  PAST_DUE,
  TRIAL_ENDED,
} from '@/constants/billing';
import {
  TEMP_EXEC_PARTNER_SPONSOR,
  TEMP_LEGACY_FREE,
} from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  useFeedsStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';

export default function () {
  const billingStore = useBillingStore();
  const ffStore = useFeatureFlagStore();
  const teamStore = useTeamStore();
  const feedsStore = useFeedsStore();

  const {
    isFreeTier,
    isSubscriptionPastDue,
    isSubscriptionCancelled,
    subscriptionRenewalDate,
    isConnectorTier,
    chargifyData,
    isOnFreeTrial,
  } = storeToRefs(billingStore);

  const { debookingEnabled, onLatestBilling } = useHasFeature();
  const { isAdminPanelUser, currentOrg } = useAuth();
  const { authorizations } = storeToRefs(teamStore);
  const { feedNamesReadyForPreview } = storeToRefs(feedsStore);
  const route = useRoute();
  const router = useRouter();

  /* Admin Alert: Those who are oauth'ed into production as an admin */
  const adminAlert = computed(() =>
    isAdminPanelUser.value && appConfig.env === 'prod'
      ? {
          message: `You are logged into production (${currentOrg.value.name}) as an admin!`,
          type: 'admin',
        }
      : null,
  );

  /* Feed Alert */
  const feedAlert = computed(() => {
    if (feedNamesReadyForPreview.value.length > 0) {
      return {
        // TODO: Need product to validate icon/message/type
        icon: ['fak', 'timer'],
        message: `You have ${feedNamesReadyForPreview.value.length} ${pluralize(feedNamesReadyForPreview.value.length, 'feed')} currently in preview: ${feedNamesReadyForPreview.value.join(', ')}.`,
        type: 'info',
      };
    }
    return null;
  });

  /* Billing Alert (Overdue Alert) */
  const billingAlert = computed(() => {
    if (isSubscriptionPastDue.value) {
      const billingDate = DateTime.fromISO(
        chargifyData.value?.open_invoice_issue_date,
      );
      const cancellationDate = billingDate
        .plus({ days: 30 })
        .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
      return {
        type: PAST_DUE,
        buttonText: 'Update billing',
        message: `Your payment failed, we will try the card again until ${cancellationDate} and then suspend your account`,
        path: 'edit-payment',
      };
    }

    const store = useRootStore();
    const currentAuth = store.currentAuth;
    const currentUser = store.currentUser;
    const isAdmin =
      currentAuth?.role?.name === 'Admin' || import.meta.env.MODE === 'test';

    const auth = authorizations.value.find(
      (a) => a.user?.id === currentUser.id,
    );
    const isRetainingAccess = !auth?.losing_access;

    const warningAlertTemplate = {
      icon: ['fak', 'timer'],
      type: CANCELLED,
    };

    if (
      isSubscriptionCancelled.value &&
      onLatestBilling.value &&
      debookingEnabled.value
    ) {
      warningAlertTemplate.message = `Your plan will be cancelled and you will lose access to paid features on ${subscriptionRenewalDate.value}`;

      /* Only admins can restore a plan */
      if (isAdmin) {
        warningAlertTemplate.buttonText = 'Restore plan';
        warningAlertTemplate.path = 'restore-plan';
      }
      return warningAlertTemplate;
    }

    /* Folks who are not on latest billing cannot restore after a cancellation */
    if (isSubscriptionCancelled.value && debookingEnabled.value) {
      let msg = `Your plan will be cancelled and you will lose access to paid features on ${subscriptionRenewalDate.value}.`;
      msg +=
        isAdmin && isRetainingAccess
          ? ' After that you can upgrade again.'
          : ' Contact your admin with any questions.';
      warningAlertTemplate.message = msg;
      return warningAlertTemplate;
    }

    if (isConnectorTier.value && debookingEnabled.value && isAdmin) {
      return {
        message: `You are in your renewal period. Confirm your plan details and make any changes before ${subscriptionRenewalDate.value}`,
        type: ACTIVE,
        buttonText: 'Learn more',
        path: 'billing',
      };
    }
    return null;
  });

  /* Warning to free tier users that after 180 days */
  const legacyFreeModalVisible = ref(false);

  const hasLegacyFree = computed(() =>
    ffStore.hasFeatureFlag(TEMP_LEGACY_FREE),
  );
  const hasPartnerSponsorshipFlag = computed(() =>
    ffStore.hasFeatureFlag(TEMP_EXEC_PARTNER_SPONSOR),
  );
  const legacyFreeAlert = computed(() => {
    if (
      hasPartnerSponsorshipFlag.value ||
      !hasLegacyFree.value ||
      !isFreeTier.value
    )
      return null;
    return {
      icon: ['fak', 'timer'],
      message: `Heads up, your Explorer plan is changing July 1, 2025`,
      type: ACTIVE,
      buttonText: 'Learn more',
      showModal: true,
    };
  });

  const { hasConnectorTrial, daysLeftText, isTrialOver } =
    useConnectorTrialEndDate();

  /* You are on free trial! */
  const freeTrialAlert = computed(() => {
    if (!hasConnectorTrial.value || !isOnFreeTrial.value || isTrialOver.value)
      return null;
    return {
      message: `You’re currently on a Connector free trial. You have ${daysLeftText.value} remaining before it ends`,
      type: CONNECTOR_TRIAL,
      buttonText: 'Upgrade to Connector now',
    };
  });

  /* Your free trial has expired */
  const freeTrialEndedAlert = computed(() => {
    if (!hasConnectorTrial.value || !isTrialOver.value) return null;
    return {
      icon: ['fak', 'warning'],
      message:
        'Your free trial has ended - upgrade now to retain your Connector level access',
      type: TRIAL_ENDED,
      buttonText: 'Upgrade',
    };
  });

  /* Export Limit Alert (For export limits being reached) */
  const { exportLimitStatus, RECORD_EXPORT_ALERTS } = useRecordExportLimits();
  const exportAlertAndOrgId = computed(
    () => `${exportLimitStatus.value}__${currentOrg.value.id}`,
  );
  const dismissedLimitAlerts = ref([]);

  const showExportLimitAlert = computed(() => {
    if (dismissedLimitAlerts.value.includes(exportAlertAndOrgId.value))
      return false;
    return [OVER_90, LIMIT_REACHED].includes(exportLimitStatus.value);
  });

  watch(exportAlertAndOrgId, (newVal) => {
    const exportLimitAlertDismissal = window.localStorage?.getItem(newVal);
    if (
      exportLimitAlertDismissal &&
      exportLimitAlertDismissal === 'dismissed'
    ) {
      dismissedLimitAlerts.value.push(exportAlertAndOrgId.value);
    }
  });

  /* Handlers for events from alerts */
  function onDismissAlert(type) {
    if ([LIMIT_REACHED, OVER_90].includes(type)) {
      window.localStorage?.setItem(exportAlertAndOrgId.value, 'dismissed');
      dismissedLimitAlerts.value.push(exportAlertAndOrgId.value);
    }
  }

  const { handleBillingInteraction } = useBilling();
  async function onAlertClicked({ type, alert }) {
    if ([LIMIT_REACHED, OVER_90].includes(type)) {
      handleBillingInteraction({
        cta: PLAN_AND_BILLING_CTAS.RECORD_EXPORT_LIMIT,
        talkToSalesReason: 'Record Export limit',
        event_site: EVENT_SITES.BILLING_ALERT_BANNER,
      });
    }
    if (alert?.path)
      await router.push({ name: alert?.path, query: { cancel: route.name } });
    if (alert?.showModal) legacyFreeModalVisible.value = true;
    if ([CONNECTOR_TRIAL, TRIAL_ENDED].includes(type)) {
      handleBillingInteraction({
        cta: PLAN_AND_BILLING_CTAS.FREE_TRIAL_BANNER,
        event_site: EVENT_SITES.FREE_TRIAL_BANNER,
      });
    }
  }

  const allAlerts = computed(() => {
    const alerts = [];
    if (billingAlert.value) alerts.push(billingAlert.value);
    if (adminAlert.value) alerts.push(adminAlert.value);
    if (showExportLimitAlert.value)
      alerts.push(RECORD_EXPORT_ALERTS[exportLimitStatus.value]);
    if (legacyFreeAlert.value) alerts.push(legacyFreeAlert.value);
    if (freeTrialAlert.value) alerts.push(freeTrialAlert.value);
    if (freeTrialEndedAlert.value) alerts.push(freeTrialEndedAlert.value);
    if (feedAlert.value) alerts.push(feedAlert.value);
    return alerts;
  });

  const numAlerts = computed(() => allAlerts.value.length);

  return {
    billingAlert,
    adminAlert,
    legacyFreeAlert,
    freeTrialAlert,
    freeTrialEndedAlert,
    showExportLimitAlert,
    legacyFreeModalVisible,
    onDismissAlert,
    onAlertClicked,
    allAlerts,
    numAlerts,
  };
}
