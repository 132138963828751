import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import { initStore, pollStore } from '@/stores/store-utils';
import { Population } from '@/types/populations';
import { FilterableSource, Source, SourceMdm } from '@/types/sources';
import urls from '@/urls';

export const useSourcesStore = defineStore('SourcesStore', () => {
  const popSourceIdToSourcesMap = ref<Record<number, Source[]>>({});
  const sources = ref<Source[]>([]);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data } = await crossbeamApi.GET('/v0.1/sources', {
        params: {
          query: {
            include_deleted: true,
          },
        },
      });
      sources.value = data?.items ?? [];
    },
  );

  refresh({ useCache: true });

  const dealSources = computed(() => {
    return sources.value?.filter(({ mdm_type: type }) => type === 'deal') ?? [];
  });

  const contactSources = computed(() => {
    return (
      sources.value?.filter(({ mdm_type: type }) => type === 'contact') ?? []
    );
  });

  function getSourceById(id: number) {
    return sources.value.find((source) => source.id === id);
  }

  function getSourcesByFeedId(feedId: number) {
    return sources.value.filter((source) => source.feed_id === feedId);
  }

  function getAllFilterableSources(
    items: FilterableSource[],
  ): FilterableSource[] {
    return items.map((source) => {
      const { id, fields, table, mdm_type, is_base_table, schema } = source;
      source['filterable-sources'].unshift({
        fields,
        id,
        is_base_table,
        mdm_type,
        schema,
        table,
      });
      return source;
    });
  }

  function getSourceAndFieldByFieldId(sourceFieldId: number) {
    const source = sources.value.find((source) =>
      source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    );
    if (!source) {
      return;
    }
    return {
      source: { ...source },
      field: {
        ...source.fields.find(
          (sourceField) => sourceField.id === sourceFieldId,
        ),
      },
    };
  }

  function getSourceFieldById(sourceFieldId: number) {
    const source = sources.value.find((source) =>
      source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    );
    if (!source) {
      return;
    }
    return {
      ...source.fields.find((sourceField) => sourceField.id === sourceFieldId),
    };
  }

  function getSourcesByMdmType(parentMdmType: SourceMdm) {
    return (
      sources.value?.filter(({ mdm_type: type }) => type === parentMdmType) ??
      []
    );
  }

  function getRelatedSources(source: Source) {
    const datasetName = source.dataset_name;
    return datasetName
      ? sources.value.filter((source) => source.dataset_name === datasetName)
      : [source];
  }

  function resetSourcesByPopulationSourceId() {
    popSourceIdToSourcesMap.value = {};
  }

  async function setSourcesByPopulationSourceId(population: Population) {
    const { source_id: sourceId } = population;
    if (popSourceIdToSourcesMap.value[sourceId]) return;
    try {
      const { data, error } = await crossbeamApi.GET(
        '/v0.2/sources/{id}/filterable-sources',
        {
          params: { path: { id: sourceId } },
        },
      );
      if (error) throw error;
      popSourceIdToSourcesMap.value[sourceId] = data.items;
    } catch (xhr) {
      captureException(xhr);
    }
  }

  async function deleteSource(id: number) {
    try {
      const url = urls.sources.delete(id);
      await axios.delete(url);
    } catch (xhr) {
      captureException(xhr);
    }
  }

  const { isPolling, startPolling, stopPolling } = pollStore(
    readySync,
    refresh,
  );

  return {
    error: initError,
    ready,
    readySync,
    running,
    sources,
    popSourceIdToSourcesMap,
    refreshSourcesStore: refresh,
    dealSources,
    contactSources,
    getSourceById,
    getSourcesByFeedId,
    getAllFilterableSources,
    getSourceAndFieldByFieldId,
    getSourceFieldById,
    getSourcesByMdmType,
    getRelatedSources,
    resetSourcesByPopulationSourceId,
    setSourcesByPopulationSourceId,
    deleteSource,
    isPolling,
    startPolling,
    stopPolling,
  };
});
