import { GetApiResponseByUrl } from '@crossbeam/openapi';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import appConfig from '@/config';
import { initStore } from '@/stores/store-utils';

export type Connection =
  GetApiResponseByUrl<'/v0.1/connections'>['items'][number];

type ConnectionType = Connection['integration_type'];
export const useConnectionsStore = defineStore('Connections', () => {
  const connections = ref<Connection[]>([]);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data, error } = await crossbeamApi.GET('/v0.1/connections');

      if (error) throw error;

      connections.value = data?.items ?? [];
    },
  );

  refresh({ useCache: true });

  const activeConnections = computed(() => {
    return connections.value.filter(
      (s) =>
        s.integration_type === 'salesforce' ||
        (!s.is_paused && !s.error_message),
    );
  });

  function getConnectionByType(integrationType: ConnectionType) {
    return connections.value.find(
      (s) => s.integration_type === integrationType,
    );
  }

  function getConnectionsByType(integrationType: ConnectionType) {
    return connections.value.filter(
      (s) => s.integration_type === integrationType,
    );
  }

  function reauthSalesforce() {
    const salesforceConnection = getConnectionByType('salesforce');
    if (!salesforceConnection) {
      throw new Error('No Salesforce connection found');
    }
    window.open(
      `${salesforceConnection?.config?.instance_url}/${
        appConfig.salesforceReauthUrl
      }`,
      '_blank',
    );
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    refreshConnectionsStore: refresh,
    connections,
    activeConnections,
    getConnectionByType,
    getConnectionsByType,
    reauthSalesforce,
  };
});
