import { EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import ConnectionCheckFailedNotification from '@/components/notifications/ConnectionCheckFailedNotification.vue';
import DataShareNotification from '@/components/notifications/DataShareNotification.vue';
import FeedProcessedNotification from '@/components/notifications/FeedProcessedNotification.vue';
import ListNotification from '@/components/notifications/ListNotification.vue';
import PopRevealNotification from '@/components/notifications/PopRevealNotification.vue';
import ProposalAcceptedNotification from '@/components/notifications/ProposalAcceptedNotification.vue';
import ProposalReceivedNotification from '@/components/notifications/ProposalReceivedNotification.vue';
import PushConnectionErrorNotification from '@/components/notifications/PushConnectionErrorNotification.vue';
import RecordExportLimitNotification from '@/components/notifications/RecordExportLimitNotification.vue';
import ReportPopulationRemovedNotification from '@/components/notifications/ReportPopulationRemovedNotification.vue';
import ReportPopulationStoppedSharingNotification from '@/components/notifications/ReportPopulationStoppedSharingNotification.vue';
import SeatRequestNotification from '@/components/notifications/SeatRequestNotification.vue';
import ShareRequestNotification from '@/components/notifications/ShareRequestNotification.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import useSharedList from '@/composables/useSharedList';
import { TEMP_PARTNER_VISIBILITY } from '@/constants/feature_flags';
import {
  useCollaborateStore,
  useDataRequestsStore,
  useDataSharesStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
  useSeatRequestsStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import { Notification } from '@/types/notifications';

export default function useNotificationsPage() {
  const { addSuccessFlash, addErrorFlash } = useFlashesStore();

  const notificationsStore = useNotificationsStore();
  const { loadMore } = notificationsStore;
  const {
    allNotifications,
    unreadNotifications,
    newItemsHref,
    isLoading,
    isLoadingMore,
  } = storeToRefs(notificationsStore);

  const { exportLimitStatus } = useRecordExportLimits();
  const { hasScope } = useAuth();

  const notifications = computed(() => {
    return allNotifications.value.filter(
      (notif) =>
        !!exportLimitStatus.value || notif.event_type !== 'record_export_limit',
    );
  });

  const loading = ref(false);
  const putPending = ref(false);

  const collaborateStore = useCollaborateStore();
  const dataRequestsStore = useDataRequestsStore();
  const dataSharesStore = useDataSharesStore();
  const feedsStore = useFeedsStore();
  const featureFlagsStore = useFeatureFlagStore();
  const partnersStore = usePartnersStore();
  const populationsStore = usePopulationsStore();
  const seatRequestsStore = useSeatRequestsStore();
  const rootStore = useRootStore();

  const partnerVisibilityEnabled = computed(() =>
    featureFlagsStore.hasFeatureFlag(TEMP_PARTNER_VISIBILITY),
  );

  onMounted(async () => {
    loading.value = true;
    const promises = [
      collaborateStore.refreshCollaborateStore(),
      dataRequestsStore.refreshInboundShareRequests(),
      dataSharesStore.refreshDataSharesStore(),
      feedsStore.refreshFeedsStore(),
      notificationsStore.refreshNotificationsStore(),
      populationsStore.refreshPartnerPopulations(),
      partnersStore.refreshProposals(),
      seatRequestsStore.refreshSeatRequestsStore(),
    ];
    await Promise.all(promises);
    loading.value = false;
  });

  const NOTIFICATION_LOOKUP = {
    share_request: ShareRequestNotification,
    data_shared: DataShareNotification,
    feed_processed: FeedProcessedNotification,
    connection_check_failed: ConnectionCheckFailedNotification,
    report_population_removed: ReportPopulationRemovedNotification,
    report_population_stopped_sharing:
      ReportPopulationStoppedSharingNotification,
    population_visible: PopRevealNotification,
    proposal_received: ProposalReceivedNotification,
    proposal_accepted: ProposalAcceptedNotification,
    push_connection_error: PushConnectionErrorNotification,
    invite_request_access: SeatRequestNotification,
    record_export_limit: RecordExportLimitNotification,
  };

  interface NotificationObjectId {
    data_share_id?: number;
    start_of_day?: string;
    visibility?: string;
  }

  function notificationComponent(item: Notification) {
    const filteredItem = partnerVisibilityFilteredItem(
      item as Notification & { object_id?: NotificationObjectId },
    );
    if (
      !!filteredItem &&
      NOTIFICATION_LOOKUP[
        filteredItem.event_type as keyof typeof NOTIFICATION_LOOKUP
      ]
    )
      return NOTIFICATION_LOOKUP[
        filteredItem.event_type as keyof typeof NOTIFICATION_LOOKUP
      ];
    if (!!filteredItem && filteredItem.event_type.startsWith('list'))
      return ListNotification;
  }

  function partnerVisibilityFilteredItem(
    item: Notification & { object_id?: NotificationObjectId },
  ) {
    const canManagePartnerships = hasScope('write:partnerships');
    if (!partnerVisibilityEnabled.value || canManagePartnerships) return item;
    const { data, event_type, object_id } = item;

    const partnerOrgIdentifierMap: Record<string, number | undefined> = {
      share_request: data?.requesting_org_id,
      report_population_removed: data?.partner_org_id,
      report_population_stopped_sharing: data?.partner_org_id,
      proposal_received: data?.inviter_org_id,
      proposal_accepted: data?.receiving_org_id,
    };
    if (event_type === 'population_visible' && data?.population_id) {
      const partnerPop = populationsStore.getPartnerPopulationById(
        data.population_id,
      );
      if (!partnerPop) return;
      partnerOrgIdentifierMap[event_type] = partnerPop.organization_id;
    }
    if (event_type.startsWith('list') && data?.list_id) {
      const { partnerOrg } = useSharedList(data.list_id);
      partnerOrgIdentifierMap[event_type] = partnerOrg.value?.organization_id;
    }

    if (event_type === 'data_shared' && object_id?.data_share_id) {
      const dataShareId = dataSharesStore.getDataShareById(
        object_id.data_share_id,
      );
      if (
        dataShareId &&
        'partner_org_id' in dataShareId &&
        dataShareId.partner_org_id !== null
      ) {
        partnerOrgIdentifierMap[event_type] = dataShareId.partner_org_id;
      }
    }
    if (!partnerOrgIdentifierMap[event_type]) return item;
    const orgId = Number(partnerOrgIdentifierMap[event_type]);
    return orgId !== undefined && rootStore.visiblePartnerOrgIds?.has(orgId)
      ? item
      : null;
  }

  async function handleMarkAllRead() {
    if (!putPending.value && unreadNotifications.value.length !== 0) {
      putPending.value = true;
      loading.value = true;
      try {
        await notificationsStore.markAllRead();
        addSuccessFlash({ message: 'Notifications marked as read' });
      } catch (_err) {
        addErrorFlash({
          message: 'Notifications not marked as read',
          description:
            'Please try again or contact support@crossbeam.com for help.',
        });
      } finally {
        loading.value = false;
        putPending.value = false;
      }
    }
  }

  const { iteratively } = useIteratively();

  function sendIterativelyEvent(buttonText: string, item: Notification) {
    iteratively.userClickedNotification({
      event_site: EVENT_SITES.NOTIFICATION_CARD,
      notification_text: item.event_type,
      notification_unread: !item.is_read,
      cta: buttonText,
    } as never);
  }

  return {
    handleMarkAllRead,
    isLoading,
    isLoadingMore,
    loading,
    loadMore,
    newItemsHref,
    notifications,
    notificationComponent,
    putPending,
    sendIterativelyEvent,
    unreadNotifications,
  };
}
