<template>
  <BittsLoading :is-loading="isLoading">
    <div class="c-sharing-settings-tab">
      <div class="c-sharing-settings-tab__title">
        <h2 class="font-bold text-lg">Populations shared</h2>
        <h3 class="text-neutral-text mb-16">
          We recommend to share your Customers, Prospects, Open Opportunities
          and Strategic Accounts.
        </h3>
      </div>
      <div class="flex flex-col gap-8">
        <SharingItem
          v-for="dataShare in dataShares"
          :key="dataShare.populationId"
          :data-share="dataShare"
          :update-data-share="updateDataShare"
          :preset-options="presetOptions[dataShare.presetParentId]"
        />
      </div>
      <BittsPopover v-if="populationOptions.length > 0 && hasSharingPermission">
        <BittsButton
          type="neutral"
          variant="outline"
          size="small"
          :text="buttonText"
          class="w-fit"
          :left-icon="['far', 'plus']"
        />
        <template #content>
          <div
            v-for="option in populationOptions"
            :key="option.value"
            @click="createDataShare(option)"
          >
            <div class="flex items-center">
              <slot name="option" :option="option">
                <span class="c-sharing-settings-tab__pop-option">{{
                  option.label
                }}</span>
              </slot>
            </div>
          </div>
        </template>
      </BittsPopover>
    </div>
  </BittsLoading>
</template>

<script setup lang="ts">
import { BittsButton, BittsLoading, BittsPopover } from '@crossbeam/bitts';

import { computed } from 'vue';

import useAuth from '@/composables/useAuth';

import useSharingSettings from '../composables/useSharingSettings';

import SharingItem from './SharingItem.vue';

type Props = {
  partnerId?: number;
  isBulk?: boolean;
};

const { partnerId, isBulk } = defineProps<Props>();

const emit = defineEmits<(e: 'bulk-update', success: boolean) => void>();

const {
  isLoading,
  dataShares,
  populationOptions,
  presetOptions,
  createDataShare,
  updateDataShare,
  bulkUpdateDataShares,
} = useSharingSettings(partnerId, isBulk);

const { hasPermission } = useAuth();
const hasSharingPermission = computed(() => hasPermission('manage:sharing'));

const buttonText = computed(() => {
  return dataShares.value.length > 0
    ? 'Share another population'
    : 'Share population';
});

async function triggerBulkUpdate() {
  try {
    await bulkUpdateDataShares();
    emit('bulk-update', true);
    return true;
  } catch (error) {
    emit('bulk-update', false);
    return false;
  }
}

defineExpose({
  triggerBulkUpdate,
});
</script>

<style lang="pcss" scoped>
.c-sharing-settings-tab {
  @apply flex flex-col m-24 gap-16;
}
.c-sharing-settings-tab__title {
  @apply flex flex-col gap-8;
}
.c-sharing-settings-tab__pop-option {
  @apply flex items-center cursor-pointer px-10 py-6 w-full min-w-[200px];
  &:hover {
    @apply bg-primary-background-weak;
  }
}
</style>
