import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { captureException } from '@/errors';
import {
  MatchIssue,
  MatchIssueType,
  MatchIssues,
} from '@/interfaces/match_issues';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

export const useMatchIssuesStore = defineStore('MatchIssuesStore', () => {
  const matchIssueTypes = ref<MatchIssueType[]>([]);
  const issues = ref<MatchIssues>({});

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const { data } = await axios.get(urls.matchingIssues.types);
      if (data) matchIssueTypes.value = data.items;
    },
  );

  refresh({ useCache: true });

  async function fetchMatchIssuesBySourceIdAndRecordId({
    sourceId,
    recordId,
  }: {
    sourceId: number | string;
    recordId: string;
  }): Promise<void> {
    try {
      const response = await axios.get(
        urls.matchingIssues.get(sourceId, recordId),
      );
      issues.value[`${sourceId}_${recordId}`] = response.data.items;
    } catch (err) {
      captureException(err);
    }
  }

  function getMatchIssuesBySourceIdAndRecordId(
    sourceId: number,
    recordId: string,
  ): MatchIssue[] {
    return issues.value[`${sourceId}_${recordId}`] || [];
  }

  return {
    error: initError,
    ready,
    readySync,
    refreshMatchIssuesStore: refresh,
    running,
    issues,
    fetchMatchIssuesBySourceIdAndRecordId,
    getMatchIssuesBySourceIdAndRecordId,
    matchIssueTypes,
  };
});
