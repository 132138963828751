import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { TEMP_PARTNER_VISIBILITY } from '@/constants/feature_flags';
import { useFeatureFlagStore, usePartnersStore } from '@/stores';

import useAuth from './useAuth';

export function useEmptyStatePartner() {
  const { hasPermission } = useAuth();
  const { hasFeatureFlag } = useFeatureFlagStore();
  const partnerStore = usePartnersStore();

  const { partnerOrgs } = storeToRefs(partnerStore);

  const showEmptyStatePartner = computed(
    () =>
      partnerOrgs.value.length === 0 &&
      hasFeatureFlag(TEMP_PARTNER_VISIBILITY) &&
      !hasPermission('manage:partner-visibility'),
  );

  return { showEmptyStatePartner };
}
