import { MINIMAL_VIEW_PARAM } from '@/utils';

let isMinimalView: boolean | null = null;

export function __resetMinimalView() {
  isMinimalView = null;
}
export default function useMinimalApp() {
  isMinimalView = Boolean(sessionStorage.getItem(MINIMAL_VIEW_PARAM));

  return {
    isMinimalView,
  };
}
