<template>
  <BittsModalTwo
    name="report-bad-match-modal"
    :loading="!ready"
    title="Report Issue with Matching"
    primary-button-text="Send Report"
    description="This information will be used to improve our matching algorithm. False matches you've reported will disappear over time. Until then, we will flag them in the UI for you and your partner."
    :action-loading="submitting"
    @action="submit"
  >
    <template #default>
      <div class="flex items-center">
        <BittsAvatar
          :fallback-image-type="recordType"
          :alt-text-from-parent="recordName"
          :image-source="imageSource"
          size="medium"
          shape="square"
        />
        <div class="ml-16">
          <div class="c-report-bad-match-modal__org-name">
            {{ recordName }}
          </div>
          <div v-if="recordDomain" class="c-report-bad-match-modal__org-domain">
            {{ recordDomain }}
          </div>
        </div>
      </div>
      <VuelidateWrapper
        class="mt-16"
        property="affectedPartners"
        :errors="v$.$errors"
      >
        <BittsMultiselect
          v-model="affectedPartners"
          :dropdown-match-select-width="false"
          form-label="Which partner(s) should not be listed as a match?"
          mode="multiple"
          :options="partners"
          option-type="company"
          placeholder="Select Partners"
        />
      </VuelidateWrapper>
      <VuelidateWrapper
        class="my-24"
        property="selectedIssues"
        :errors="v$.$errors"
      >
        <BittsCheckboxGroup form-label="What best describes the problem?">
          <BittsCheckbox
            v-for="issueType in matchIssueTypes"
            :key="issueType.id"
            v-model="issueType.checked"
            :label="issueType.description"
            class="c-report-bad-match-modal__issue-text"
            @input="(checked) => handleCheck(issueType, checked)"
          />
        </BittsCheckboxGroup>
      </VuelidateWrapper>
      <BittsTextArea
        v-model="additionalComments"
        name="additional-comments"
        placeholder="Anything else you think would be helpful to us!"
        :form-label="{
          title: 'Additional Comments',
          secondaryText: 'Optional',
        }"
      />
    </template>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import {
  BittsAvatar,
  BittsCheckbox,
  BittsCheckboxGroup,
  BittsModalTwo,
  BittsMultiselect,
  BittsTextArea,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import VuelidateWrapper from '@/components/VuelidateWrapper.vue';

import { MDM_PROPERTIES } from '@/constants/mdm';
import { MatchIssueType } from '@/interfaces/match_issues';
import { useFlashesStore, useMatchIssuesStore } from '@/stores';
import { Partner } from '@/types/partners';
import { PartnerPopulation } from '@/types/populations';
import { Source, SourceField } from '@/types/sources';
import urls from '@/urls';
import { REGEX_URL, getMdmField, trim } from '@/utils';

type Elements = Record<
  string,
  {
    fields: SourceField[];
  }
>;
const {
  partnerPopulations = [],
  source,
  sourceId,
  sourcePrimaryKey,
} = defineProps<{
  partnerPopulations: (PartnerPopulation & { org: Partner })[];
  source: Source & { elements: Elements };
  sourceId: number | string;
  sourcePrimaryKey: string;
}>();

const emit = defineEmits<(e: 'closed') => void>();

const matchIssuesStore = useMatchIssuesStore();
const { matchIssueTypes, ready } = storeToRefs(matchIssuesStore);
const flashesStore = useFlashesStore();

const selectedIssues = computed(() =>
  matchIssueTypes.value.filter((t) => t.checked),
);

const additionalComments = ref('');
const submitting = ref(false);
const affectedPartners = ref([]);

const rules = {
  affectedPartners: {
    required: helpers.withMessage(
      'Please select at least one partner',
      required,
    ),
  },
  selectedIssues: {
    required: helpers.withMessage(
      'Please select at least one reason',
      required,
    ),
  },
};

const v$ = useVuelidate(rules, { affectedPartners, selectedIssues });

const partners = computed(() => {
  const partnerIds = new Set(partnerPopulations.map((p) => p.org.id));
  return [...partnerIds].map((id) => {
    const partner = partnerPopulations.find((p) => id === p.org.id)?.org;
    return { ...partner, value: id, label: partner?.name };
  });
});

const recordType = computed(() =>
  source.mdm_type === 'account' ? 'company' : 'person',
);
const recordName = computed(() => {
  return recordType.value === 'company'
    ? getMdmField(source.elements.account, MDM_PROPERTIES.account.NAME)
    : getMdmField(source.elements.lead, 'person_email');
});
const recordDomain = computed(() => {
  if (recordType.value === 'company') {
    const rawRecordDomain = trim(
      getMdmField(source.elements.account, 'company_website'),
    );
    return REGEX_URL.test(rawRecordDomain)
      ? rawRecordDomain.trim().replace(/https?:\/\//, '')
      : rawRecordDomain;
  }
  return null;
});
const imageSource = computed(() => {
  return recordType.value === 'company'
    ? `https://logo.clearbit.com/${recordDomain.value}`
    : '';
});

function handleCheck(type: MatchIssueType, isChecked: boolean) {
  matchIssueTypes.value = matchIssueTypes.value.map((issueType) => {
    if (issueType === type) return { ...issueType, checked: isChecked };
    return { ...issueType };
  });
}

async function submit() {
  v$.value.$touch();
  if (!v$.value.$error) {
    submitting.value = true;

    try {
      const payload = {
        master_id: sourcePrimaryKey,
        source_id: sourceId,
        partner_org_ids: affectedPartners.value,
        issue_type_ids: selectedIssues.value.map((i) => i.id),
        comments: additionalComments.value,
      };
      await axios.post(urls.matchingIssues.post, payload);
      matchIssuesStore.fetchMatchIssuesBySourceIdAndRecordId({
        sourceId,
        recordId: sourcePrimaryKey,
      });
      flashesStore.addSuccessFlash({ message: 'Matching Feedback Submitted' });
    } catch (_$ex) {
      flashesStore.addErrorFlash({
        message: 'Matching Feedback Not Submitted',
        description: 'Please try again',
      });
    } finally {
      submitting.value = false;
      v$.value.$reset();
      emit('closed');
    }
  }
}
</script>

<style lang="pcss" scoped>
.c-report-bad-match-modal__org-name {
  @apply text-base;
}

.c-report-bad-match-modal__org-domain {
  @apply text-sm text-neutral-500 leading-6;
}

.c-report-bad-match-modal__issue-text:deep(.bitts-checkbox-label) {
  @apply text-neutral-800;
}
</style>
