<template>
  <BittsModal
    title="Delete rows?"
    confirm-type="danger"
    save-text="Delete"
    :saving="isSaving"
    :width="400"
    :visible="true"
    @saved="onDeleteConfirmed"
    @closed="onModalClosed"
  >
    <template #content>
      <p class="text-neutral-text">
        Are you sure you want to delete these rows? Your collaborators will no
        longer be able to see these opportunities, and this action can not be
        undone.
      </p>
    </template>
  </BittsModal>
</template>
<script setup lang="ts">
import { BittsModal } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import useIteratively from '@/composables/useIteratively';
import { captureException } from '@/errors';
import { useCollaborateStore, useFlashesStore } from '@/stores';
import { SharedListDataRow } from '@/types/shared_lists';

const {
  rowsSelected = [],
  listId = '',
  currentNumRecords = 0,
} = defineProps<{
  rowsSelected?: SharedListDataRow[];
  listId?: string;
  currentNumRecords?: number;
}>();

const emit = defineEmits<(e: 'saved' | 'closed') => void>();
const collaborateStore = useCollaborateStore();
const flashesStore = useFlashesStore();
const { iteratively } = useIteratively();

const isSaving = ref(false);

const newNumRecordsInList = computed(
  () => currentNumRecords - rowsSelected.length,
);

function onModalClosed() {
  emit('closed');
}

async function onDeleteConfirmed() {
  try {
    isSaving.value = true;
    await crossbeamApi.POST('/v0.1/lists/{list_id}/rows/delete', {
      params: { path: { list_id: listId } },
      body: {
        row_ids: rowsSelected.map((row) => row.row_id),
      },
    });
    const singleOrMultipleRows =
      rowsSelected.length > 1 ? 'rows have' : 'row has';
    flashesStore.addSuccessFlash({
      message: `Your ${singleOrMultipleRows} successfully been deleted`,
    });
    iteratively.userUpdatedListRecords({
      event_site: EVENT_SITES.LIST_DELETE_ROWS_MODAL,
      previous_value: currentNumRecords.toString(),
      new_value: newNumRecordsInList.value.toString(),
      action: 'removed records',
      action_location: 'Collaborate',
    } as never);
    emit('saved');
    collaborateStore.refreshCollaborateStore();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message =
      error.response?.data?.errors?.join(',') ||
      'Something went wrong, reach out to our support team for help';
    captureException(error);
    flashesStore.addErrorFlash({ message });
    onModalClosed();
  } finally {
    isSaving.value = false;
  }
}
</script>
