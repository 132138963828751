import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import { REST_API } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import { initStore } from '@/stores/store-utils';
import {
  OauthApplication,
  OauthApplicationPatchPayload,
  OauthApplicationPostPayload,
} from '@/types/oauth_applications';

import { useFeatureFlagStore } from './FeatureFlagStore';

export const useOauthApplicationStore = defineStore('OAuthApplications', () => {
  const oAuthApplications = ref<OauthApplication[]>([]);
  const { hasPermission } = useAuth();
  const { hasFeatureFlag } = useFeatureFlagStore();
  const hasRestApiEnabled = computed(() => hasFeatureFlag(REST_API));

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      if (
        !hasPermission('manage:oauth-applications') ||
        !hasRestApiEnabled.value
      )
        return;
      const { data } = await crossbeamApi.GET('/v0.1/oauth-applications');
      oAuthApplications.value = data?.items || [];
    },
  );

  refresh({ useCache: true });

  function getOAuthApplicationById(id: string) {
    return oAuthApplications.value.find((app) => app.id === id);
  }

  async function createOAuthApplication(body: OauthApplicationPostPayload) {
    const { error } = await crossbeamApi.POST('/v0.1/oauth-applications', {
      body,
    });
    if (error) {
      captureException(error);
      throw error;
    }
  }

  async function deleteOAuthApplication(appId: string) {
    oAuthApplications.value = oAuthApplications.value.filter(
      (app) => app.id !== appId,
    );
    const { error } = await crossbeamApi.DELETE(
      '/v0.1/oauth-applications/{application_id}',
      {
        params: {
          path: {
            application_id: appId,
          },
        },
      },
    );
    if (error) {
      captureException(error);
      throw error;
    }
  }

  async function patchOauthApplication(
    appId: string,
    body: OauthApplicationPatchPayload,
  ) {
    const { error } = await crossbeamApi.PATCH(
      '/v0.1/oauth-applications/{application_id}',
      {
        params: {
          path: {
            application_id: appId,
          },
        },
        body,
      },
    );
    if (error) {
      captureException(error);
      throw error;
    }
  }

  return {
    error: initError,
    ready,
    readySync,
    refreshOAuthApplicationsStore: refresh,
    running,
    createOAuthApplication,
    deleteOAuthApplication,
    patchOauthApplication,
    getOAuthApplicationById,
    oAuthApplications,
  };
});
