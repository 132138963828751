const setOrRemove = (key, value, encoder = null) => {
  if (window.localStorage) {
    if (value) {
      window.localStorage.setItem(key, encoder ? encoder(value) : value);
    } else {
      window.localStorage.removeItem(key);
    }
  }
};

const get = (key, decoder = null) => {
  const val = window.localStorage?.getItem(key);
  if (!val) {
    return val;
  }
  return decoder ? decoder(val) : val;
};

const dataTypes = {
  json: { encoder: JSON.stringify, decoder: JSON.parse },
  integer: { encoder: String, decoder: parseInt },
  boolean: { encoder: Boolean },
  string: { encoder: String, decoder: String },
};

const wrapper = (key, dataType, { clearOnLogout }) => {
  return {
    set(value) {
      setOrRemove(key, value, dataTypes[dataType].encoder);
    },
    get() {
      return get(key, dataTypes[dataType].decoder);
    },
    clearOnLogout,
  };
};

export const ls = {
  publicInvite: wrapper('xbeam:publicInvite', 'json', { clearOnLogout: true }),
  suggestedPartners: wrapper('xbeam:suggestedPartners', 'json', {
    clearOnLogout: true,
  }),
  proposalAcceptInfo: wrapper('xbeam:proposalAcceptInfo', 'json', {
    clearOnLogout: true,
  }),
  orgId: wrapper('xbeam:organizationId', 'integer', { clearOnLogout: false }),
  nextUrl: wrapper('xbeam:auth:next', 'json', { clearOnLogout: true }),
  newSession: wrapper('xbeam:newSession', 'boolean', { clearOnLogout: true }),
  deviceUUID: wrapper('xbeam:device', 'string', { clearOnLogout: false }),
  oauthState: wrapper('xbeam:sso-state', 'string', { clearOnLogout: true }),
  billing: wrapper('xbeam:billing', 'json', { clearOnLogout: true }),
  attributionSettings: wrapper('xbeam:attributionSettings', 'json', {
    clearOnLogout: false,
  }),
  onboardingInviteSkip: wrapper('xbeam:onboardingInviteSkip', 'integer', {
    clearOnLogout: false,
  }),
  onboardingCallouts: wrapper('xbeam:onboardingCallouts', 'json', {
    clearOnLogout: false,
  }),
  onboardingMappingDone: wrapper('xbeam:onboardingMappingDone', 'integer', {
    clearOnLogout: false,
  }),
  partnerDisplayType: wrapper('xbeam:partnerDisplayType', 'string', {
    clearOnLogout: false,
  }),
  dismissEarlyQuarter: wrapper('xbeam:dismissEarlyQuarter', 'boolean', {
    clearOnLogout: true,
  }),
  onboardingCrmAdminSkip: wrapper('xbeam:onboardingCrmAdminSkip', 'integer', {
    clearOnLogout: false,
  }),
  minimalView: wrapper('xbeam:minimalView', 'boolean', { clearOnLogout: true }),
  navbarCollapsed: wrapper('xbeam:navbarCollapsed', 'boolean', {
    clearOnLogout: false,
  }),
  dealNavFilters: wrapper('xbeam:dealNavFilters', 'json', {
    clearOnLogout: false,
  }),
};

// setLSOrgId
// getLSOrgId
// setLSNextUrl
// getLSNextUrl

export const getAndClearLSNextUrl = () => {
  const next = ls.nextUrl.get();
  ls.nextUrl.set();
  return next;
};

export function getOrCreateBillingToken(key, extra) {
  const billingTokens = ls.billing.get() || {};
  billingTokens[key] = billingTokens[key] || {
    ...extra,
    token: crypto.randomUUID(),
  };
  ls.billing.set(billingTokens);
  return billingTokens[key];
}

export function clearBillingToken(key) {
  const billingTokens = ls.billing.get() || {};
  const { [key]: _, ...remainingTokens } = billingTokens;
  ls.billing.set(remainingTokens);
}
