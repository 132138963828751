/**
 * TEMPORARY FLAGS
 * Generally used for gating access to features while we beta test and / or
 * the feature needs to go through design review
 */
export const SALESFORCE_APP_EARLY_ADOPTER = 'early_adopter_salesforce_widget';
export const BQ_CSV_ACCOUNT_EXECUTIVE_FIELDS =
  'bq_csv_account_executive_fields';
export const MULTI_SELECT_SF = 'multi_select_sf';
export const SNOWFLAKE_PULL_LIMIT = 'snowflake_pull_limits';
export const TEMP_PP_UNLIMITED_CUSTOM = 'pp_unlimited_custom_pops';
export const TEMP_LAST_ACTIVITY = 'temp_last_activity';
export const TEMP_EXCLUDE_OFFLINE_PARTNERS = 'temp_exclude_offline_partners';
export const TEMP_HAS_BAD_HUBSPOT_PUSH_AUTH =
  'temp_has_bad_hubspot_push_authentication';
export const TEMP_EXEC_UP_SHARED_LIST_ROWS = 'temp_exec_up_shared_list_rows';
export const TEMP_REBUILT_DEBOOKING = 'temp_rebuilt_debooking';
export const TEMP_TEST_DEBOOKING = 'temp_test_debooking';
export const TEMP_HUBSPOT_INTEGRATION_STATUS_TRACKING =
  'temp_hubspot_integration_status_tracking';
export const TEMP_DEAL_DASHBOARD = 'temp_deal_dashboard';
export const TEMP_DEAL_NAV_PERMISSIONS = 'temp_deal_nav_permissions';
export const TEMP_REDESIGNED_FILTER_GROUPS = 'temp_redesigned_filter_groups';
export const TEMP_PARTNER_VISIBILITY = 'temp_partner_visibility';
export const TEMP_NEW_DATASHARING = 'temp_new_datasharing';
export const TEMP_SHOW_RESET_TO_DEFAULTS = 'temp_show_reset_to_defaults';
export const TEMP_EMBED_ROI_DASHBOARD = 'temp_embed_roi_dashboard';
export const TEMP_EXEC_PARTNER_SPONSOR = 'temp_exec_partner_sponsor';
export const TEMP_DUNS = 'duns_matching';
export const TEMP_LEGACY_FREE = 'temp_legacy_free';
export const TEMP_CONNECTOR_TRIAL = 'temp_connector_trial';
export const TEMP_PERFORMANCE_DASHBOARD = 'temp_performance_dashboard';
export const TEMP_PUSH_EXTRA_FIELDS = 'temp_push_extra_fields';
export const TEMP_ECOSYSTEM_FIELDS_SFDC = 'temp_ecosystem_fields_sfdc';
export const TEMP_NAVBAR_GLOWUP = 'temp_navbar_glowup';
export const TEMP_ALLOW_ADDING_TO_BIG_CSV = 'temp_allow_adding_data_to_big_csv';
export const TEMP_INVITE_WITH_SHARING = 'temp_invite_with_sharing';
export const TEMP_DEAL_CONTACT_ROLE_FIELDS_FILTERABLE =
  'temp_deal_contact_role_fields_filterable';

// This might actually be a temp flag but was created in muncher before the temp pattern
export const DUNS_MATCHING = 'duns_matching';

// new onboarding
export const NEW_ONBOARDING = 'new_onboarding';

// population filters a/b
export const POPULATION_FILTER_MODAL = 'population_filter_modal';

// Leverage picklist options in report and population filters
export const TEMP_POPULATION_FILTER_WITH_PICKLIST_OPTIONS =
  'temp_population_filter_with_picklist_options';
export const TEMP_REPORT_FILTER_WITH_PICKLIST_OPTIONS =
  'temp_report_filter_with_picklist_options';

/**
 * PERMANENT FLAGS
 * These flags are not globally released, they are turned on a case-by-case basis.
 * They are typically used for individual paid features
 */
export const SSO = 'sso';
export const REST_API = 'rest_api';
export const SNOWFLAKE_PUSH = 'snowflake_push';
export const SALESFORCE_APP_PUSH = 'salesforce_app_push';
export const AUDIT_LOG = 'audit_log';
export const SALESFORCE_PUSH_CONFIG = 'salesforce_push_config';
export const ATTRIBUTION_ACCESS_OVERRIDE =
  'attribution_access'; /* Enterprise customers automatically get attribution */
export const PREVENT_DEFAULT_PARTNERSTACK_COL =
  'prevent_default_partnerstack_col'; /* does not add PartnerStack Column as a default in Reports/Shared Lists when enabled (requested for demo orgs) */
export const OVER_SEAT_QUOTA_RESTRICTED = 'over_seat_quota_restricted';
export const PARTNERSTACK_PARTNERSHIP_KEY = 'partnerstack_partnership_key';
export const DISABLE_SELF_SERVE_DEBOOKING = 'disable_self_serve_debooking';
export const BIG_CSV_FILE_LIMIT_500MB = 'big_csv_file_limit_500MB';
export const SHARING_OVERRIDES_ENABLED = 'sharing_overrides_enabled';

// Partner cloud flags
export const PC_INTEGRATIONS = {
  HUBSPOT: 'pc_hubspot',
  HUBSPOT_V2: 'pc_hubspot_v2',
  TRAY: 'pc_tray',
  TORCHLITE: 'pc_torchlite',
  SALESFORCE_ATTRIBUTION_PUSH: 'pc_salesforce_attribution',
  MARKETO: 'pc_marketo',
  GONG: 'pc_gong',
  CLARI: 'pc_clari',
  DYNAMICS: 'pc_dynamics',
};
