import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import appConfig from '@/config';

import { initStore } from './store-utils';

export const useFeatureFlagStore = defineStore('FeatureFlags', () => {
  type FeatureFlag = {
    name: string;
  };

  const featureFlags = ref<FeatureFlag[]>([]);

  const { currentOrg, currentUser } = useAuth();

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      if (!currentUser.value.id || !currentOrg.value.id) return;

      const { data, error } = await crossbeamApi.GET('/v0.1/feature-flags');
      const lsFlagInfo = localStorage.getItem('featureFlagInfo');

      if (lsFlagInfo && appConfig.env !== 'prod') {
        const { enabled, flags } = JSON.parse(lsFlagInfo);
        if (enabled) {
          featureFlags.value = flags;
          return;
        }
      }

      if (error) throw error;

      featureFlags.value = data?.items ?? [];
    },
  );

  refresh({ useCache: true });

  function hasFeatureFlag(flag: string) {
    return featureFlags.value.some((o) => o.name === flag);
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    refreshFeatureFlagStore: refresh,
    featureFlags,
    hasFeatureFlag,
  };
});
