<template>
  <div
    :class="field.$error && wrapperClasses"
    class="c-bitts-live-feedback-wrapper"
  >
    <!-- slot is for the actual input -->
    <slot />
    <div
      v-if="
        (field.$dirty || showValidator) && unpassedValidationMessages.length > 0
      "
      :class="contentClass"
    >
      <div class="c-bitts-live-feedback-wrapper__content mb-8 mt-8">
        <slot name="description" />
      </div>
      <div class="grid grid-cols-2 w-full">
        <div
          v-for="[
            validationType,
            validationMessage,
          ] of unpassedValidationMessages"
          :key="validationType"
          class="c-bitts-live-feedback-wrapper__content c-bitts-live-feedback-wrapper__subtype"
        >
          <p class="c-bitts-live-feedback-wrapper__message mb-4">
            <FontAwesomeIcon
              :icon="['fak', 'x']"
              class="mr-8 text-neutral-400 text-12"
            />
            {{ validationMessage.message }}
          </p>
          <div
            v-if="validationMessage.description"
            class="c-bitts-live-feedback-wrapper__description"
          >
            <ul v-if="isList(validationMessage)" class="ml-16">
              <li
                v-for="message of validationMessage.description"
                :key="message"
                class="inline-block mr-3"
              >
                <span class="fake-bullet">•</span> {{ message }}
              </li>
            </ul>
            <p v-else>
              {{ validationMessage.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ValidatorResponse } from '@vuelidate/core';
import { computed } from 'vue';

type ValidationMessage = {
  message: string;
  description?: string;
};
const {
  field,
  wrapperClass = [],
  validationMessages = null,
  contentClass = [],
  showValidator = false,
} = defineProps<{
  field: ValidatorResponse;
  wrapperClass: string[] | string;
  contentClass: string[];
  showValidator: boolean;
  validationMessages: Record<string, ValidationMessage>;
}>();

function isList(message: ValidationMessage) {
  return Array.isArray(message.description);
}

const wrapperClasses = computed(() => {
  const classList = [];
  if (Array.isArray(wrapperClass) && wrapperClass.length) {
    classList.push(...wrapperClass);
  } else {
    classList.push(wrapperClass);
  }
  return classList;
});

function passesValidation(validationType: string) {
  return field.$model.length && !field[validationType].$invalid;
}
const unpassedValidationMessages = computed(() => {
  return Object.entries(validationMessages || {}).filter(
    ([type]) => !passesValidation(type),
  );
});
</script>

<style lang="pcss">
.c-bitts-live-feedback-wrapper {
  .c-bitts-live-feedback-wrapper__message {
    @apply flex justify-items-start items-center;
  }

  .c-bitts-live-feedback-wrapper__content {
    @apply text-sm text-neutral-text-weak text-left;
  }

  .c-bitts-live-feedback-wrapper__subtype {
    @apply ml-8;
  }

  .c-bitts-live-feedback-wrapper__maintype {
    @apply flex flex-1;
    flex: 0 0 100%;
  }
}
</style>
