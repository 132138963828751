import { MDM_PROPERTIES, MDM_TYPES } from '@/constants/mdm';

export const HIDDEN = 'hidden';
export const OLAPS_ONLY = 'aggregates_only';
export const SHARING = 'full';
export const GREENFIELD_SHARING = 'greenfield_full';

// Sharing types
export const SHARING_TYPES = {
  GREENFIELD_SHARING,
  SHARING,
  OLAPS_ONLY,
  HIDDEN,
} as const;

export type SharingType = (typeof SHARING_TYPES)[keyof typeof SHARING_TYPES];

export const POPULATION_RULE = 'population';
export const PARTNER_RULE = 'partner';
export const PARTNER_POPULATION_RULE = 'partner_population';

const FULL_TEXT = {
  title: 'Sharing',
  selectorTitle: 'Sharing data',
  value: SHARING,
  cssClasses: [
    'bg-success-background-weak',
    'border-success-text-strong',
    'text-success-text-strong',
  ],
  variantClasses: ['hover:border-success-text-strong'],
  subtitle: ({
    partnerName = 'Your partners',
    populationName = 'this population',
    showSubject = true,
  } = {}) =>
    `${showSubject ? partnerName : ''} can see the data shared
    with them for all overlaps with ${populationName}.`,
};

export const SELECTABLE_VISIBILITY_OPTIONS = [
  {
    name: 'Sharing Data',
    value: SHARING,
    activeClasses:
      'border-success-accent text-success-text bg-success-background-weak',
  },
  {
    name: 'Counts',
    value: OLAPS_ONLY,
    activeClasses:
      'border-warning-accent text-warning-text bg-warning-background-weak',
  },
  {
    name: 'Hidden',
    value: HIDDEN,
    activeClasses:
      'border-danger-accent text-danger-text bg-danger-background-weak',
  },
];

export const VISIBILITY_OPTIONS_TEXT = {
  full: FULL_TEXT,
  greenfield_full: { ...FULL_TEXT, value: GREENFIELD_SHARING },
  use_defaults: {
    title: 'Use Defaults',
    value: 'use_defaults',
    subtitle: ({
      partnerName = 'Your partners',
      populationName = 'this population',
      showSubject = true,
    } = {}) =>
      `${showSubject ? partnerName : ''} will be subject to
      the sharing defaults defined for ${populationName}.`,
  },
  aggregates_only: {
    title: 'Counts',
    value: OLAPS_ONLY,
    cssClasses: [
      'bg-accent-background-weak',
      'border-accent-text-strong',
      'text-warning-text-strong',
    ],
    variantClasses: ['hover:border-accent-text-strong'],
    subtitle: ({
      partnerName = 'Your partners',
      populationName = 'this population',
      showSubject = true,
      isTerse = false,
    } = {}) =>
      !isTerse
        ? `${showSubject ? partnerName : ''} can see a summary
      count of overlaps for ${populationName}. No specifics about
      the overlaps, including name or any other data, is shared.`
        : `${showSubject ? partnerName : ''} can see a summary
        count of overlaps for ${populationName}, but specifics
        are not shared.`,
  },
  hidden: {
    title: 'Hidden',
    value: HIDDEN,
    cssClasses: [
      'bg-danger-background-weak',
      'border-danger-text',
      'text-danger-text-strong',
    ],
    variantClasses: ['hover:border-danger-text'],
    subtitle: ({
      partnerName = 'Your partners',
      populationName = 'this population',
      showSubject = true,
    } = {}) =>
      `${showSubject ? partnerName : ''} cannot see
      ${populationName}.`,
  },
};

export const SHARING_LEVEL_OPTIONS_TEXT = {
  use_defaults: {
    title: 'Use Defaults',
    subtitle: (companyName: string) => `${companyName} will see your populations
    based on their sharing defaults`,
  },
  aggregates_only: {
    title: 'Overlap Counts Only',
    subtitle: (
      companyName: string,
    ) => `${companyName} will only see overlap counts for your populations.
    No data will be shared.`,
  },
  'set-per-pop': {
    title: 'Custom',
    subtitle: (companyName: string) =>
      `Set specific population sharing levels for ${companyName}`,
  },
};

export const DATA_SHARING_STATE_KEY = 'data-sharing-state-key';

export const CUSTOM = 'custom';
export const RECOMMENDED = 'recommended';

const { account, lead, deal, deal_contact_role, user, contact } =
  MDM_PROPERTIES;
export const PRESET_FIELDS_LOOKUP = {
  [MDM_TYPES.ACCOUNT]: [
    account.BILLING_COUNTRY,
    account.CREATED_AT,
    account.EMPLOYEES,
    account.INDUSTRY,
    account.NAME,
    account.TYPE,
    account.WEBSITE,
  ],
  [MDM_TYPES.LEAD]: [lead.CREATED_AT, lead.EMAIL, lead.NAME, lead.PHONE],
  [MDM_TYPES.DEAL]: [
    deal.CLOSE_DATE,
    deal.IS_CLOSED,
    deal.IS_WON,
    deal.LAST_ACTIVITY_AT,
    deal.NAME,
    deal.OPEN_DATE,
    deal.STAGE,
  ],
  [MDM_TYPES.USER]: [
    user.EMAIL,
    user.FIRST_NAME,
    user.NAME,
    user.LAST_NAME,
    user.TITLE,
  ],
  [MDM_TYPES.CONTACT]: [
    contact.CREATED_AT,
    contact.EMAIL,
    contact.FIRST_NAME,
    contact.LAST_ACTIVITY_AT,
    contact.LAST_NAME,
    contact.NAME,
    contact.TITLE,
  ],
  [MDM_TYPES.DEAL_CONTACT]: [
    deal_contact_role.IS_PRIMARY,
    deal_contact_role.ROLE,
  ],
};
