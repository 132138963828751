import { datadogRum } from '@datadog/browser-rum';

import appConfig from '@/config';

const ignoredErrorMessages = [
  'AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows',
];

export const initClient = (opts = { copilot: false }) => {
  if (appConfig.datadog) {
    appConfig.datadog.version = APP_VERSION;
    datadogRum.init({
      ...appConfig.datadog,
      beforeSend(event) {
        event.context = {
          ...event.context,
          fullstoryUrl: getFullStoryUrl(),
        };
        return true;
      },
    });

    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty('APP_VERSION', APP_VERSION);
    datadogRum.setGlobalContextProperty('COPILOT', opts.copilot);
  }
};

export const setUser = ({ email, orgId, orgName }) => {
  if (appConfig.datadog) {
    datadogRum.setUser({ email, org_id: orgId, org_name: orgName });
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

const getFullStoryUrl = () => {
  if (!appConfig.enableFullstory) return;
  const fsCookie = getCookie('fs_uid');
  if (!fsCookie || fsCookie.split('`').length < 3) return;
  const cookieParts = fsCookie.split('`');
  const appId = cookieParts[1];
  const sessionParts = cookieParts[2].split(':');
  const tail = `${sessionParts[0]}:${sessionParts[0]}!${sessionParts[1]}`;
  return `https://app.fullstory.com/ui/${appId}/session/${tail}`;
};

export const configureHandlers = (app, flashesStore) => {
  app.config.errorHandler = (err) => {
    // handle errors when new build is deployed and user is actively using the app
    // certain chunks won't be available and the user will need to reload the app
    if (err && err.toString().includes('ChunkLoadError')) {
      flashesStore.addInfoFlash({
        message: '🎉 Application Update Available',
        description: 'Get the latest and greatest Crossbeam by',
        link: {
          url: window.location.href,
          text: 'refreshing now',
        },
      });
    }

    // handle expired sessions
    if (err && err.toString().includes('Session has expired.')) {
      window.location.replace('/login');
      return;
    }

    if (
      err &&
      window.location.pathname !== '/login' &&
      err?.response?.status === 401
    ) {
      flashesStore.addErrorFlash({
        message: 'Session has expired.',
        description: 'Please re-enter login credentials.',
      });
      window.location.replace('/login');

      return;
    }

    if (!ignoredErrorMessages.some((msg) => err?.message?.startsWith(msg))) {
      console.error(err);
      // This gets run in a timeout because otherwise, infinite loop issues may
      // sometimes occur. I haven't figured out the exact mechanism, but my
      // theory is that:
      //  1 Vue tries to render a component, where an error occurs
      //  2 This error handler runs and updates the pinia store
      //  3 The change to the pinia store causes Vue to try to re-render the
      //    component
      //  4 Go back to step 1
      // Some resources on this behavior:
      // - https://github.com/vuejs/vuex/issues/1505
      // - https://github.com/vuejs/vue/pull/9489
      setTimeout(() => {
        flashesStore.addUnhandledError(err);
      });
    }
  };
};

export const captureException = (err) => {
  // include error message from axios request if the exception is from an axios request
  if (err.response?.data && err.message) {
    err.message = `${err.message}: ${JSON.stringify(err.response?.data)}`;
  }
  const datadogEnv = appConfig.datadog?.env;
  if (datadogEnv) {
    datadogRum.addError(err);
  }

  if (import.meta.env.MODE !== 'test') {
    console.log('exception reported', err);
  }
};

export const defaultErrorMessage =
  'Something went wrong, reach out to our support team for help';

export default {
  captureException,
  defaultErrorMessage,
};
