<template>
  <div class="c-ecosystem-fields-salesforce">
    <BittsCheckbox
      label="Push to standard object"
      :checked="isEcosystemFieldsPushEnabled"
      :disabled="!isPushEnabled"
      @input="(val) => emit('changed:overlaps-push', val)"
    />
    <BittsTooltip
      :include-cta="true"
      learn-more-link="https://help.crossbeam.com/en/articles/10742955-enhanced-ecosystem-reporting-in-salesforce-beta"
    >
      <FontAwesomeIcon
        :icon="['fak', 'information-circle']"
        class="text-neutral-accent"
      />
      <template #title>
        <p class="mb-4">
          Push "is a customer of" and "is an opportunity for" fields in your
          standard Account object.
        </p>
        <p>
          You need to create the fields on your Account object before you can
          map it below.
        </p>
      </template>
    </BittsTooltip>
  </div>
  <div class="ecosystem-field-mappings" v-if="isEcosystemFieldsPushEnabled">
    <label class="text-neutral-text" for="isCustomerOf">Is customer of:</label>
    <BittsSelect
      v-bind="baseSelectProps"
      id="isCustomerOf"
      :options="customSourceFields"
      :disabled="!isPushEnabled"
      :error="errorLookup.is_customer_of"
      error-message="Field cannot be found in Salesforce"
      v-model="isCustomerOf"
    />
    <label class="text-neutral-text" for="isOppyFor">Is opportunity for:</label>
    <BittsSelect
      v-bind="baseSelectProps"
      id="isOppyFor"
      :options="customSourceFields"
      :disabled="!isPushEnabled"
      :error="errorLookup.is_opportunity_for"
      error-message="Field cannot be found in Salesforce"
      v-model="isOppyFor"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsCheckbox, BittsSelect, BittsTooltip } from '@crossbeam/bitts';
import { Nullable } from '@crossbeam/types';

import { computed, onBeforeMount, ref, watchEffect } from 'vue';

import { useFeedsStore, useSourcesStore } from '@/stores';
import {
  EcosystemCrossbeamField,
  EcosystemFieldMappings,
} from '@/types/integration_profiles';

const { isPushEnabled, ecosystemFieldMappings, isEcosystemFieldsPushEnabled } =
  defineProps<{
    isPushEnabled: boolean;
    isEcosystemFieldsPushEnabled: boolean;
    ecosystemFieldMappings: Nullable<EcosystemFieldMappings>;
  }>();

const emit = defineEmits<{
  'changed:fields': [fields: EcosystemFieldMappings];
  'changed:overlaps-push': [value: boolean];
}>();

const errorLookup = ref<Record<EcosystemCrossbeamField, boolean>>({
  is_customer_of: false,
  is_opportunity_for: false,
});

const baseSelectProps = {
  allowClear: true,
  placeholder: 'Select field',
  class: 'w-300',
};

// Salesforce feed info
const feedsStore = useFeedsStore();
const salesforceFeed = computed(() =>
  feedsStore.crmFeeds.find((feed) => feed.integration.type === 'salesforce'),
);
const feedId = computed(() => salesforceFeed.value?.id);

// Account sources
const { getSourcesByMdmType } = useSourcesStore();
const sources = computed(() =>
  getSourcesByMdmType('account').filter(
    (source) => source.feed_id === feedId.value,
  ),
);

const customSourceFields = computed(() =>
  sources.value[0]?.fields
    .filter(
      (field) =>
        field.column.endsWith('__c') && // custom field
        !!field.options, // has options (picklist)
    )
    .map((field) => ({
      label: field.nickname,
      value: field.column,
    })),
);

const isCustomerOf = ref<Nullable<string>>(null);
const isOppyFor = ref<Nullable<string>>(null);

const fieldMappings = computed<EcosystemFieldMappings>(() => {
  if (!isOppyFor.value || !isCustomerOf.value) return null;
  return [
    {
      crossbeam_field: 'is_customer_of',
      crm_field: isCustomerOf.value,
    },
    {
      crossbeam_field: 'is_opportunity_for',
      crm_field: isOppyFor.value,
    },
  ];
});

watchEffect(() => {
  if (isCustomerOf.value) {
    errorLookup.value.is_customer_of = false;
  }
  if (isOppyFor.value) {
    errorLookup.value.is_opportunity_for = false;
  }
  emit('changed:fields', fieldMappings.value);
});

function setField(field: EcosystemCrossbeamField, value?: string) {
  if (!value) return;

  const exists = customSourceFields.value?.some((f) => f.value === value);
  if (!exists) {
    errorLookup.value[field] = true;
    return;
  }

  if (field === 'is_customer_of') isCustomerOf.value = value;
  if (field === 'is_opportunity_for') isOppyFor.value = value;
}
onBeforeMount(() => {
  const isCustomerOfField = ecosystemFieldMappings?.find(
    (field) => field.crossbeam_field === 'is_customer_of',
  )?.crm_field;

  const isOppyForField = ecosystemFieldMappings?.find(
    (field) => field.crossbeam_field === 'is_opportunity_for',
  )?.crm_field;

  setField('is_customer_of', isCustomerOfField);
  setField('is_opportunity_for', isOppyForField);
});
</script>

<style scoped lang="pcss">
.c-ecosystem-fields-salesforce {
  @apply flex items-center mt-8 text-neutral-text-strong;
}

.ecosystem-field-mappings {
  @apply flex flex-col gap-8 border-l border-neutral-border pl-18 ml-6;
}
</style>
