<template>
  <div class="c-pwd-strength">
    <span
      v-for="level in passwordLevels"
      :key="`bar_${level}`"
      class="c-pwd-strength__bar"
      :class="getBarColor(level)"
    />
    <div class="c-pwd-strength__explanation">
      {{ pwdStrengthMsg }}<span v-if="pwdFeedback"> - {{ pwdFeedback }} </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Nullable } from '@crossbeam/types';

import { ZxcvbnResult, debounce, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { adjacencyGraphs, dictionary } from '@zxcvbn-ts/language-common';
import { translations } from '@zxcvbn-ts/language-en';
import { computed, onMounted, ref, watch } from 'vue';

const {
  password = '',
  limitVisibleScore = 4,
  limitFeedback = '',
} = defineProps<{
  password: string;
  limitVisibleScore: number;
  limitFeedback: string;
}>();

const emit = defineEmits<(e: 'password-score', score: number) => void>();

const options = {
  translations,
  graphs: adjacencyGraphs,
  dictionary: {
    ...dictionary,
    ...dictionary,
  },
};

/* This library scores a password from 0-4 */
zxcvbnOptions.setOptions(options);

const result = ref<Nullable<ZxcvbnResult>>(null);

const passwordLevels = ref([0, 1, 2, 3, 4]);

const pwdStrength = computed(() => {
  /* If the password is limited, return the lower of the two,
        otherwise just return the score */
  const resultScore = result.value?.score || 0;
  if (limitVisibleScore) return Math.min(resultScore, limitVisibleScore);
  return resultScore;
});
const pwdStrengthMsg = computed(() => {
  switch (pwdStrength.value) {
    case 4:
      return 'Password is very strong!';
    case 3:
      return 'Password is strong';
    case 2:
      return 'Password is okay';
    case 1:
      return 'Password is weak';
    default:
      return 'Password is very weak';
  }
});
const pwdFeedback = computed(() => {
  if (result.value?.feedback.warning) return result.value.feedback.warning;
  const score = result.value?.score || 0;
  if (limitFeedback && limitVisibleScore) {
    if (pwdStrength.value <= limitVisibleScore && score >= 3) {
      return limitFeedback;
    }
  }
  if (score < 4) return 'Try adding more characters or words!';
  return '';
});

function getBarColor(level: number) {
  if (
    (pwdStrength.value === 0 && level === 0) ||
    (pwdStrength.value === 1 && level <= 1)
  )
    return 'c-pwd-strength__bar--failure';
  if (pwdStrength.value === 2 && level <= 2)
    return 'c-pwd-strength__bar--warning';
  if (pwdStrength.value >= 3 && level <= pwdStrength.value)
    return 'c-pwd-strength__bar--success';
  return 'c-pwd-strength__bar--neutral';
}

function emitPasswordScore() {
  emit('password-score', result.value?.score || 0);
}

onMounted(() => {
  result.value = zxcvbn(password);
  emitPasswordScore();
});

const debouncePwdCheck = debounce(function (pwd) {
  result.value = zxcvbn(pwd);
  emitPasswordScore();
}, 200);

watch(
  () => password,
  () => debouncePwdCheck(password),
);
</script>
<style lang="pcss">
.c-pwd-strength {
  @apply flex items-center flex-wrap gap-4;
}

.c-pwd-strength__bar {
  @apply h-8 flex-1 rounded-3;
}

.c-pwd-strength__bar--success {
  @apply bg-success-accent;
}

.c-pwd-strength__bar--warning {
  @apply bg-warning-accent;
}

.c-pwd-strength__bar--failure {
  @apply bg-danger-accent;
}

.c-pwd-strength__bar--neutral {
  @apply border border-neutral-300;
}
.c-pwd-strength__explanation {
  @apply w-full text-sm text-neutral-text-weak text-left leading-4;
}
</style>
