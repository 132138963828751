import { EVENT_SITES, itly } from '@crossbeam/itly';

import { isWebUri } from 'valid-url';

import useHasFeature from '@/composables/useHasFeature';
import useMinimalApp from '@/composables/useMinimalApp';
import appConfig from '@/config';
import {
  TEMP_DEAL_DASHBOARD,
  TEMP_NAVBAR_GLOWUP,
  TEMP_PERFORMANCE_DASHBOARD,
} from '@/constants/feature_flags';
import { isVerified } from '@/constants/verification_status';
import { getAndClearLSNextUrl, ls } from '@/local_storage';
import { useBillingStore, useFeatureFlagStore } from '@/stores';
import urls from '@/urls';

export async function routingOverride({ from, store }) {
  const billingStore = useBillingStore();
  const { hasFeatureFlag } = useFeatureFlagStore();

  if (!store.isLoggedIn) {
    return { name: 'login' };
  }

  const { isMinimalView } = useMinimalApp();

  const hasSalesFeatureBeta =
    hasFeatureFlag(TEMP_DEAL_DASHBOARD) &&
    hasFeatureFlag(TEMP_PERFORMANCE_DASHBOARD) &&
    hasFeatureFlag(TEMP_NAVBAR_GLOWUP);

  await billingStore.readySync;

  const isAcademyLogin = from.name === 'academy';

  // If user has no core access and they aren't trying to log into the
  // crossbeam academy application (crossbeam.academy.com), then route to sales app
  const routeToSalesApp = store.hasSalesEdgeOnly && !isAcademyLogin;

  if (store.userCanTryToLinkAccounts && from.name !== 'link-accounts') {
    return { name: 'link-accounts' };
  } else if (routeToSalesApp) {
    window.location.replace(window.origin.replace('app', 'sales'));
  } else if (store.userNeedsToRegister) {
    return { name: 'company_setup' };
  } else if (store.userHasNoOrg) {
    return { name: 'no_org' };
  } else if (store.currentOrg?.is_dormant) {
    return { name: 'dormant_company' };
  } else if (!isVerified(store.currentOrg)) {
    return { name: 'verify_company' };
  } else if (isMinimalView && !hasSalesFeatureBeta) {
    return { name: 'coming_soon' };
  }
  return null;
}

export const isAuthorizedExternalRedirect = (nextUrl) => {
  const chromeExtensionUri = /https:\/\/(.*\.)?chromiumapp\.org.*/;
  return (
    isWebUri(nextUrl) &&
    typeof nextUrl === 'string' &&
    nextUrl.match(chromeExtensionUri)
  );
};

export function beforeEnterMain(_to, _from, next, store) {
  const newSession = ls.newSession.get();

  if (newSession && !store.userHasNoOrg) {
    itly.userLoggedIn({ event_site: EVENT_SITES.LOGIN_CROSSBEAM });
    ls.newSession.set();
  }
  const publicInvite = ls.publicInvite.get();
  const proposalAcceptInfo = ls.proposalAcceptInfo.get();

  let nextUrlOrRoute;
  if (publicInvite) {
    nextUrlOrRoute = { name: 'accept_public_invite' };
  } else if (proposalAcceptInfo) {
    const query = {
      proposal_id: proposalAcceptInfo.proposal.id,
      proposal_acceptance_code: proposalAcceptInfo.acceptance_code,
    };
    nextUrlOrRoute = {
      name: 'accept_proposal',
      query,
    };
  } else {
    nextUrlOrRoute = getAndClearLSNextUrl() || { name: 'home' };
  }
  /* This is a redirect from Partnered, send them back there after logging in,
   * instead of sending them to the crossbeam application */
  const isPartneredLogin =
    appConfig.salesEdgeRedirectUrl &&
    typeof nextUrlOrRoute === 'string' &&
    nextUrlOrRoute.startsWith(appConfig.salesEdgeRedirectUrl);

  if (isPartneredLogin) {
    window.open(nextUrlOrRoute, '_self');
    return;
  }

  if (isAuthorizedExternalRedirect(nextUrlOrRoute)) {
    window.location.replace(nextUrlOrRoute);
    return;
  }
  next(nextUrlOrRoute);
}

export const handleLogout = (nextUrl) => {
  for (const storageItem of Object.values(ls)) {
    if (storageItem.clearOnLogout) {
      storageItem.set();
    }
  }

  const returnToUrl = new URL(nextUrl, location.origin);
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    returnToUrl.searchParams.set(key, value);
  });
  const returnTo = returnToUrl.toString();
  const url = `${appConfig.apiBaseUrl + urls.session.logout}?returnTo=${encodeURIComponent(returnTo)}`;
  if (window.UserLeap && window.UserLeap.logoutUser)
    window.UserLeap.logoutUser();
  window.location.replace(url);
};

/* Billing navigation guards */
export async function inRenewalPeriod(to, from, next) {
  const billingStore = useBillingStore();
  const { debookingEnabled } = useHasFeature();
  await billingStore.readySync;
  if (billingStore.isSubscriptionCancelled || !debookingEnabled.value)
    next({ name: 'not_found' });
  else next();
}

export async function inRenewalPeriodAndCancelled(to, from, next) {
  const billingStore = useBillingStore();
  const { debookingEnabled } = useHasFeature();
  await billingStore.readySync;
  if (!billingStore.isSubscriptionCancelled || !debookingEnabled.value)
    next({ name: 'not_found' });
  else next();
}
