<template>
  <BittsCard title-text="Revenue Settings" class="potential-revenue-card">
    <div class="p-16 flex-col items-stretch">
      <div v-if="!hasDealSources" class="mt-20 mb-16">
        <BittsCallout
          v-if="!calloutDismissed"
          size="x-small"
          type="info"
          title="Add a CRM data source to gain access to revenue metrics"
          subtitle="We need access to opportunity data to be able to generate these numbers"
          action-text="Connect Data Source"
          :dismiss="true"
          @bitts-callout-action="handleConnectDataSource"
          @bitts-callout-dismiss="handleDismiss"
        />
      </div>
      <NewAndExistingBusinessSettings
        v-if="
          hasFeatureFlag(TEMP_PERFORMANCE_DASHBOARD) &&
          hasScope('write:populations')
        "
        class="mb-24"
      />
      <div
        v-if="hasDealSources && !hasDealSourcesAndFields"
        class="mt-20 mb-16"
      >
        <BittsCallout
          v-if="!calloutDismissed"
          size="x-small"
          type="info"
          title="Add the Pipeline Mapping preset to gain access to revenue metrics"
          subtitle="We need access to opportunity data to be able to generate these numbers"
          action-text="Update Data Sync"
          :dismiss="true"
          @bitts-callout-action="handleDataSync"
          @bitts-callout-dismiss="handleDismiss"
        />
      </div>
      <div v-if="hasOrgSettingsPermission">
        <h3>CRM Amount Field</h3>
        <span class="text-neutral-text text-sm">
          This is the field used to pull the amount associated with individual
          opportunities for
          <BittsLink
            url="https://help.crossbeam.com/en/articles/6797349-potential-revenue"
            text="Potential Revenue"
          />
          and
          <BittsLink
            url="https://help.crossbeam.com/en/collections/6037610-attribution"
            text="Attribution"
          />.
        </span>
        <BittsSelect
          v-model="currentAmountColumn"
          :disabled="!hasDealSourcesAndFields"
          :has-opt-groups="true"
          heading-type="svg"
          :options="fieldList"
          class="max-w-[400px] my-8"
          :allow-clear="true"
          @update:model-value="handleUpdateAmountColumn"
        >
          <template #suffix="{ option }">
            <BittsTag
              v-if="option.default"
              color="info"
              size="x-small"
              variant="rounded"
              class="mr-8"
            >
              Default
            </BittsTag>
          </template>
        </BittsSelect>
      </div>
      <div class="potential_revenue-card__description">
        {{ `Note: This must be a number field in your ${objectType} object` }}
      </div>
      <h3>Potential Revenue Populations</h3>
      <div class="potential-revenue-card__text">
        <p
          >For each partnership, we calculate a potential revenue number based
          on Open Opportunities in your Populations.</p
        >
        <p>
          Below you can customize which Populations are used in this
          calculation.
          <BittsLink
            url="https://help.crossbeam.com/en/articles/6797349-potential-revenue"
            text="Learn more about this feature"
          />
        </p>
      </div>
      <div class="potential-revenue-card__settings">
        <div class="w-full">
          <BittsCheckboxGroup
            form-label="Your Populations"
            :class="{ 'cursor-not-allowed': !hasOrgSettingsPermission }"
            class="potential-revenue-card__checkbox-group"
          >
            <BittsCheckbox
              v-for="setting in ownRevenueSettings"
              :key="setting.title"
              :checked="setting.isChecked"
              :disabled="!hasOrgSettingsPermission || !hasDealSourcesAndFields"
              :label="setting.title"
              @input="onSettingSelected(setting.type, OWN)"
            />
          </BittsCheckboxGroup>
        </div>
        <FontAwesomeIcon
          :icon="['fak', 'mapping']"
          :style="{ width: '16px', height: '16px', color: 'currentColor' }"
          class="text-neutral-accent px-12"
        />
        <div class="w-full">
          <BittsCheckboxGroup
            form-label="Partner Populations"
            :class="{ 'cursor-not-allowed': !hasOrgSettingsPermission }"
            class="potential-revenue-card__checkbox-group"
          >
            <BittsCheckbox
              v-for="setting in partnerRevenueSettings"
              :key="setting.title"
              :checked="setting.isChecked"
              :disabled="!hasOrgSettingsPermission || !hasDealSourcesAndFields"
              :label="setting.title"
              @input="onSettingSelected(setting.type, PARTNER)"
            />
          </BittsCheckboxGroup>
        </div>
      </div>
      <BittsButton
        v-if="hasOrgSettingsPermission"
        :disabled="!haveSettingsChanged"
        size="small"
        text="Save Settings"
        @click="onSaveSettings"
      />
    </div>
  </BittsCard>
</template>

<script>
import {
  BittsButton,
  BittsCallout,
  BittsCard,
  BittsCheckbox,
  BittsCheckboxGroup,
  BittsLink,
  BittsSelect,
  BittsTag,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { TEMP_PERFORMANCE_DASHBOARD } from '@/constants/feature_flags';
import { MDM_PROPERTIES } from '@/constants/mdm';
import { captureException } from '@/errors';
import {
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
  usePotentialRevenueStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

import NewAndExistingBusinessSettings from './NewAndExistingBusinessSettings.vue';

const OWN = 'our_populations_potential_influence';
const PARTNER = 'partner_populations_potential_influence';
const AMOUNT_COLUMN = 'amount_column_potential_influence';

const CUSTOMERS = 'customers';
const OPEN_OPPS = 'open_opportunities';
const PROSPECTS = 'prospects';
const CUSTOM = 'custom';
const DOUBLE_PRECISION = 'double precision';
const MONEY = 'money';

export default {
  name: 'PotentialRevenueCard',
  components: {
    BittsButton,
    BittsCallout,
    BittsCard,
    BittsCheckbox,
    BittsCheckboxGroup,
    BittsSelect,
    BittsLink,
    BittsTag,
    NewAndExistingBusinessSettings,
  },
  emits: ['close-and-redirect'],
  setup() {
    const { currentOrg, hasScope } = useAuth();
    const { iteratively } = useIteratively();
    const potRevSettingsStore = usePotentialRevenueStore();

    const { hasFeatureFlag } = useFeatureFlagStore();

    return {
      currentOrg,
      hasScope,
      hasFeatureFlag,
      iteratively,
      potRevSettingsStore,
      TEMP_PERFORMANCE_DASHBOARD,
    };
  },
  data() {
    return {
      haveSettingsChanged: false,
      calloutDismissed: false,
      OWN,
      PARTNER,
      potentialRevenueSettings: {},
      currentAmountColumn: null,
    };
  },
  computed: {
    ...mapState(useFeedsStore, ['feedsByIdLookup']),
    ...mapState(useSourcesStore, ['dealSources']),
    hasHubspotConnected() {
      return this.getFeedByDataSourceType('hubspot_v3');
    },
    hasOrgSettingsPermission() {
      return this.hasScope('write:organization');
    },
    objectType() {
      if (this.hasHubspotConnected) return 'deals';
      return 'opportunity';
    },
    ownRevenueSettings() {
      return [
        {
          title: 'Your Customers',
          isChecked: this.isChecked(CUSTOMERS, OWN),
          type: CUSTOMERS,
        },
        {
          title: 'Your Open Opportunities',
          isChecked: this.isChecked(OPEN_OPPS, OWN),
          type: OPEN_OPPS,
        },
        {
          title: 'Your Prospects',
          isChecked: this.isChecked(PROSPECTS, OWN),
          type: PROSPECTS,
        },
        {
          title: 'All Custom Populations',
          isChecked: this.isChecked(CUSTOM, OWN),
          type: CUSTOM,
        },
      ];
    },
    partnerRevenueSettings() {
      return [
        {
          title: 'Partner Customers',
          isChecked: this.isChecked(CUSTOMERS, PARTNER),
          type: CUSTOMERS,
        },
        {
          title: 'Partner Open Opportunities',
          isChecked: this.isChecked(OPEN_OPPS, PARTNER),
          type: OPEN_OPPS,
        },
        {
          title: 'Partner Prospects',
          isChecked: this.isChecked(PROSPECTS, PARTNER),
          type: PROSPECTS,
        },
        {
          title: 'All Custom Populations',
          isChecked: this.isChecked(CUSTOM, PARTNER),
          type: CUSTOM,
        },
      ];
    },
    hasDealSources() {
      return Array.isArray(this.dealSources) && this.dealSources.length > 0;
    },
    fieldList() {
      const dealFeedGroups = [];
      this.dealSources
        .filter((source) => this.feedsByIdLookup[source.feed_id])
        .forEach((source) => {
          const feed = this.feedsByIdLookup[source.feed_id].integration;
          const optGroup = {
            heading: feed?.friendly_name,
            svg: `${feed?.type}Icon`,
            items: source.fields
              .filter(
                (field) =>
                  field.is_visible &&
                  (field.pg_data_type === DOUBLE_PRECISION ||
                    field.pg_data_type === MONEY),
              )
              .sort((a, b) => a?.display_name?.localeCompare(b?.display_name))
              .map((field) => {
                const option = {
                  ...field,
                  value: field.id,
                  label: field.display_name,
                };
                if (field.mdm_property === MDM_PROPERTIES.deal.AMOUNT)
                  option.default = true;
                return option;
              }),
          };
          dealFeedGroups.push(optGroup);
        });
      return dealFeedGroups;
    },
    hasDealSourcesAndFields() {
      return (
        this.hasDealSources &&
        this.fieldList.some((source) => source.items.length)
      );
    },
  },
  async created() {
    await this.potRevSettingsStore.readySync;
    this.potentialRevenueSettings =
      this.potRevSettingsStore.potentialRevenueSettings;
    if (this.potentialRevenueSettings[AMOUNT_COLUMN]) {
      this.currentAmountColumn = Object.values(
        this.potentialRevenueSettings[AMOUNT_COLUMN] || {},
      )[0];
    } else {
      if (!this.fieldList[0]) return;
      const defaultField = this.fieldList[0].items.find(
        (item) => item.mdm_property === MDM_PROPERTIES.deal.AMOUNT,
      );
      if (defaultField) {
        this.potentialRevenueSettings[AMOUNT_COLUMN] = {};
        this.potentialRevenueSettings[AMOUNT_COLUMN][defaultField.source_id] =
          defaultField.id;
        this.currentAmountColumn = defaultField.id;
      }
    }
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedByDataSourceType']),
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useSourcesStore, ['getSourceFieldById']),
    addSetting(setting, org) {
      this.potentialRevenueSettings[org].push(setting);
    },
    isChecked(setting, org) {
      return this.potentialRevenueSettings[org]?.includes(setting);
    },
    async onSaveSettings() {
      try {
        const url = urls.org.potentialRevenueSettings;
        await axios.post(url, this.potentialRevenueSettings);
        await this.potRevSettingsStore.refreshPotentialRevenueStore();
        this.addSuccessFlash({
          message: 'Potential revenue settings saved',
          description:
            'It will take some time for these changes to be reflected.',
        });
        this.haveSettingsChanged = false;
      } catch (err) {
        captureException(err);
        this.addErrorFlash({
          message: 'Could not save potential revenue settings',
          description: `If this error persists, please contact
          <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
        });
      }
    },
    handleItlySyncData(dataType = 'sync_crm') {
      this.iteratively.userClickedSyncData({
        cta_location: 'Potential Revenue Configuration', // TODO: Deprecate, this is EVENT_SITE now
        event_site: EVENT_SITES.POTENTIAL_REVENUE_CONFIGURATION,
        data_type: dataType,
      });
    },
    async goToDataSources() {
      const hasMultipleDealSources = this.dealSources.length > 1;
      if (hasMultipleDealSources) await this.handleConnectDataSource();
      else await this.handleDataSync();
    },
    onSettingSelected(setting, org) {
      if (!this.hasOrgSettingsPermission) return;
      this.haveSettingsChanged = true;
      if (this.isChecked(setting, org)) this.removeSetting(setting, org);
      else this.addSetting(setting, org);
    },
    handleUpdateAmountColumn(fieldId) {
      const selectedField = this.getSourceFieldById(fieldId);
      if (selectedField?.id && selectedField?.source_id) {
        if (this.currentAmountColumn !== selectedField.id)
          this.haveSettingsChanged = true;
        this.currentAmountColumn = selectedField.id;
        this.potentialRevenueSettings[AMOUNT_COLUMN] = {
          [selectedField.source_id]: selectedField.id,
        };
      }
      this.haveSettingsChanged = true;
    },
    async handleConnectDataSource() {
      this.handleItlySyncData();
      await this.$router.push({ name: 'data-sources' });
    },
    async handleDataSync() {
      const { feed_id: feedId } = this.dealSources[0];
      const feed = this.feedsByIdLookup[feedId];
      if (!feed) {
        this.addErrorFlash({ message: 'This feed cannot be found' });
        return;
      }
      const feedType = feed.integration.type;
      this.handleItlySyncData(feedType);
      const name =
        feedType === 'snowflake' ? 'snowflake-settings' : 'data-templates';
      await this.$router.push({
        name,
        params: { id: feedId },
        query: { customizing: true },
      });
    },
    handleDismiss() {
      this.calloutDismissed = true;
    },
    removeSetting(setting, org) {
      this.potentialRevenueSettings[org] = this.potentialRevenueSettings[
        org
      ].filter((potRevSetting) => potRevSetting !== setting);
    },
  },
};
</script>

<style scoped lang="pcss">
.potential-revenue-card__checkbox-group {
  @apply border-neutral-border shadow-component w-full;
}
.potential-revenue-card__settings {
  @apply flex items-center mb-24;
}
.potential-revenue-card__text {
  @apply text-neutral-text max-w-[680px] mb-16 text-sm;
}
.potential-revenue-card__title {
  @apply flex items-center text-neutral-text-strong text-sm font-bold mb-8;
}
.potential-revenue-card__dropdown {
  @apply border-neutral-border w-full;
}
.potential_revenue-card__description {
  @apply mb-24 text-sm text-neutral-text-weak;
}

.potential-revenue-card h3 {
  @apply text-neutral-text-strong font-bold mb-8 block;
}
</style>

<style lang="pcss">
.potential-revenue-card {
  .bitts-card__title {
    @apply text-lg text-neutral-text-strong font-bold;
  }
  .c-bitts-divider {
    @apply border-neutral-border m-0;
  }
  .ant-tooltip-inner {
    @apply font-normal;
  }
}
.potential-revenue-card__dropdown {
  .multiselect__option--disabled {
    @apply text-neutral-text;
    justify-content: normal !important;
    background: none !important;
  }
  .multiselect__element {
    @apply border-neutral-border border-t border-solid;
  }
  .multiselect__element:first-child {
    border: none;
  }
  .multiselect__element[role='option'] {
    border: none;
  }
  .multiselect__option {
    @apply rounded-none;
    span:not(.ant-checkbox-inner):not(.ant-checkbox) {
      padding-left: 8px;
    }
  }
}
</style>
