import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import {
  ReportFolder,
  ReportNotificationConfig,
  ReportNotificationConfigPayload,
} from '@/interfaces/reports';
import { initStore } from '@/stores/store-utils';
import { Report, ReportSlackApp } from '@/types/reports';
import urls from '@/urls';

export const useReportsStore = defineStore('Reports', () => {
  const reports = ref<Report[]>([]);
  const folders = ref<ReportFolder[]>([]);
  const slackApp = ref<ReportSlackApp | null>(null);

  const { initError, ready, readySync, running, refresh } = initStore(
    async () => {
      const reportResp = await crossbeamApi.GET('/v0.3/reports');
      const folderResp = await axios.get(urls.reports.folders.all);
      reports.value = reportResp.data?.items || [];
      folders.value = folderResp.data || [];
    },
  );

  refresh({ useCache: true });

  /* getters */
  function getByFolderId(id: string): ReportFolder | undefined {
    return folders.value.find((folder) => folder.id === id);
  }

  function getByReportId(id: string): Report | undefined {
    return reports.value.find((report) => report.id === id);
  }

  function getByReportName(name: string): Report | undefined {
    if (!name) return;
    return reports.value.find(
      (report) =>
        report.name.trim().toLowerCase() === name.trim().toLowerCase(),
    );
  }

  function getReportsIncludingString(str: string): Report[] {
    const normalizedStr = str.trim().toLowerCase();
    return reports.value.filter((report) =>
      report.name.trim().toLowerCase().includes(normalizedStr),
    );
  }

  /* actions */
  async function deleteReport(report: Report): Promise<void> {
    reports.value = reports.value.filter(
      (existing) => existing.id !== report?.id,
    );
    const url = urls.reports.delete(report.id);
    await axios.delete(url);
  }

  async function loadSlack(): Promise<void> {
    try {
      const response = await crossbeamApi.GET('/v0.1/slack-app');
      slackApp.value = response.data as ReportSlackApp;
    } catch (err) {
      captureException(err);
    }
  }

  async function saveNotificationConfigs({
    reportId,
    notifications,
  }: {
    reportId: string;
    notifications: ReportNotificationConfigPayload[];
  }): Promise<ReportNotificationConfig[]> {
    const url = urls.reports.notificationConfigs(reportId);
    const response = await axios.put(url, { items: notifications });
    const report = reports.value.find((report) => report.id === reportId);
    if (report) report.notification_configs = notifications;
    return response.data.items;
  }

  function upsertReport(report: Report): void {
    reports.value = reports.value
      .filter((existing) => existing.id !== report.id)
      .concat(report);
  }

  return {
    error: initError,
    ready,
    readySync,
    running,
    deleteReport,
    folders,
    getByFolderId,
    getReportsIncludingString,
    getByReportId,
    getByReportName,
    loadSlack,
    refreshReportsStore: refresh,
    reports,
    saveNotificationConfigs,
    slackApp,
    upsertReport,
  };
});
