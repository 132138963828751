<template>
  <BittsContainer
    class="c-connect-data-source-card"
    :class="{
      [pointbreak as string]: true,
      crm: variant === 'crm',
      best: isBestOption,
    }"
  >
    <div>
      <BittsTag
        v-if="showTag"
        variant="rounded"
        class="absolute top-12 right-6 block"
        color="secondary"
      >
        <span v-if="variant === 'crm'">Recommended</span>
        <span v-else-if="hasCrmAdmin">Best alternative</span>
      </BittsTag>
    </div>
    <!-- Content  -->
    <div class="content">
      <div>
        <BittsSvg
          v-if="variant !== 'crm'"
          class="w-48 h-48"
          :svg="`${variant}Icon`"
        />
        <BittsSvg
          v-else
          class="w-48 h-48"
          :svg="
            chosenCrm && isChosenCrmSupported
              ? chosenCrm + 'Icon'
              : 'salesforceIcon'
          "
        />
      </div>
      <template v-if="variant === 'crm'">
        <div class="flex justify-center font-bold">
          <h2
            :class="{
              'text-lg': isMobile,
              'text-xl': !isMobile,
            }"
          >
            {{ connectionText }}
          </h2>
          <div class="relative">
            <BittsDropdown
              class="pl-4 absolute"
              :class="{
                'mt-6': !isMobile,
              }"
              parent-class="w-[280px]"
              :options="crmOptions"
              @selection="handleChooseCrm"
            >
              <template #prompt>
                <BittsButton
                  :center-icon="['fas', 'pen']"
                  variant="ghost"
                  type="neutral"
                  size="x-small"
                  :disabled="viewOnly"
                />
              </template>
            </BittsDropdown>
          </div>
        </div>
        <div>
          <div class="flex items-center gap-8 text-neutral-text-weak">
            <FontAwesomeIcon
              class="text-success-accent"
              :icon="['far', 'check']"
            />
            <p>Synced with real-time business</p>
          </div>
          <div class="flex items-center gap-8 text-neutral-text-weak">
            <FontAwesomeIcon
              class="text-success-accent"
              :icon="['far', 'check']"
            />
            <p>Optimized use of Crossbeam</p>
          </div>
        </div>
      </template>
    </div>

    <!-- Footer with button  -->
    <div class="flex flex-col items-center">
      <BittsCheckbox
        :checked="isSandbox"
        @input="isSandbox = !isSandbox"
        v-if="variant === 'crm' && chosenCrm === SALESFORCE_DATA_SOURCE_TYPE"
        label="I'm connecting to a sandbox"
        class="mb-12"
        :disabled="variant === 'crm' && isConnectCrmDisabled"
      />
      <BittsButton
        :text="connectBtnText"
        :left-icon="['fas', 'plus']"
        class="w-full"
        size="x-small"
        :variant="connectBtnVariant"
        @click="handleClickConnectBtn"
        :data-testid="`add-${variant}-btn`"
        :disabled="variant === 'crm' && isConnectCrmDisabled"
      />
    </div>
  </BittsContainer>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsCheckbox,
  BittsContainer,
  BittsDropdown,
  BittsSvg,
  BittsTag,
} from '@crossbeam/bitts/';
import { usePointbreak, useScreenSize } from '@crossbeam/pointbreak';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useNewOnboardingDataSource from '@/composables/useNewOnboardingDataSource';
import {
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useFeedsStore, useTeamStore } from '@/stores';
import { FeedIntegrationType } from '@/types/feeds';

type Variant = 'crm' | 'googleSheets' | 'csv';
const { variant } = defineProps<{
  variant: Variant;
}>();

const teamStore = useTeamStore();
const { hasCrmAdmin } = storeToRefs(teamStore);
const { googleFeed } = storeToRefs(useFeedsStore());

const {
  chosenCrm,
  chosenCrmFriendlyName,
  crmOptions,
  isChosenCrmSupported,
  isConnectCrmDisabled,
  isSandbox,
  viewOnly,
  handleAddCsv,
  handleChooseCrm,
  handleConnectCrm,
} = useNewOnboardingDataSource();

const { isMobile } = useScreenSize();

const showTag = computed(
  () => variant === 'crm' || (variant === 'googleSheets' && hasCrmAdmin.value),
);
const isBestOption = computed(
  () =>
    (variant === 'crm' && !hasCrmAdmin.value) ||
    (variant === 'googleSheets' && hasCrmAdmin.value),
);

const connectionText = computed(
  () => `Connect ${chosenCrmFriendlyName.value || 'Salesforce'}`,
);
const connectBtnText = computed(() => {
  if (variant === 'crm')
    return isConnectCrmDisabled.value
      ? 'Connected already'
      : connectionText.value;
  if (variant === 'googleSheets') return 'Connect a Google sheet';
  return 'Upload a CSV file';
});
const connectBtnVariant = computed(() => {
  if (isBestOption.value) return 'fill';
  return 'outline';
});

const router = useRouter();
async function handleClickConnectBtn() {
  if (variant === 'crm') {
    await handleConnectCrm(
      chosenCrmFriendlyName.value
        ? (chosenCrm.value as FeedIntegrationType)
        : SALESFORCE_DATA_SOURCE_TYPE,
    );
  }
  if (variant === 'googleSheets') {
    const isConnected =
      !!googleFeed.value && googleFeed.value?.status !== 'CONN_CHECK_FAILED';
    isConnected
      ? router.push({ name: 'onboarding-google-sheets' })
      : handleConnectCrm(GOOGLE_SHEETS_DATA_SOURCE_TYPE);
  }
  if (variant === 'csv') handleAddCsv();
}
const pointbreak = usePointbreak();
</script>

<style lang="pcss">
.c-connect-data-source-card {
  @apply flex flex-col justify-between p-24 relative;

  .bitts-checkbox span .bitts-checkbox-with-label {
    @apply text-neutral-text-weak;
  }

  .content {
    @apply w-full flex flex-col items-center justify-center gap-12;
  }

  &.crm {
    @apply bg-neutral-50 w-[420px] border-none;
  }

  &.crm.sm,
  &.crm.xs {
    @apply w-full max-w-[420px];
  }

  &:not(.crm) {
    @apply h-[100%] min-h-[180px];
  }

  &.best {
    @apply bg-neutral-50 border-solid border-2 border-secondary-border;
  }
}
</style>
