import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { MDM_TYPES } from '@/constants/mdm';
import { useFeedsStore, useSourcesStore } from '@/stores';

import useAuth from './useAuth';

export function usePreferredCRMFeed() {
  const feedsStore = useFeedsStore();
  const { crmFeeds } = storeToRefs(feedsStore);
  const sourcesStore = useSourcesStore();
  const { sources } = storeToRefs(sourcesStore);
  const { preferredCRMFeedId } = useAuth();

  const preferredCRMFeed = computed(() => {
    if (crmFeeds.value.length === 0) {
      return null;
    }
    if (!preferredCRMFeedId.value) {
      return crmFeeds.value[0];
    }
    return crmFeeds.value.find((f) => f.id === preferredCRMFeedId.value);
  });

  const preferredCRMFeedSource = computed(() => {
    if (!preferredCRMFeed.value) return null;
    return sources.value.find(
      (s) =>
        s.feed_id === preferredCRMFeed.value?.id &&
        s.mdm_type === MDM_TYPES.ACCOUNT,
    );
  });

  return {
    preferredCRMFeed,
    preferredCRMFeedSource,
  };
}
