<template>
  <a
    href="https://help.crossbeam.com/en/articles/5246994-flagging-incorrect-matches"
    target="_blank"
    @click.stop
  >
    <BittsTooltip popover-class="c-match-issue__popover">
      <BittsSvg svg="alertTriangle" class="c-match-issue-flag__icon" />
      <template #title>
        <div class="c-match-issue__popover-text">
          {{ issueMessage }} Click warning icon to learn more.
        </div>
      </template>
    </BittsTooltip>
  </a>
</template>

<script setup lang="ts">
import { BittsSvg, BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { MatchIssue } from '@/interfaces/match_issues';

const { matchIssues } = defineProps<{ matchIssues: MatchIssue[] }>();

const { currentOrg, currentUser } = useAuth();

const selfReportMessage = computed(() => {
  return matchIssues.find((mi) => mi.reporting_user.id === currentUser.value.id)
    ? 'You reported this as an inaccurate match.'
    : null;
});
const orgReportMessage = computed(() => {
  const issue = matchIssues.find(
    (mi) => mi.reporting_organization.id === currentOrg.value.id,
  );
  if (issue) {
    const user = issue.reporting_user;
    return `${user.first_name} ${user.last_name} reported this as an inaccurate match.`;
  }
  return null;
});
const partnerReportMessage = computed(() => {
  const user = matchIssues[0]?.reporting_user;
  const org = matchIssues[0]?.reporting_organization;
  return (
    `${user?.first_name} ${user?.last_name} from ${org?.name} ` +
    'reported this as an inaccurate match.'
  );
});
const issueMessage = computed(() => {
  return (
    selfReportMessage.value ||
    orgReportMessage.value ||
    partnerReportMessage.value
  );
});
</script>

<style lang="pcss" scoped>
.c-match-issue-flag__icon:deep(path) {
  @apply text-neutral-400;
  fill: currentColor;
}
</style>
