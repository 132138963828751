<template>
  <div class="c-current-plan-card-summary">
    <div class="card-title" data-testid="card-title">
      <p class="flex items-center">
        <span data-testid="card-title" class="title">You're on {{ tier }}</span>
        <span
          v-if="isConnectorTier && isSubscriptionCancelled"
          class="cancellation-period"
          >until {{ subscriptionRenewalDate }}</span
        >
      </p>
      <slot name="top-right" />
    </div>
    <p data-testid="card-subtitle" class="text-neutral-text-weak mb-4">
      <span v-if="trialEndDate">
        Your trial is over on <b>{{ trialEndDate }}</b>
      </span>
      <span v-else-if="isSubscriptionCancelled"
        >After that, you will be downgraded to the free Explorer plan</span
      >
      <span v-else-if="isFreeTier || (isConnectorTier && isOnFreeTrial)"
        >Thanks for being on Crossbeam since
        <span class="text-neutral-text font-bold">{{ createdAtDate }}</span>
      </span>
      <span v-else>
        Thanks for being on this plan since
        <span class="text-neutral-text font-bold">{{ startDate }}</span>
      </span>
    </p>
    <div
      v-if="isLegacyFreeFlagOn && isPartnerSponsor"
      class="flex flex-col gap-4 text-neutral-text mt-16"
    >
      <span class="flex items-center gap-8 font-bold text-neutral-text-strong">
        <FontAwesomeIcon
          :icon="['fak', 'unlock']"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
          class="text-primary-accent"
        />
        Account Mapping Pass
      </span>
      <div class="flex items-center flex-wrap gap-3">
        You've unlocked unlimited account mapping for your partners on the free
        plan.
        <BittsLink
          text=" Learn more about Account Mapping Pass"
          url="https://help.crossbeam.com/en/articles/10592414-account-mapping-pass-in-crossbeam-how-it-works"
          class="inline w-auto"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { BittsLink } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { Nullable } from 'vitest';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { TEMP_LEGACY_FREE } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';
import { extractLocalDate } from '@/utils';

const {
  tier,
  startDate,
  trialEndDate = null,
} = defineProps<{
  startDate: Nullable<string>;
  tier: string;
  trialEndDate?: Nullable<string>;
}>();

const billingStore = useBillingStore();
const {
  isConnectorTier,
  isFreeTier,
  isOnFreeTrial,
  isPartnerSponsor,
  isSubscriptionCancelled,
  subscriptionRenewalDate,
} = storeToRefs(billingStore);

const ffStore = useFeatureFlagStore();
const isLegacyFreeFlagOn = computed(() =>
  ffStore.hasFeatureFlag(TEMP_LEGACY_FREE),
);

const { currentOrg } = useAuth();
const createdAtDate = computed(() =>
  currentOrg.value.created_at
    ? extractLocalDate(DateTime.fromISO(currentOrg.value.created_at))
    : null,
);
</script>
<style lang="pcss" scoped>
.c-current-plan-card-summary {
  @apply p-16 sm:p-24 flex flex-col rounded-16;
  /* TODO: Refactor the colors out into our tailwind config */
  background: linear-gradient(
      104.9deg,
      theme(colors.secondary.background-weak / 0.8) 0%,
      rgba(226, 247, 253, 0) 100%,
      theme(colors.secondary.accent / 0) 100%
    ),
    linear-gradient(0deg, theme(colors.white), theme(colors.white));
  .title {
    @apply text-lg font-bold text-info-text-strong;
  }
  .card-title {
    @apply mb-8 flex flex-row justify-between;
  }

  .cancellation-period {
    @apply text-secondary-text text-lg ml-4;
  }
}
</style>
